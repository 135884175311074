import React, { useState, useEffect, useContext } from "react";
import OverLayIU from "./overlay_ui";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Box,
  Modal,
} from "@mui/material";
// import MenuItem from '@mui/material/MenuItem';
import IconUI from "../ui/icon_ui";
import { GrClose } from "react-icons/gr";

import { services } from "../../services/api";
import Select, { components } from "react-select";

import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import InputAdornment from "@mui/material/InputAdornment";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import DotLoader from "../../components/ui/dotloader";
import { NotificacionContext } from "../../context/notificacion_context";
import CapturaContext from "../../context/captura_context/capturaContext";

import { emailValidation } from "../../utils/inputsRules";
import { maxLengthLimitInput } from "../../utils/inputsRules";
import swal from "sweetalert";
import GifLoader from "./gifLoader";
import { useHistory } from "react-router-dom";

function createMarkup(text) {
  return { __html: text };
}

export default function PopUp({ html, onclose, tipo, bodyUpdate }) {
  let rol = localStorage.getItem("rol");
  const colorUI = "#6F1D46";
  // const colorUI = "#4a001f";

  const permisosDependencias = ["Administrador"];
  const permisosAreas = ["adminDependencia", "Administrador"];
  const history = useHistory();

  const { setDatosTabla, editDatosTabla, addDatosTabla, currentUser } =
    useContext(CapturaContext);
  const { showNotification } = useContext(NotificacionContext);

  const [state, setState] = useState({
    type: tipo,
    rol: "",
    loading: false,
  });

  // const [passwordUser, setPasswordUser] = useState("");
  // const [stateModal, setStateModal] = useState(true);

  // const styleModal = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: "80%",
  //   bgcolor: "background.paper",
  //   boxShadow: 24,
  //   p: 4,
  // };

  const [validState, setValidState] = useState({
    curp: false,
    email: false,
    nombre: false,
    apellidoPaterno: false,
    apellidoMaterno: false,
    municipio: false,
  });

  const [oficinaState, setOficina] = useState({
    apioficinas: null,
    buscando: true,
  });

  const [correoEdit, setCorreoEdit] = useState(
    bodyUpdate?.email ? bodyUpdate.email : ""
  );

  const [curpEdit, setCurpEdit] = useState(
    bodyUpdate?.curp ? bodyUpdate.curp : ""
  );

  const [dataEdit, setDataEdit] = useState({
    nombre: bodyUpdate && bodyUpdate.nombre ? bodyUpdate.nombre : "",
    apePaterno:
      bodyUpdate && bodyUpdate.primerApellido ? bodyUpdate.primerApellido : "",
    apeMaterno:
      bodyUpdate && bodyUpdate.segundoApellido
        ? bodyUpdate.segundoApellido
        : "",
  });

  useEffect(() => {
    if (bodyUpdate) {
      setDataEdit({
        nombre: bodyUpdate.nombre,
        apePaterno: bodyUpdate.primerApellido,
        apeMaterno: bodyUpdate.segundoApellido,
      });
    }
  }, [bodyUpdate]);

  const [stateUpdate, setStateUpdate] = useState({
    municipioEdit: "",
    dependenciaEdit: "",
    oficinaEdit: "",
    rolEdit: "",
    areaUpdaate: "",
  });

  const [data, setdata] = useState({
    municipio: "",
    oficina: "",
    dependencia: "",
    apimunicipios: null,
    apioficinas: null,
    apiroles: null,
    apidependencias: null,
    apiareas: null,
    apiareasCopy: null,
    curp: "",
    nombre: "",
    primerApellido: "",
    segundoApellido: "",
    email: "",
    rol: "",
    // dependencia: "",
    area: "",
    consultacurp: "",
    infocurp: "CURP del usuario.",
    loading: false,
    validEmail: false,
    validCurp: true,
    validMunicipio: false,
    validOficina: false,
    validRol: false,
  });

  const consumoApiUsers = async () => {
    let dataUsersApi = await services("GET", `usuarios/listar`, null);
    // let areas = await services("FREEAPIGET", "areas/index", null);
    // let areas = await services("GET", "gratuitos/areas/index", null);
    // && areas.status === 200
    if (dataUsersApi.status === 200) {
      setDatosTabla(dataUsersApi.data);
      // setAreas(areas.data.data);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const rol = localStorage.getItem("rol");
      consumoApi(rol);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  // const closeModal = () => {
  //   setStateModal(false);
  // };

  const handleChangeMunicipio = (municipio) => {
    setTimeout(() => {
      setdata({
        ...data,
        municipio: municipio,
        oficina: { IDENTIFICADOR: "0", DESCRIPCION: "Seleccione Oficina" },
      });
    }, 2000);
    setOficina({ ...oficinaState, buscando: true });
    consumoApiOficina(municipio.IDENTIFICADOR);
  };

  const handleChangeMunicipioEdit = (municipio) => {
    consumoApiOficina(municipio.IDENTIFICADOR);
    setStateUpdate({
      ...stateUpdate,
      municipioEdit: municipio,
      oficinaEdit: { IDENTIFICADOR: "0", DESCRIPCION: "Seleccione Oficina" },
    });
  };
  // handleChangeDependencia
  const handleChangeDependenciaEdit = (depencencia) => {
    const newArrayAreas = data.apiareasCopy.filter((area) => {
      return area.id_dependencia === depencencia.id;
    });
    // console.log(newArrayAreas, "nuevo arreglo")
    setdata({ ...data, apiareas: newArrayAreas });
    // consumoApiOficina(municipio.IDENTIFICADOR);
    setStateUpdate({ ...stateUpdate, dependenciaEdit: depencencia });
  };

  const handleChangeAreaEdit = (area) => {
    setStateUpdate({ ...stateUpdate, areaUpdate: area });

    // console.log(area);
    // console.log("HOLA", stateUpdate.areaUpdate);
  };

  const handleChangeOficinaEdit = (oficina) => {
    setStateUpdate({ ...stateUpdate, oficinaEdit: oficina });
  };

  const handleChangeRolEdit = (rol) => {
    setStateUpdate({ ...stateUpdate, rolEdit: rol });
    // console.log(stateUpdate.rolEdit);
  };

  const handleChangeOficina = (oficina) => {
    setdata({ ...data, oficina: oficina });
  };

  const handleChangeRol = (rol) => {
    setdata({ ...data, rol: rol });

    // console.log(data.rol);
    // console.log(typeof data.apiareas);
    // console.log(Object.keys(data.apiareas) === 0);
    // console.log("ARRAY",);
  };

  const handleChangeDependencia = (dependencia) => {
    // console.log(dependencia.id, "dependencia.id")
    // console.log(data.apiareas, "apiareas")
    const newArrayAreas = data.apiareasCopy.filter((area) => {
      return area.id_dependencia === dependencia.id;
    });
    // console.log(newArrayAreas, "nuevo arreglo")
    setdata({ ...data, dependencia: dependencia, apiareas: newArrayAreas });
  };

  const handleChangeArea = (area) => {
    // console.log("data area: ", area.nombre);
    setdata({ ...data, area: area });
  };

  const SortArray = (x, y) => {
    if (x.DESCRIPCION < y.DESCRIPCION) {
      return -1;
    }
    if (x.DESCRIPCION > y.DESCRIPCION) {
      return 1;
    }
    return 0;
  };

  const consumoApi = async (rol) => {
    setdata({ ...data, loading: true });
    setState({ ...state, rol });
    let municipios = await services("GET", `catalago/MUNICIPIO`, null);
    let oficinas = await services("GET", `catalago/OFICINA`, null);
    let roles = await services("GET", `usuarios/roles/list`, null);
    let dependencias = await services("GET", `dependencia/listar`, null);
    // let areas = await services("FREEAPIGET", "areas/index", null);
    let areas = await services("GET", "gratuitos/areas/index", null);

    if (
      municipios.status === 200 &&
      oficinas.status === 200 &&
      roles.status === 200 &&
      dependencias.status === 200 &&
      areas.status === 200
    ) {
      let array_ordered = municipios.data.TB_RESULTADO;
      array_ordered = array_ordered.sort(SortArray);

      //Guardando respuesta de apis en el state

      setdata({
        ...data,
        apimunicipios: array_ordered,
        apioficinas: oficinas.data.TB_RESULTADO,
        apiroles: roles,
        apidependencias: dependencias.data,
        loading: false,
        apiareas: areas.data.data,
        apiareasCopy: areas.data.data,
      });

      if (state.type === "update") {
        //Completando objeto para edición de Municipio
        const getObjectoMunicipio = array_ordered.filter((item) => {
          return item.IDENTIFICADOR === bodyUpdate.id_municipio;
        });

        //Completando objeto para edición de Oficina
        const getObjectoOficina = oficinas.data.TB_RESULTADO.filter((item) => {
          return item.IDENTIFICADOR === bodyUpdate.id_oficina;
        });

        //Completando objeto para edición de Rol
        const getObjectoRol = roles.data.filter((item) => {
          return item.name === bodyUpdate.rol;
        });

        let getDependenciaObject;
        let getAreaObject;

        // Add Dependencia aqui para editar
        if (!dependencias.data.status) {
          getDependenciaObject = dependencias.data?.filter((item) => {
            return item.id === bodyUpdate.id_dependencia;
          });
          getAreaObject = areas.data.data.filter((item) => {
            return item.nombre === bodyUpdate.area;
          });
        }

        // Add Area aqui para editar

        // console.log(getAreaObject);

        setStateUpdate({
          ...stateUpdate,
          municipioEdit: getObjectoMunicipio[0],
          oficinaEdit: getObjectoOficina[0],
          rolEdit: getObjectoRol[0],
          dependenciaEdit: getDependenciaObject
            ? getDependenciaObject[0]
            : null,
          areaUpdate: getAreaObject ? getAreaObject[0] : null,
        });
      }
    } else {
    }
  };

  const consumoApiOficina = async (oficina) => {
    let oficinas = await services("GET", `catalago/OFICINA/${oficina}`, null);
    let oficinasDataApi = [];
    if (oficinas.status === 200) {
      if (!Array.isArray(oficinas.data.TB_RESULTADO)) {
        oficinasDataApi = [oficinas.data.TB_RESULTADO];
      } else {
        oficinasDataApi = oficinas.data.TB_RESULTADO;
      }
    }
    setOficina({
      ...oficinaState,
      apioficinas: oficinasDataApi,
      buscando: false,
    });
  };

  const saveUserApi = async (body) => {
    // console.log(body);
    let guardarUsuario = await services("POST", `usuarios/crear`, body);

    if (guardarUsuario.status === 200) {
      if (!guardarUsuario.data.created) {
        showNotification(
          "warning",
          "Alerta!",
          guardarUsuario.data.error,
          null,
          4
        );
      } else {
        const passwordUser = guardarUsuario.data.password;
        swal({
          title: "¡Éxito!",
          text: `La contraseña del usuario es ${passwordUser}`,
          icon: "success",
          button: "Aceptar",
        });
        document.querySelector(".swal-button--confirm").style.backgroundColor =
          colorUI;
        addDatosTabla(guardarUsuario.data.data);
        showNotification(
          "success",
          "Acción Exitosa!",
          guardarUsuario.data.mensage,
          null,
          4
        );
        onclose();
      }
    } else if (guardarUsuario.status === 422) {
      // console.log(guardarUsuario);
      swal("Error", guardarUsuario.data, "error");
    }
  };

  const updateUserApi = async (bodyUpdate, bodyUpdateOld) => {
    setState({
      ...state,
      loading: true,
    });
    let updateUsuario = await services(
      "PUT",
      `usuarios/editar/${bodyUpdate.id}`,
      bodyUpdate
    );
    if (updateUsuario.status === 200) {
      if (!updateUsuario.data.updated) {
        setState({
          ...state,
          loading: false,
        });
        swal({
          title: "Atención",
          text: updateUsuario.data.error,
          icon: "warning",
          button: "Aceptar",
        }).then(() => {});
      } else {
        bodyUpdateOld.id_municipio = bodyUpdate.id_municipio;
        bodyUpdateOld.id_oficina = bodyUpdate.id_oficina;
        bodyUpdateOld.rol = bodyUpdate.rol;
        bodyUpdateOld.email = bodyUpdate.email;
        bodyUpdateOld.area = updateUsuario.data.area;
        consumoApiUsers();
        // console.log(updateUsuario.data)

        editDatosTabla(bodyUpdateOld);
        showNotification(
          "success",
          "Acción Exitosa!",
          "El usuario se actualizó correctamente",
          null,
          4
        );
        onclose();
        setState({
          ...state,
          loading: false,
        });
      }
    } else {
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDataEdit((prevDataEdit) => ({
      ...prevDataEdit,
      [name]: value.toUpperCase(),
    }));
  };
  // const handleInputApePaterno = (e) => {
  //   const { name, value } = e.target;
  //   setDataEdit({
  //     ...dataEdit,
  //     [name]: value.toUpperCase(),
  //   });
  // };

  // const handleInputApeMaterno = (e) => {
  //   const { name, value } = e.target;
  //   setDataEdit({
  //     ...dataEdit,
  //     [name]: value.toUpperCase(),
  //   });
  // };

  const editCurpChange = async (e) => {
    let curp = e.toUpperCase();

    setCurpEdit(curp);

    if (curp.length === 18) {
      setState({
        ...state,
        loading: true,
      });

      const response = await services(
        "GET",
        `usuarios/consultaCurp/${curp}`,
        null
      );

      if (response.status === 200) {
        setState({
          ...state,
          loading: false,
        });
        showNotification(
          "success",
          "Atención",
          "Curp encontrada con éxito",
          null,
          4
        );
        setDataEdit({
          ...dataEdit,
          nombre: response.data.nombres,
          apePaterno: response.data.primerApellido,
          apeMaterno: response.data.segundoApellido,
        });
      } else {
        setState({
          ...state,
          loading: false,
        });
        showNotification(
          "warning",
          "Atención",
          "No se ha encontrado la curp",
          null,
          4
        );
        setDataEdit({
          nombre: "",
          apePaterno: "",
          apeMaterno: "",
        });
        setdata({ ...data, curp: curp, consultacurp: "success" });
      }
    } else {
      setdata({ ...data, curp: curp, consultacurp: "" });
    }
  };

  // const editCurpChange = async (e) => {
  //   let curp = e.toUpperCase();

  //   setCurpEdit(curp);

  //   if (curp.length === 18) {
  //     setState({
  //       ...state,
  //       loading: true,
  //     });
  //     const response = await services(
  //       "GET",
  //       `usuarios/consultaCurp/${curp}`,
  //       null
  //     );

  //     if (response.status === 200) {
  //       setState({
  //         ...state,
  //         loading: false,
  //       });
  //       showNotification(
  //         "success",
  //         "Atención",
  //         "Curp encontrada con éxito",
  //         null,
  //         4
  //       );
  //       setDataEdit({
  //         ...dataEdit,
  //         nombre: response.data.nombres,
  //         apePaterno: response.data.primerApellido,
  //         apeMaterno: response.data.segundoApellido,
  //       });
  //     } else {
  //       setState({
  //         ...state,
  //         loading: false,
  //       });
  //       showNotification(
  //         "warning",
  //         "Atención",
  //         "No se ha encontrado la curp",
  //         null,
  //         4
  //       );
  //       setDataEdit({
  //         ...dataEdit,
  //         nombre: "",
  //         apePaterno: "",
  //         apeMaterno: "",
  //       });
  //       setdata({ ...data, curp: curp, consultacurp: "success" });
  //     }
  //   } else {
  //     setdata({ ...data, curp: curp, consultacurp: "" });
  //   }
  // };

  const handleChangebuscarCurp = async (event) => {
    let curp = event.target.value.toUpperCase();
    if (curp.length === 18) {
      setState({
        ...state,
        loading: true,
      });
      const response = await services(
        "GET",
        `usuarios/consultaCurp/${curp}`,
        null
      );

      if (response.status === 200) {
        setState({
          ...state,
          loading: false,
        });
        showNotification(
          "success",
          "Éxito",
          "Curp encontrada con éxito",
          null,
          4
        );
        setdata({
          ...data,
          curp: curp,
          nombre: response.data.nombres,
          primerApellido: response.data.primerApellido,
          segundoApellido: response.data.segundoApellido,
          consultacurp: "success",
        });
      } else {
        setState({
          ...state,
          loading: false,
        });
        showNotification(
          "warning",
          "Atención",
          "No se ha encontrado la curp",
          null,
          4
        );
        setdata({ ...data, curp: curp, consultacurp: "success" });
      }
      // if (response.status === 404) {
      //   setdata({ ...data, nombre: '', primerApellido: '', segundoApellido: '', consultacurp: 'error', infocurp: 'CURP no valida.', });
      // }
    }
    // else if (curp.length < 1) {
    //   setdata({ ...data, nombre: '', primerApellido: '', segundoApellido: '' });
    // }
  };

  // const enviarDatosSap = () => {
  //   let email = document.getElementById("email").value;
  //   let id_municipio = data.municipio.IDENTIFICADOR;
  //   let id_oficina = data.oficina.IDENTIFICADOR;
  //   let id_dependencia = data.dependencia.id;
  //   let id_area = data.area.id;
  //   let rol = data.rol.name;

  //   let stateValidationUserRegister = {
  //     validEmail: false,
  //     validCurp: false,
  //     validMunicipio: false,
  //     validOficina: false,
  //     validDependencia: false,
  //     validRol: false,
  //     validName: false,
  //     validApaterno: false,
  //     validAmaterno: false,
  //   };

  //   if (state.rol !== "adminDependencia") {
  //     if (
  //       data.nombre === "" ||
  //       data.primerApellido === "" ||
  //       data.segundoApellido === "" ||
  //       email === "" ||
  //       data.curp === "" ||
  //       data.municipio === "" ||
  //       data.dependencia === "" ||
  //       data.oficina.IDENTIFICADOR === "0" ||
  //       data.rol === "" ||
  //       data.area === ""
  //     ) {
  //       if (email === "" || !emailValidation(email)) {
  //         stateValidationUserRegister.validEmail = true;
  //       }
  //       if (data.curp === "") {
  //         stateValidationUserRegister.validCurp = true;
  //       }
  //       if (data.municipio === "") {
  //         stateValidationUserRegister.validMunicipio = true;
  //       }
  //       if (data.oficina.IDENTIFICADOR === "0") {
  //         stateValidationUserRegister.validOficina = true;
  //       }
  //       if (data.dependencia === "") {
  //         stateValidationUserRegister.validDependencia = true;
  //       }
  //       if (data.rol === "") {
  //         stateValidationUserRegister.validRol = true;
  //       }
  //       if (data.nombre === "") {
  //         stateValidationUserRegister.validName = true;
  //       }
  //       if (data.primerApellido === "") {
  //         stateValidationUserRegister.validApaterno = true;
  //       }
  //       if (data.segundoApellido === "") {
  //         stateValidationUserRegister.validAmaterno = true;
  //       }

  //       setValidState({
  //         ...validState,
  //         curp: stateValidationUserRegister.validCurp,
  //         email: stateValidationUserRegister.validEmail,
  //         nombre: stateValidationUserRegister.validName,
  //         apellidoPaterno: stateValidationUserRegister.validApaterno,
  //         apellidoMaterno: stateValidationUserRegister.validAmaterno,
  //       });

  //       showNotification(
  //         "warning",
  //         "Campos requeridos!",
  //         "Por favor, llena todos los campos requeridos",
  //         null,
  //         4
  //       );
  //     } else if (!emailValidation(email)) {
  //       showNotification(
  //         "error",
  //         "Correo inválido",
  //         "Por favor, ingresa un correo electrónico válido.",
  //         null,
  //         4
  //       );
  //     } else {
  //       let body = {
  //         curp: data.curp,
  //         nombre: data.nombre,
  //         email: email,
  //         primerApellido: data.primerApellido,
  //         segundoApellido: data.segundoApellido,
  //         rol: rol,
  //         id_municipio: id_municipio,
  //         id_oficina: id_oficina,
  //         idDependencia: id_dependencia,
  //         fechaBaja: null,
  //         area_id: id_area,
  //         area: data.area.nombre,
  //       };
  //       if (state.rol === "adminDependencia") {
  //         body = {
  //           curp: data.curp,
  //           nombre: data.nombre,
  //           email: email,
  //           rol: "revisor",
  //           primerApellido: data.primerApellido,
  //           segundoApellido: data.segundoApellido,
  //           id_municipio: id_municipio,
  //           id_oficina: id_oficina,
  //           fechaBaja: null,
  //           area_id: id_area,
  //           area: data.area.nombre,
  //         };
  //       }
  //       saveUserApi(body);
  //     }
  //   } else {
  //     if (
  //       data.nombre === "" ||
  //       data.primerApellido === "" ||
  //       data.segundoApellido === "" ||
  //       email === "" ||
  //       data.curp === "" ||
  //       data.municipio === "" ||
  //       data.oficina.IDENTIFICADOR === "0"
  //     ) {
  //       if (email === "" || !emailValidation(email)) {
  //         stateValidationUserRegister.validEmail = true;
  //       }
  //       if (data.curp === "") {
  //         stateValidationUserRegister.validCurp = true;
  //       }
  //       if (data.municipio === "") {
  //         stateValidationUserRegister.validMunicipio = true;
  //       }
  //       if (data.oficina.IDENTIFICADOR === "0") {
  //         stateValidationUserRegister.validOficina = true;
  //       }
  //       if (state.rol !== "adminDependencia") {
  //         if (data.dependencia === "") {
  //           stateValidationUserRegister.validDependencia = true;
  //         }
  //         if (data.rol === "") {
  //           stateValidationUserRegister.validRol = true;
  //         }
  //       }
  //       if (data.nombre === "") {
  //         stateValidationUserRegister.validName = true;
  //       }
  //       if (data.primerApellido === "") {
  //         stateValidationUserRegister.validApaterno = true;
  //       }
  //       if (data.segundoApellido === "") {
  //         stateValidationUserRegister.validAmaterno = true;
  //       }

  //       setValidState({
  //         ...validState,
  //         curp: stateValidationUserRegister.validCurp,
  //         email: stateValidationUserRegister.validEmail,
  //         nombre: stateValidationUserRegister.validName,
  //         apellidoPaterno: stateValidationUserRegister.validApaterno,
  //         apellidoMaterno: stateValidationUserRegister.validAmaterno,
  //       });

  //       showNotification(
  //         "warning",
  //         "Campos requeridos!",
  //         "Por favor, llena todos los campos requeridos",
  //         null,
  //         4
  //       );
  //     } else if (!emailValidation(email)) {
  //       showNotification(
  //         "error",
  //         "Correo inválido",
  //         "Por favor, ingresa un correo electrónico válido.",
  //         null,
  //         4
  //       );
  //     } else {
  //       let body = {
  //         curp: data.curp,
  //         nombre: data.nombre,
  //         email: email,
  //         primerApellido: data.primerApellido,
  //         segundoApellido: data.segundoApellido,
  //         rol: rol,
  //         id_municipio: id_municipio,
  //         id_oficina: id_oficina,
  //         idDependencia: id_dependencia,
  //         fechaBaja: null,
  //         area_id: id_area,
  //         area: data.area,
  //       };
  //       if (state.rol === "adminDependencia") {
  //         body = {
  //           curp: data.curp,
  //           nombre: data.nombre,
  //           email: email,
  //           primerApellido: data.primerApellido,
  //           segundoApellido: data.segundoApellido,
  //           id_municipio: id_municipio,
  //           id_oficina: id_oficina,
  //           fechaBaja: null,
  //           rol: rol,
  //           area_id: id_area,
  //           area: data.area,
  //         };
  //       }
  //       saveUserApi(body);
  //     }
  //   }
  // };

  // Logica para activar al usuario para que se muestre el boton de asignar credenciales
  const enviarDatosSap = () => {
    let email = document.getElementById("email").value;
    let id_municipio = data.municipio?.IDENTIFICADOR || "";
    let id_oficina = data.oficina?.IDENTIFICADOR || "";
    let id_dependencia = data.dependencia?.id || "";
    let id_area = data.area?.id || "";
    let rolData = data.rol;
    let rol = localStorage.getItem("rol");

    let stateValidationUserRegister = {
      validEmail: false,
      validCurp: false,
      validMunicipio: false,
      validOficina: false,
      validDependencia: false,
      validRol: false,
      validName: false,
      validApaterno: false,
      validAmaterno: false,
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validación de campos requeridos
    let camposRequeridosVacios =
      data.nombre === "" ||
      data.primerApellido === "" ||
      data.segundoApellido === "" ||
      email === "" ||
      data.curp === "" ||
      !id_municipio ||
      (rol !== "adminArea" && id_oficina === "") ||
      (rol !== "adminDependencia" &&
        rol !== "adminArea" &&
        id_dependencia === "") ||
      (rol !== "adminDependencia" && rol !== "adminArea" && id_area === "") ||
      !data.rol?.name;

    // console.log({
    //   nombre: data.nombre,
    //   primerApellido: data.primerApellido,
    //   segundoApellido: data.segundoApellido,
    //   email: email,
    //   curp: data.curp,
    //   municipio: data.municipio,
    //   dependencia: data.dependencia,
    //   oficina: data.oficina.IDENTIFICADOR,
    //   rol: data.rol,
    //   area: data.area,
    // });

    if (camposRequeridosVacios) {
      if (email === "" || !emailRegex.test(email)) {
        stateValidationUserRegister.validEmail = true;
      }
      if (data.curp === "") {
        stateValidationUserRegister.validCurp = true;
      }
      if (!id_municipio) {
        stateValidationUserRegister.validMunicipio = true;
      }
      if (rol !== "adminArea" && id_oficina === "") {
        stateValidationUserRegister.validOficina = true;
      }
      if (
        rol !== "adminDependencia" &&
        rol !== "adminArea" &&
        id_dependencia === ""
      ) {
        stateValidationUserRegister.validDependencia = true;
      }
      if (rol !== "adminDependencia" && rol !== "adminArea" && id_area === "") {
        stateValidationUserRegister.validRol = true;
      }
      if (data.nombre === "") {
        stateValidationUserRegister.validName = true;
      }
      if (data.primerApellido === "") {
        stateValidationUserRegister.validApaterno = true;
      }
      if (data.segundoApellido === "") {
        stateValidationUserRegister.validAmaterno = true;
      }

      setValidState({
        ...validState,
        curp: stateValidationUserRegister.validCurp,
        email: stateValidationUserRegister.validEmail,
        nombre: stateValidationUserRegister.validName,
        apellidoPaterno: stateValidationUserRegister.validApaterno,
        apellidoMaterno: stateValidationUserRegister.validAmaterno,
      });

      if (email === "" || !emailRegex.test(email)) {
        showNotification(
          "warning",
          "Campos requeridos!",
          "Por favor, llena todos los campos requeridos y asegúrate de ingresar un correo electrónico válido",
          null,
          4
        );
      } else {
        showNotification(
          "warning",
          "Campos requeridos!",
          "Por favor, llena todos los campos requeridos",
          null,
          4
        );
      }
    } else if (!emailRegex.test(email)) {
      showNotification(
        "error",
        "Correo inválido",
        "Por favor, ingresa un correo electrónico válido.",
        null,
        4
      );
    } else {
      let body = {
        curp: data.curp,
        nombre: data.nombre,
        email: email,
        primerApellido: data.primerApellido,
        segundoApellido: data.segundoApellido,
        rol: rolData.name,
        id_municipio: id_municipio,
        id_oficina: id_oficina,
        ...(rol !== "adminDependencia" && { idDependencia: id_dependencia }),
        ...(rol !== "adminDependencia" && { area_id: id_area }),
        ...(rol !== "adminDependencia" && { area: data.area?.nombre }),
        fechaBaja: null,
      };

      if (rol === "adminDependencia") {
        body = {
          curp: data.curp,
          nombre: data.nombre,
          email: email,
          rol: rolData.name,
          primerApellido: data.primerApellido,
          segundoApellido: data.segundoApellido,
          id_municipio: id_municipio,
          id_oficina: id_oficina,
          fechaBaja: null,
          area_id: id_area,
          area: data.area?.nombre,
        };
      }

      saveUserApi(body);
    }
  };

  const actualizarDatosSAp = () => {
    let id_usuario = bodyUpdate.id;
    let curp = document.getElementById("curpEdit").value;
    let rol = stateUpdate.rolEdit.name;
    let id_municipio = stateUpdate.municipioEdit.IDENTIFICADOR;
    let id_oficina = stateUpdate.oficinaEdit.IDENTIFICADOR;
    let id_dependencia =
      stateUpdate.dependenciaEdit !== null
        ? stateUpdate.dependenciaEdit.id
        : null;
    //data.dependencia.id;
    let id_area =
      stateUpdate.areaUpdate !== null ? stateUpdate.areaUpdate.id : null; //data.area.id;
    // let id_area = stateUpdate.areaUpdate.id;

    // console.log(curp);

    let stateValidationUserUpdate = {
      validEmail: false,
      validMunicipio: false,
      validOficina: false,
      validRol: false,
    };

    if (id_municipio === "" || id_oficina === "0" || rol === "") {
      if (id_municipio === "") {
        stateValidationUserUpdate.validMunicipio = true;
      }
      if (id_oficina === "0") {
        stateValidationUserUpdate.validOficina = true;
      }
      if (rol === "") {
        stateValidationUserUpdate.validRol = true;
      }

      showNotification(
        "warning",
        "Campos requeridos!",
        "Por favor, llena todos los campos requeridos",
        null,
        4
      );
    } else {
      let bodyUpdated = {
        email: correoEdit,
        id: id_usuario,
        curp: curpEdit,
        nombre: dataEdit.nombre,
        primerApellido: dataEdit.apePaterno,
        segundoApellido: dataEdit.apeMaterno,
        rol: rol,
        id_municipio: id_municipio,
        id_oficina: id_oficina,
        id_dependencia: id_dependencia,
        area_id: id_area,
        area: data.area,
      };
      updateUserApi(bodyUpdated, bodyUpdate);
    }
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <>
      {state.loading ? <GifLoader /> : null}
      <OverLayIU
        onClick={() => {
          onclose();
        }}
      />
      <div className="wizard">
        {data.loading ? (
          <DotLoader />
        ) : (
          <>
            <div className="d-flex mb-3">
              <div className="col"></div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  onclose();
                }}
              >
                <IconUI size={15}>
                  <GrClose></GrClose>
                </IconUI>
              </div>
            </div>
            <div className="container my-3">
              {state.type === "update" ? (
                <div className="border-bottom--guinda text-big mb-2 text-bold">
                  {" "}
                  Edición de Usuario
                </div>
              ) : (
                <div className="border-bottom--guinda text-big mb-2 text-bold">
                  {" "}
                  Registro de Usuario
                </div>
              )}
            </div>
            {/* Registro Usuario */}
            {state.type === "form" ? (
              <form
                encType="multipart/form-data"
                method="post"
                name="formFisica"
              >
                <Grid container spacing={3}>
                  <Grid align="left" item xs={12} sm={12} md={6}>
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Correo Electrónico *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={validState.email}
                        onChange={(event) => {}}
                        onKeyPress={(event) => {
                          maxLengthLimitInput(event, 55, "email");
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="email"
                        name="email"
                        aria-describedby="my-helper-text"
                        required
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa el correo electrónico del usuario.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid align="right" item xs={12} sm={12} md={6}>
                    {/* CREAR USUARIO DESDE 0 */}
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        CURP *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={validState.curp}
                        onChange={handleChangebuscarCurp}
                        onKeyPress={(event) => {
                          maxLengthLimitInput(event, 17, "curp");
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="curp"
                        name="curp"
                        aria-describedby="my-helper-text"
                        required
                        endAdornment={
                          <InputAdornment position="end">
                            {data.consultacurp === "success" ? (
                              <OfflinePinIcon color="success" />
                            ) : (
                              <></>
                            )}
                            {data.consultacurp === "error" ? (
                              <ReportGmailerrorredIcon color="error" />
                            ) : (
                              <></>
                            )}
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa la CURP del usuario.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid align="center" item xs={12} sm={12} md={4}>
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Nombre *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={validState.nombre}
                        onChange={(event) => {
                          setdata({ ...data, nombre: event.target.value });
                        }}
                        onKeyPress={(event) => {}}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="nombre"
                        name="nombre"
                        aria-describedby="my-helper-text"
                        required
                        value={data.nombre}
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa el nombre del usuario.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid align="center" item xs={12} sm={12} md={4}>
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Apellido Paterno *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={validState.apellidoPaterno}
                        onChange={(event) => {
                          setdata({
                            ...data,
                            primerApellido: event.target.value,
                          });
                        }}
                        onKeyPress={(event) => {}}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="primerApellido"
                        name="primerApellido"
                        aria-describedby="my-helper-text"
                        required
                        value={data.primerApellido}
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa el apellido paterno del usuario.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid align="center" item xs={12} sm={12} md={4}>
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Apellido Materno *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={validState.apellidoMaterno}
                        onChange={(event) => {
                          setdata({
                            ...data,
                            segundoApellido: event.target.value,
                          });
                        }}
                        onKeyPress={(event) => {}}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="segundoApellido"
                        name="segundoApellido"
                        aria-describedby="my-helper-text"
                        required
                        value={data.segundoApellido}
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa el apellido materno del usuario.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid align="center" item xs={12} sm={12} md={6}>
                    <InputLabel
                      style={{ marginLeft: 0, marginTop: 35 }}
                      id="demo-simple-select-helper-label"
                    >
                      Municipio *
                    </InputLabel>
                    <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
                      <Select
                        style={{ zIndex: 110 }}
                        options={data.apimunicipios}
                        placeholder="Seleccione Municipio"
                        getOptionValue={(options) => options.IDENTIFICADOR}
                        getOptionLabel={(options) => options.DESCRIPCION}
                        onChange={(options) => handleChangeMunicipio(options)}
                        id="id_municipio"
                        name="id_municipio"
                        components={{ NoOptionsMessage }}
                      />

                      <FormHelperText>Selecciona un municipio.</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid align="center" item xs={12} sm={12} md={6}>
                    <InputLabel
                      style={{ marginLeft: 0, marginTop: 35 }}
                      id="demo-simple-select-helper-label"
                    >
                      Oficina *
                    </InputLabel>
                    <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
                      <Select
                        options={oficinaState.apioficinas}
                        placeholder="Seleccione Oficina"
                        isDisabled={oficinaState.buscando}
                        getOptionValue={(options) => options.IDENTIFICADOR}
                        getOptionLabel={(options) => options.DESCRIPCION}
                        onChange={(options) => handleChangeOficina(options)}
                        value={data.oficina}
                        id="id_oficina"
                        name="id_oficina"
                        components={{ NoOptionsMessage }}
                      />

                      <FormHelperText>Selecciona una oficina.</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid align="center" item xs={12} sm={12} md={6}>
                    {state.rol === "adminDependencia1" ? null : (
                      <>
                        <InputLabel
                          style={{ marginLeft: 0, marginTop: 35 }}
                          id="demo-simple-select-helper-label"
                        >
                          Rol *
                        </InputLabel>
                        <FormControl
                          sx={{ minWidth: "100%", maxWidth: "100%" }}
                        >
                          <Select
                            options={data.apiroles?.data}
                            placeholder="Seleccione Rol"
                            getOptionValue={(options) => options.name}
                            getOptionLabel={(options) => options.name}
                            onChange={(options) => handleChangeRol(options)}
                            id="rol"
                            name="rol"
                            components={{ NoOptionsMessage }}
                          />

                          <FormHelperText>
                            Selecciona el rol del usuario.
                          </FormHelperText>
                        </FormControl>
                      </>
                    )}
                  </Grid>
                  <Grid align="center" item xs={12} sm={12} md={6}>
                    {permisosDependencias.includes(rol) ? (
                      <>
                        <InputLabel
                          style={{ marginLeft: 0, marginTop: 35 }}
                          id="demo-simple-select-helper-label"
                        >
                          Dependencias *
                        </InputLabel>
                        <FormControl
                          sx={{ minWidth: "100%", maxWidth: "100%" }}
                        >
                          <Select
                            style={{ zIndex: 110 }}
                            options={data.apidependencias}
                            placeholder="Seleccione Dependencia"
                            getOptionValue={(options) =>
                              options.claveDependencia
                            }
                            getOptionLabel={(options) => options.nombre}
                            onChange={(options) =>
                              handleChangeDependencia(options)
                            }
                            id="id_dependencia"
                            name="id_dependencia"
                            components={{ NoOptionsMessage }}
                          />

                          <FormHelperText>
                            Selecciona una depencencia.
                          </FormHelperText>
                        </FormControl>
                      </>
                    ) : null}
                  </Grid>
                  <Grid align="center" item xs={12} sm={12} md={6}>
                    {/* {permisosAreas.includes(rol) &&
                    Array.isArray(data.apiareas) &&
                    data.apiareas.length > 0 ? (
                      <>
                        <InputLabel
                          style={{ marginLeft: 0, marginTop: 35 }}
                          id="demo-simple-select-helper-label"
                        >
                          Área *
                        </InputLabel>
                        <FormControl
                          sx={{ minWidth: "100%", maxWidth: "100%" }}
                        >
                          <Select
                            style={{ zIndex: 110 }}
                            options={data.apiareas}
                            placeholder="Seleccione Área"
                            getOptionValue={(options) => options.id}
                            getOptionLabel={(options) => options.nombre}
                            onChange={(options) => handleChangeArea(options)}
                            id="id_areas"
                            name="id_areas"
                            components={{ NoOptionsMessage }}
                          />
                          <FormHelperText>Selecciona un area.</FormHelperText>
                        </FormControl>
                      </>
                    ) : (
                      <p className="text-small">
                        <span className="text-danger">
                          Esta dependencia no tiene áreas
                        </span>{" "}
                        <br /> Por favor cree un área en el apartado{" "}
                        <span
                          onClick={() => history.push("/areas")}
                          className="text-decoration-underline fw-bold cursor-pointer"
                        >
                          ÁREAS
                        </span>
                      </p>
                    )} */}
                    {rol !== "adminArea" &&
                      (permisosAreas.includes(rol) &&
                      Array.isArray(data.apiareas) &&
                      data.apiareas.length > 0 ? (
                        <>
                          <InputLabel
                            style={{ marginLeft: 0, marginTop: 35 }}
                            id="demo-simple-select-helper-label"
                          >
                            Área *
                          </InputLabel>
                          <FormControl
                            sx={{ minWidth: "100%", maxWidth: "100%" }}
                          >
                            <Select
                              style={{ zIndex: 110 }}
                              options={data.apiareas}
                              placeholder="Seleccione Área"
                              getOptionValue={(options) => options.id}
                              getOptionLabel={(options) => options.nombre}
                              onChange={(options) => handleChangeArea(options)}
                              id="id_areas"
                              name="id_areas"
                              components={{ NoOptionsMessage }}
                            />
                            <FormHelperText>Selecciona un área.</FormHelperText>
                          </FormControl>
                        </>
                      ) : (
                        <p className="text-small">
                          <span className="text-danger">
                            Esta dependencia no tiene áreas
                          </span>{" "}
                          <br /> Por favor cree un área en el apartado{" "}
                          <span
                            onClick={() => history.push("/areas")}
                            className="text-decoration-underline fw-bold cursor-pointer"
                          >
                            ÁREAS
                          </span>
                        </p>
                      ))}
                  </Grid>
                </Grid>
                <div className="container">
                  <Grid sx={{ marginTop: 3 }} container spacing={2}>
                    <Grid align="center" item xs={12} sm={6} md={6}></Grid>
                    <Grid
                      className="d-flex justify-content-end gap-4"
                      align="right"
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      {(Array.isArray(data.apiareas) &&
                        data.apiareas.length > 0) ||
                      rol === "adminArea" ? (
                        <div
                          style={{ width: 200 }}
                          className="cta cta--guinda"
                          onClick={enviarDatosSap}
                        >
                          {" "}
                          Registrar
                        </div>
                      ) : (
                        <p>Al menos debe existir un área</p>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </form>
            ) : (
              <div dangerouslySetInnerHTML={createMarkup(html)}></div>
            )}
            {/* Edición de información */}
            {state.type === "update" ? (
              <form
                encType="multipart/form-data"
                method="post"
                name="formFisica"
              >
                <Grid container spacing={3}>
                  <Grid align="left" item xs={12} sm={12} md={6}>
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Correo Electrónico *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={validState.email}
                        onChange={(event) => {
                          setCorreoEdit(event.target.value);
                        }}
                        onKeyPress={(event) => {
                          maxLengthLimitInput(event, 55, "email");
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="email"
                        name="email"
                        aria-describedby="my-helper-text"
                        required
                        value={correoEdit}
                      />
                      <FormHelperText id="my-helper-text">
                        Correo electrónico del usuario.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid align="right" item xs={12} sm={12} md={6}>
                    {/* EDITAR CURP */}
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        CURP *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={validState.curp}
                        // onChange={handleChangebuscarCurp}
                        onChange={(e) => editCurpChange(e.target.value)}
                        onKeyPress={(event) => {
                          maxLengthLimitInput(event, 17, "curp");
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="curpEdit"
                        name="curpEdit"
                        value={curpEdit}
                        aria-describedby="my-helper-text"
                        required
                        // disabled
                        endAdornment={
                          <InputAdornment position="end">
                            {data.consultacurp === "success" ? (
                              <OfflinePinIcon color="success" />
                            ) : (
                              <></>
                            )}
                            {data.consultacurp === "error" ? (
                              <ReportGmailerrorredIcon color="error" />
                            ) : (
                              <></>
                            )}
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="my-helper-text">
                        {data.infocurp}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid align="center" item xs={12} sm={12} md={12}>
                    {permisosDependencias.includes(rol) ? (
                      <>
                        <InputLabel
                          style={{ marginLeft: 0, marginTop: 35 }}
                          id="demo-simple-select-helper-label"
                        >
                          Dependencias *
                        </InputLabel>
                        <FormControl sx={{ minWidth: 230, maxWidth: "100%" }}>
                          <Select
                            style={{ zIndex: 110 }}
                            options={data.apidependencias}
                            placeholder="Seleccione Dependencia"
                            getOptionValue={(options) =>
                              options.claveDependencia
                            }
                            getOptionLabel={(options) => options.nombre}
                            onChange={(options) =>
                              handleChangeDependenciaEdit(options)
                            }
                            value={stateUpdate.dependenciaEdit}
                            id="id_dependencia"
                            name="id_dependencia"
                            components={{ NoOptionsMessage }}
                          />

                          <FormHelperText>
                            Selecciona una depencencia.
                          </FormHelperText>
                        </FormControl>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid align="center" item xs={12} sm={12} md={4}>
                    {/* EDITAR USUARIO */}
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Nombre *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={false}
                        onChange={handleInputChange}
                        onKeyPress={(event) => {}}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="nombre"
                        name="nombre"
                        value={dataEdit.nombre}
                        aria-describedby="my-helper-text"
                        required
                      />
                      <FormHelperText id="my-helper-text">
                        Nombre del usuario.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid align="center" item xs={12} sm={12} md={4}>
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Apellido Paterno *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={false}
                        onChange={handleInputChange}
                        onKeyPress={(event) => {}}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="primerApellido"
                        name="apePaterno"
                        aria-describedby="my-helper-text"
                        value={dataEdit.apePaterno}
                      />
                      <FormHelperText id="my-helper-text">
                        Apellido paterno del usuario.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid align="center" item xs={12} sm={12} md={4}>
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Apellido Materno *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={false}
                        onChange={handleInputChange}
                        onKeyPress={(event) => {}}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="segundoApellido"
                        name="apeMaterno"
                        aria-describedby="my-helper-text"
                        required
                        value={dataEdit.apeMaterno}
                      />
                      <FormHelperText id="my-helper-text">
                        Apellido materno del usuario.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid align="left" item xs={12} sm={12} md={4}>
                    <InputLabel
                      style={{ marginLeft: 0, marginTop: 35 }}
                      id="demo-simple-select-helper-label"
                    >
                      Municipio *
                    </InputLabel>
                    <FormControl sx={{ minWidth: 230, maxWidth: "100%" }}>
                      <Select
                        required
                        options={data.apimunicipios}
                        placeHolder="Seleccione Municipo"
                        getOptionValue={(options) => options.IDENTIFICADOR}
                        getOptionLabel={(options) => options.DESCRIPCION}
                        onChange={(options) =>
                          handleChangeMunicipioEdit(options)
                        }
                        value={stateUpdate.municipioEdit}
                        id="id_municipio"
                        name="id_municipio"
                        components={{ NoOptionsMessage }}
                      />

                      <FormHelperText>Selecciona un municipio.</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid align="left" item xs={12} sm={12} md={4}>
                    <InputLabel
                      style={{ marginLeft: 0, marginTop: 35 }}
                      id="demo-simple-select-helper-label"
                    >
                      Oficina *
                    </InputLabel>
                    <FormControl sx={{ minWidth: 230, maxWidth: "100%" }}>
                      <Select
                        placeHolder="Seleccione Oficina"
                        isDisabled={oficinaState.buscando}
                        options={oficinaState.apioficinas}
                        getOptionValue={(options) => options.IDENTIFICADOR}
                        getOptionLabel={(options) => options.DESCRIPCION}
                        onChange={(options) => handleChangeOficinaEdit(options)}
                        value={stateUpdate.oficinaEdit}
                        id="id_oficina"
                        name="id_oficina"
                        components={{ NoOptionsMessage }}
                      />

                      <FormHelperText>Selecciona una oficina.</FormHelperText>
                    </FormControl>
                  </Grid>
                  {state.rol === "adminDependencia" ? null : (
                    <>
                      <Grid align="left" item xs={12} sm={12} md={4}>
                        <InputLabel
                          style={{ marginLeft: 0, marginTop: 35 }}
                          id="demo-simple-select-helper-label"
                        >
                          Rol *
                        </InputLabel>
                        <FormControl sx={{ minWidth: 230, maxWidth: "100%" }}>
                          <Select
                            isDisabled={currentUser.curp === bodyUpdate.curp}
                            options={data.apiroles?.data}
                            getOptionValue={(options) => options.name}
                            getOptionLabel={(options) => options.name}
                            onChange={(options) => handleChangeRolEdit(options)}
                            value={stateUpdate.rolEdit}
                            id="rol"
                            name="rol"
                            components={{ NoOptionsMessage }}
                          />

                          <FormHelperText>
                            Selecciona el rol del usuario.
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  {permisosAreas.includes(rol) ? (
                    <Grid align="center" item xs={12} sm={12} md={6}>
                      <InputLabel
                        style={{ marginLeft: 0, marginTop: 35 }}
                        id="demo-simple-select-helper-label"
                      >
                        Área *
                      </InputLabel>
                      <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
                        <Select
                          style={{ zIndex: 110 }}
                          options={data.apiareas}
                          placeholder="Seleccione Área"
                          getOptionValue={(options) => options.id}
                          getOptionLabel={(options) => options.nombre}
                          onChange={(options) => handleChangeAreaEdit(options)}
                          id="id_areas"
                          name="id_areas"
                          components={{ NoOptionsMessage }}
                          value={stateUpdate.areaUpdate}
                        />

                        <FormHelperText>Selecciona un área.</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>

                <div className="container">
                  <Grid sx={{ marginTop: 3 }} container spacing={2}>
                    <Grid align="center" item xs={12} sm={6} md={6}></Grid>
                    <Grid
                      className="d-flex justify-content-end gap-4"
                      align="right"
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <div
                        style={{ width: 200 }}
                        className="cta cta--guinda"
                        onClick={actualizarDatosSAp}
                      >
                        {" "}
                        Actualizar
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </form>
            ) : (
              <div dangerouslySetInnerHTML={createMarkup(html)}></div>
            )}
          </>
        )}
      </div>
    </>
  );
}
