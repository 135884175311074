import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import GifLoader from "../components/ui/gifLoader";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField,
  Tooltip,
} from "@mui/material";
import IconUI from "../components/ui/icon_ui";
import { services } from "../services/api";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import ModalCertificadoCedula from "../components/ModalCertificadoCedula";
import ModalCertificadoCedulaEstado from "../components/ModalCertificadoCedulaEstado";
import ModalCertificadoCedulaEstadoPago from "../components/ModalCertificadoCedulaEstadoPago";
import ModalCedulaProvisional from "../components/ModalCedulaProvisional";

import { useParams } from "react-router-dom";
import {
  purple,
  red,
  lightGreen,
  orange,
  deepOrange,
} from "@mui/material/colors";

const colorUI = "#6f1d46";
// const colorUI = "#8d949e";

const Terminado = lightGreen["A400"];
const EnProseso = purple["A400"];
const Rechazada = red["A700"];
const EnCambio = orange["600"];
const Pendiente = deepOrange["800"];

const styleConfigModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
  p: 4,
};

const CardFile = styled(Card)(({ theme }) => ({
  height: "200px",
  width: "250px",
  margin: "auto",
  marginTop: "20px",
  borderRadius: "12px",
  boxShadow: "1px 0px 7px 0px rgba(0, 0, 0, 0.75)",
  "& .MuiCardContent-root": {
    // borderColor: '#6A0F49',
    borderColor: "#8d949e",
    borderStyle: "dotted",
    height: "80%",
    margin: "20px 20px 20px 20px",
  },
}));

const options = {
  selectableRows: false,
  tableBodyHeight: "auto",
  textLabels: {
    body: {
      noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
      toolTip: "Clasificar",
      columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
    },
    pagination: {
      next: "Siguiente",
      previous: "Anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tabla",
    },
    filter: {
      all: "Todo",
      title: "Filtros",
      reset: "Restaurar",
    },
    viewColumns: {
      title: "Ver columnas",
      titleAria: "Mostrar/Ocultar columnas en la tabla",
    },
    selectedRows: {
      text: "columna(s) selecciona(as)",
      delete: "Eliminar",
      deleteAria: "Eliminar filas seleccionadas",
    },
  },
  responsive: "standard",
  downloadOptions: {
    filename: "tablaSolicitudes.csv",
    filterOptions: {
      useDisplayedColumnsOnly: false,
      useDisplayedRowsOnly: false,
    },
  },
};

const SolicitudCertificadoView = () => {
  const { folio } = useParams();

  const ValidStatus = ["Abierto", "En cambio", "En Proceso", "Proceso"];
  const [state, setState] = useState({
    loading: false,
    consulta: false,
    newFile: {
      previewFotografia: "",
      frameFotografia: true,
      Archivo: "",
    },
  });

  const [cedula, setCedula] = useState({
    folio: "",
    fecha: "",
    estatus: "",
    tipo: "",
    lcaptura: "",
    data: {},
    cadenaEncriptada: "",
    observaciones: "",
    email: "",
    open: false,
    openE: false,
    openP: false,
    openSolicitudCertificado: false,
    openCedulaProvicional: false,
  });

  const handleCloseModalCedula = () => {
    setCedula({
      folio: "",
      fecha: "",
      estatus: "",
      tipo: "",
      lcaptura: "",
      data: {},
      cadenaEncriptada: "",
      observaciones: "",
      email: "",
      open: false,
      openE: false,
      openP: false,
      openSolicitudCertificado: false,
      openCedulaProvicional: false,
    });
  };

  const handleFinish = () => {
    // console.log("entro");
    setCedula({
      folio: "",
      fecha: "",
      estatus: "",
      tipo: "",
      lcaptura: "",
      data: {},
      cadenaEncriptada: "",
      observaciones: "",
      email: "",
      open: false,
      openE: false,
      openP: false,
      openSolicitudCertificado: false,
      openCedulaProvicional: false,
    });
    getApi();
  };

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleFotografia = (event) => {
    const file = event.target.files[0];
    setState({
      ...state,
      newFile: {
        ...state.newFile,
        previewFotografia: URL.createObjectURL(file),
        frameFotografia: false,
        Archivo: file,
      },
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, archivo) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const file = event.dataTransfer.files[0];

    switch (archivo) {
      case "fotografia":
        handleFotografia({ target: { files: [file] } });
        break;
      default:
        break;
    }

    event.currentTarget.style.border = "";
    event.currentTarget.style.filter = "";
  };

  const handleDragEnter = (event, card) => {
    event.preventDefault();
    setIsDraggingOver(true);
    if (card == "file") {
      event.currentTarget.style.border = `2px dashed ${colorUI}`;
      event.currentTarget.style.filter = "blur(1px)";
    } else if (card == "content") {
    }
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    if (!isDraggingOver) {
      event.currentTarget.style.border = "none";
      event.currentTarget.style.filter = "none";
    }
  };

  const handleCloseDinamicForm = () => {
    setConfiguradorData({
      nombreForm: "",
      data: [],
      folio: "",
      Estatus: "",
      Fecha: "",
      id_formulario: "",
      id: "",
      documentos: [],
      open: false,
      rfc: null,
      email: null,
      razon_social: null,
      importe: null,
      cantidad: null,
    });
  };
  const history = useHistory();

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "folio",
      label: "Folio",
    },
    {
      name: "concepto",
      label: "Concepto",
    },
    {
      name: "revisor",
      label: "Revisor",
      options: {
        customBodyRenderLite: (dataIndex, i) => {
          let revisor = data[dataIndex].usuarioRevision;

          return (
            <>
              <div
                style={{ height: "60px" }}
                className={
                  "w-100 d-flex justify-content-center align-items-center "
                }
              >
                <p> {revisor ? revisor : "N/A"}</p>
              </div>
            </>
          );
        },
      },
    },
    {
      name: "leyenda",
      label: "Descripción",
      options: {
        customBodyRenderLite: (dataIndex, i) => {
          let descrip = data[dataIndex].conceptoConfigurador
            ? data[dataIndex].conceptoConfigurador
            : data[dataIndex].leyenda;
          return (
            <>
              <div
                style={{ height: "60px" }}
                className={
                  "w-100 d-flex justify-content-center align-items-center "
                }
              >
                <p> {descrip}</p>
              </div>
            </>
          );
        },
      },
    },
    {
      name: "fechaCreacion",
      label: "Fecha Creación",
    },
    {
      name: "estatus",
      label: "Estatus",
      options: {
        customBodyRenderLite: (dataIndex, i) => {
          let estatus = data[dataIndex].estatus;
          let color, text;
          switch (estatus) {
            case "Pagado":
              // color = "primary";
              color = "#2271B3";
              text = "Pagado";
              break;
            case "Abierto":
              color = "#2271B3";
              text = "Abierto";
              break;
            case "En Proceso":
              color = EnProseso;
              text = "Proceso";
              break;
            case "Terminada":
              color = Terminado;
              text = "Terminada";
              break;
            case "Cancelado":
              color = Rechazada;
              text = "Cancelado";
              break;
            case "Rechazada":
              color = Rechazada;
              text = "Rechazada";
              break;
            case "Validada":
              color = Terminado;
              text = "Validada";
              break;
            case "En cambio":
              color = EnCambio;
              text = "En cambio";
              break;

            case "Pendiente de pago":
              color = Pendiente;
              text = "Pendiente de pago";
              break;

            case "Pendiente de envio de resultado":
              color = Pendiente;
              text = "Pendiente de envio";
              break;

            case "Pendiente de descarga de resultado":
              color = Pendiente;
              text = "Pendiente de descarga";
              break;

            default:
              break;
          }
          return (
            <>
              <div
                style={{ height: "60px" }}
                className={
                  "w-100 d-flex justify-content-center align-items-center "
                }
              >
                <p>
                  {" "}
                  {text}{" "}
                  <FiberManualRecordIcon
                    sx={{ color: color }}
                    fontSize="small"
                  />
                </p>
                {/* <Tooltip title={text} >
                            <Chip label={text} color={color} variant="outlined" size="small"/>

                            </Tooltip> */}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => {
          let getFolio = data[dataIndex].folio;
          return (
            <>
              <div
                style={{ height: "60px" }}
                className={
                  "w-100 d-flex justify-content-center align-items-center "
                }
              >
                <Tooltip title="Detalle">
                  <div
                    style={{
                      height: 30,
                      width: 30,
                      zIndex: 0,
                    }}
                    className="cta--icon icon_btn"
                    onClick={() => {
                      getDetailDig(getFolio);
                    }}
                  >
                    <IconUI size={"20px"}>
                      <VisibilityIcon
                        style={{ size: "20px", color: "#6f1d46" }}
                      />
                    </IconUI>
                  </div>
                </Tooltip>
              </div>
            </>
          );
        },
      },
    },
  ];
  let date = new Date();
  let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [filtros, setFiltros] = useState({
    fechaI: dayjs().format("YYYY-01-01"),
    fechaF: dayjs().format(`YYYY-MM-${ultimoDia.getDate()}`),
    folio: "",
    estatus: [0, 1],
  });

  const [ConfiguradorData, setConfiguradorData] = useState({
    nombreForm: "",
    data: [],
    folio: "",
    Estatus: "",
    Fecha: "",
    id_formulario: "",
    id: "",
    documentos: [],
    open: false,
    rfc: null,
    email: null,
    razon_social: null,
    importe: null,
    cantidad: null,
  });

  const handleFiltros = ({ target }) => {
    const { name, value } = target;
    setFiltros({ ...filtros, [name]: value });
  };

  const getDetailDig = async (folio) => {
    setState({ ...state, loading: true, folioEnviarArchivo: folio });
    let uniqueFolio = await services("POST", "solicitudes/list", {
      folio: folio,
    });

    if (uniqueFolio.status === 200) {
      if (uniqueFolio.data.status) {
        swal(
          "Token Expirado",
          "Favor de iniciar sesión de nuevo",
          "warning"
        ).then(() => {
          history.replace("/login");
        });
      } else {
        if (
          uniqueFolio.data.tipo ===
            "Solicitud de Registro De Título Profesional" &&
          uniqueFolio.data.estatus === "Pagado"
        ) {
          const {
            email,
            fecha,
            folio,
            estatus,
            data,
            tipo,
            lcaptura,
            cadenaEncriptada,
            observaciones,
          } = uniqueFolio.data;

          // console.log("QR", uniqueFolio.data);
          setCedula({
            folio: folio,
            fecha: fecha,
            estatus: estatus,
            tipo: tipo,
            lcaptura: lcaptura,
            data: data,
            cadenaEncriptada: cadenaEncriptada,
            observaciones: observaciones,
            email: email,
            openP: true,
          });
        } else if (
          uniqueFolio.data.tipo ===
          "Solicitud de Registro De Título Profesional"
        ) {
          const {
            email,
            fecha,
            folio,
            estatus,
            data,
            tipo,
            lcaptura,
            cadenaEncriptada,
            observaciones,
          } = uniqueFolio.data;

          setCedula({
            folio: folio,
            fecha: fecha,
            estatus: estatus,
            tipo: tipo,
            lcaptura: lcaptura,
            data: data,
            cadenaEncriptada: cadenaEncriptada,
            observaciones: observaciones,
            email: email,

            openE: true,
          });
        } else if (uniqueFolio.data.tipo === "Tramite") {
          const {
            rfc,
            email,
            razon_social,
            fecha,
            folio,
            estatus,
            data: { id, id_formulario, datos },
            importe,
            cantidad,
          } = uniqueFolio.data;

          const { campos, nombre } = datos[0];

          const getdocumentos = campos.filter((item) => item.type === "file");
          const documentArray = [];

          setConfiguradorData({
            documentos: getdocumentos,
            nombreForm: nombre,
            data: campos.filter((item) => item.type !== "file"),
            folio: folio,
            Estatus: estatus,
            Fecha: fecha,
            id_formulario: id_formulario,
            id: id,
            open: true,
            rfc: rfc,
            email: email,
            razon_social: razon_social,
            importe: importe,
            cantidad: cantidad,
          });
        } else if (
          uniqueFolio.data.tipo === "Constancia  De Registro De C\u00e9dula" ||
          uniqueFolio.data.tipo ===
            "Solicitud de Registro De C\u00e9dula Profesional"
        ) {
          // alert("simon")
          const {
            email,
            fecha,
            folio,
            estatus,
            data,
            tipo,
            lcaptura,
            cadenaEncriptada,
            observaciones,
          } = uniqueFolio.data;

          setCedula({
            folio: folio,
            fecha: fecha,
            estatus: estatus,
            tipo: tipo,
            lcaptura: lcaptura,
            data: data,
            cadenaEncriptada: cadenaEncriptada,
            observaciones: observaciones,
            email: email,

            open: true,
          });
        } else if (uniqueFolio.data.tipo === "Solicitud de Cédula") {
          const {
            email,
            fecha,
            folio,
            estatus,
            data,
            tipo,
            lcaptura,
            cadenaEncriptada,
            observaciones,
          } = uniqueFolio.data;

          setCedula({
            ...cedula,
            folio: folio,
            fecha: fecha,
            estatus: estatus,
            tipo: tipo,
            lcaptura: lcaptura,
            data: data,
            cadenaEncriptada: cadenaEncriptada,
            observaciones: observaciones,
            email: email,

            openSolicitudCertificado: true,
          });
        } else if (
          uniqueFolio.data.tipo ===
          "Solicitud de Registro De Cédula Provisional"
        ) {
          const {
            email,
            fecha,
            folio,
            estatus,
            data,
            tipo,
            lcaptura,
            cadenaEncriptada,
            observaciones,
          } = uniqueFolio.data;
          setCedula({
            ...cedula,
            folio: folio,
            fecha: fecha,
            estatus: estatus,
            tipo: tipo,
            lcaptura: lcaptura,
            data: data,
            cadenaEncriptada: cadenaEncriptada,
            observaciones: observaciones,
            email: email,

            openCedulaProvicional: true,
          });
        } else {
          setonlyFolio(uniqueFolio.data);
          setOpen(true);
        }
      }
    } else {
      swal("Hubo un error", uniqueFolio.error, "warning");
    }
    setState({ ...state, loading: false, folioEnviarArchivo: folio });
  };

  const [onlyFolio, setonlyFolio] = useState({
    folio: "",
    fecha: "",
    estatus: "",
    tipo: "",
    lcaptura: null,
    data: {
      id: "",
      curp: "",
      nivel: "",
      centroTrabajo: "",
      cicloEscolar: "",
      estatus: "",
      archivo: "",
      datos_adicionales: "null",
      created_at: "",
      updated_at: "",
    },
  });

  const [data, setData] = useState([]);

  let getApi = async () => {
    setState({ ...state, loading: true });
    let solicitudResponse = await services("POST", "solicitudes/list", {
      fechaInicio: filtros.fechaI,
      fechaFin: filtros.fechaF,
      estatus: filtros.estatus,
      folio: filtros.folio,
    });

    let catalogoEstatus = await services(
      "GET",
      "solicitudes/estatus/list",
      null
    );

    if (catalogoEstatus.status === 200) {
      let newCat = catalogoEstatus.data.map((item) => {
        item.label = item.descripcion;
        item.value = item.id;
        return item;
      });
      const todos = {
        id: -1,
        descripcion: "Todos",
        label: "Todos",
        value: -1,
      };
      newCat.push(todos);
      setNames(newCat);
    } else {
      swal("Hubo un error", catalogoEstatus.error, "warning");
    }
    if (solicitudResponse.status === 200) {
      if (solicitudResponse.data.status) {
        swal(
          "Token Expirado",
          "Favor de iniciar sesión de nuevo",
          "warning"
        ).then(() => {
          history.replace("/login");
        });
      } else {
        setData(solicitudResponse.data);
      }
    } else {
      swal("Hubo un error", solicitudResponse.error, "warning");
    }
    setState({ ...state, loading: false });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getApi();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const [area, setArea] = useState("");

  const handleVincu = async (estatus) => {
    if (area.length === 0 && estatus === 5) {
      swal("Campo Observaciones", "El campo no debe ir vacío.", "warning");
      setArea("");
    } else {
      setState({ ...state, loading: true });

      const body = {
        folio:
          onlyFolio.folio !== "" ? onlyFolio.folio : ConfiguradorData.folio,
        estatus: estatus,
        observaciones: area,
      };

      let apiActualizar = await services(
        "POST",
        "solicitudes/actualizar",
        body
      );

      if (apiActualizar.status === 200) {
        if (apiActualizar.data) {
          swal("Solicitud Actualizada", " ", "success").then(() => {
            getApi();
            handleClose();
            handleCloseDinamicForm();
          });
        }
      } else {
        swal("Hubo un error", apiActualizar.error, "warning");
      }
      setState({ ...state, loading: false });
      setArea("");
    }
  };

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // console.log("prueba", value);
    if (value.length === 10 && !value.includes("Todos")) {
      setPersonName([]);
    } else {
      if (value.includes("Todos")) {
        let filtro = names.map((name) => name.label);

        setPersonName(filtro);
        setFiltros({ ...filtros, estatus: names.map((name) => name.value) });
      } else {
        // Si no, procede como antes
        setPersonName(typeof value === "string" ? value.split(",") : value);
        let intersection = names.filter((x) => value.includes(x.label));
        let array = intersection.map((elem) => elem.value);
        setFiltros({
          ...filtros,
          estatus: array.length !== 0 ? array : [0, 1],
        });
      }
    }
  };

  const downloadPDF = (documentLink, name, callback, extension) => {
    try {
      const linkSource = `data:application/${extension};base64,${documentLink}`;
      const downloadLink = document.createElement("a");
      const fileName = `${name}.${extension}`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      if (callback !== null) {
        callback();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const [names, setNames] = useState([]);

  const setComponent = (component) => {
    return (
      <>
        <InputLabel className="mx-2  FormularioDinamicoRadios">
          {component.label}
        </InputLabel>
        <TextField
          id={component.id}
          disabled
          name={component.name}
          // type={component.type}
          type="text"
          fullWidth
          defaultValue={component.value}
        />
      </>
    );
  };

  const handleSubirArchivoFinal = async () => {
    setState({ ...state, loading: true });

    const formData = new FormData();

    formData.append("folio", state.folioEnviarArchivo);
    formData.append("adjunto", state.newFile.Archivo);

    try {
      const enviarPdf = await services(
        "POST",
        "solicitudes/peticion/entrega",
        formData,
        true
      );

      if (enviarPdf.status === 200) {
        // console.log(enviarPdf);

        setState({
          ...state,
          loading: false,
          newFile: {
            previewFotografia: "",
            frameFotografia: true,
            Archivo: "",
          },
        });

        swal(enviarPdf.data.mensaje, "", "success").then(() => {
          getApi();
          handleCloseDinamicForm();
        });
      } else {
        setState({ ...state, loading: false });

        swal("¡Atención!", enviarPdf.error, "warning").then(() => {
          handleClose();
        });
        // console.log("Error", enviarPdf);
      }
    } catch (error) {
      setState({ ...state, loading: false });
      console.error("Error al enviar el archivo:", error);
    }
  };
  return (
    <>
      {state.loading ? <GifLoader></GifLoader> : null}
      <div>
        <div
          style={{ columnGap: 15, rowGap: 20 }}
          className="d-flex flex-wrap flex-row justify-content-center m-2"
        >
          <TextField
            InputLabelProps={{ shrink: true }}
            onChange={handleFiltros}
            value={filtros.fechaI}
            name="fechaI"
            label="Fecha Inicio"
            size="small"
            type="date"
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            onChange={handleFiltros}
            value={filtros.fechaF}
            name="fechaF"
            label="Fecha Fin"
            size="small"
            type="date"
          />
          <TextField
            className="d-none"
            onChange={handleFiltros}
            value={filtros.folio}
            name="folio"
            label="Folio"
            size="small"
          />
          <FormControl sx={{ width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label" size="small">
              Estatus
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              size="small"
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput size="small" label="Estatus" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {names.map((name) => (
                <MenuItem key={name.value} value={name.label} name={name.label}>
                  <Checkbox checked={personName.indexOf(name.label) > -1} />
                  <ListItemText primary={name.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#6f1d46",
            }}
            onClick={() => getApi()}
          >
            Filtrar
          </Button>
        </div>
        <div className="card m-3 text-center">
          <MUIDataTable
            style={{ Overflow: "hidden" }}
            title="Bandeja de Solicitudes"
            data={data}
            columns={columns}
            options={options}
          />
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Detalles de Solicitud
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <div className="d-flex flex-column flex-wrap ">
              <p>
                <strong>Folio: </strong> {onlyFolio?.folio}
              </p>
              <p>
                <strong>Fecha: </strong>
                {onlyFolio?.fecha}
              </p>
              <p>
                <strong>Estatus: </strong>
                {onlyFolio?.estatus}
              </p>
              <p>
                <strong>Tipo: </strong>
                {onlyFolio?.tipo}
              </p>
              {onlyFolio?.importe ? (
                <p>
                  <strong>Importe: $</strong>
                  {onlyFolio?.importe}
                </p>
              ) : null}
              {onlyFolio?.cantidad ? (
                <p>
                  <strong>Cantidad: </strong>
                  {onlyFolio?.cantidad}
                </p>
              ) : null}
              <p>
                <strong>Curp: </strong>
                {onlyFolio?.data.curp}
              </p>
              <p>
                <strong>Centro de trabajo: </strong>
                {onlyFolio?.data?.centroTrabajo}
              </p>
              {onlyFolio.data.archivo && (
                <p
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    downloadPDF(
                      onlyFolio.data.archivo,
                      "Archivo_anexado",
                      null,
                      onlyFolio.data?.extension
                    )
                  }
                >
                  <CloudDownloadIcon />
                  Descargar Archivo
                </p>
              )}
              {onlyFolio.estatus === "Rechazada" && (
                <p>
                  <strong>Observaciones:</strong> <br />
                  {onlyFolio.observaciones ?? "N/A"}
                </p>
              )}
              {onlyFolio.estatus === "Abierto" && (
                <>
                  <TextField
                    rows={3}
                    multiline
                    label="Observaciones"
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                  />
                </>
              )}
              {/* <Button variant='contained'>Cambiar] estatus</Button> */}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "auto" }}
            sx={{ backgroundColor: "#6f1d46" }}
            onClick={handleClose}
          >
            Cerrar
          </Button>
          {onlyFolio.estatus === "Abierto" && (
            <>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleVincu(5)}
              >
                Rechazar
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#6f1d46",
                }}
                onClick={() => handleVincu(2)}
              >
                Validar
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Modal
        open={ConfiguradorData.open}
        onClose={handleCloseDinamicForm}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box sx={styleConfigModal}>
          <DialogTitle id="scroll-dialog-title">
            Detalles de Solicitud <br /> {ConfiguradorData.nombreForm}
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <div className="d-flex flex-column flex-wrap ">
                <p>
                  <strong>Folio: </strong> {ConfiguradorData?.folio}
                </p>
                <p>
                  <strong>Fecha: </strong>
                  {ConfiguradorData?.Fecha}
                </p>
                <p>
                  <strong>Estatus: </strong>
                  {ConfiguradorData?.Estatus}
                </p>

                {ConfiguradorData?.email ? (
                  <p>
                    <strong>Email: </strong> {ConfiguradorData?.email}
                  </p>
                ) : null}
                {ConfiguradorData?.rfc ? (
                  <p>
                    <strong>RFC: </strong> {ConfiguradorData?.rfc}
                  </p>
                ) : null}
                {ConfiguradorData?.razon_social ? (
                  <p>
                    <strong>Razón Social: </strong>{" "}
                    {ConfiguradorData?.razon_social}
                  </p>
                ) : null}
                {ConfiguradorData?.importe ? (
                  <p>
                    <strong>Importe: $</strong> {ConfiguradorData?.importe}
                  </p>
                ) : null}
                {ConfiguradorData?.cantidad ? (
                  <p>
                    <strong>Cantidad: </strong> {ConfiguradorData?.cantidad}
                  </p>
                ) : null}
                {ConfiguradorData?.Estatus ===
                "Pendiente de envio de resultado" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "2%",
                    }}
                  >
                    <div>
                      <label
                        htmlFor="inputArchivo"
                        style={{
                          backgroundColor: colorUI,
                          color: "white",
                          borderRadius: "12px",
                          cursor: "pointer",
                          padding: "5px 7px",
                          marginBottom: "10px",
                        }}
                      >
                        {" "}
                        cargar archivo
                      </label>
                      <input
                        type="file"
                        id="inputArchivo"
                        style={{ display: "none" }}
                        accept=".pdf, .jpg, .jpeg, .png"
                        onChange={handleFotografia}
                      />
                      <DeleteForeverOutlinedIcon
                        style={{ color: "red", cursor: "pointer" }}
                        fontSize="large"
                        onClick={() => {
                          setState({
                            ...state,
                            newFile: {
                              ...state.newFile,
                              previewFotografia: "",
                              frameFotografia: true,
                            },
                          });
                        }}
                        sx={
                          state.newFile.frameFotografia
                            ? { display: "none" }
                            : {}
                        }
                      />
                    </div>
                    <CardFile
                      hidden={!state.newFile.frameFotografia}
                      onDrop={(event) => {
                        handleDrop(event, "fotografia");
                      }}
                      onDragOver={(event) => {
                        handleDragOver(event);
                        handleDragEnter(event, "file");
                      }}
                      onDragEnter={(event) => {
                        handleDragEnter(event, "file");
                      }}
                      onDragLeave={handleDragLeave}
                    >
                      <CardContent
                        onDrop={(event) => {
                          handleDrop(event, "fotografia");
                        }}
                        onDragOver={handleDragOver}
                        onDragEnter={(event) => {
                          handleDragEnter(event, "content");
                        }}
                      >
                        <Typography
                          sx={{ fontSize: 15, color: colorUI }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Desliza el documento
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ color: colorUI, fontWeight: "bold" }}
                        >
                          Constancia
                        </Typography>
                      </CardContent>
                    </CardFile>
                    <iframe
                      id="frameFotografia"
                      src={state.newFile.previewFotografia}
                      className="preview-iframe"
                      hidden={state.newFile.frameFotografia}
                    ></iframe>
                    <div
                      className="cta cta--guinda mt-3"
                      onClick={handleSubirArchivoFinal}
                    >
                      Enviar
                    </div>
                  </div>
                ) : null}

                <Box
                  sx={{ width: "100%", marginTop: "1%", marginBottom: "5%" }}
                >
                  <Grid
                    direction="row"
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    columns={{ xs: 1, sm: 2, md: 2 }}
                  >
                    {ConfiguradorData.data.map((item) => (
                      <Grid className="formDinamicoaire" item xs={1}>
                        {setComponent(item)}
                      </Grid>
                    ))}
                  </Grid>
                  {ConfiguradorData.documentos.length !== 0 && (
                    <>
                      <p>Documentos:</p>

                      <Grid
                        direction="row"
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                        columns={{ xs: 1, sm: 2, md: 2 }}
                      >
                        {ConfiguradorData.documentos.map((item) => (
                          <Grid className="formDinamicoaire" item xs={1}>
                            <Card sx={{ maxWidth: 345 }}>
                              <p>{item.label}</p>
                              <CardActionArea onClick={console.log}>
                                {item.extencion !== "pdf" &&
                                item.extencion !== "doc" &&
                                item.extencion !== "xmls" &&
                                item.extencion !== "docx" ? (
                                  <CardMedia
                                    component="img"
                                    height="150"
                                    src={item.value}
                                    alt={`Documento ${item.label}`}
                                  />
                                ) : item.extencion !== "pdf" ? (
                                  <a
                                    href={item.value}
                                    target="_blank"
                                    style={{
                                      backgroundColor: colorUI,
                                      color: "white",
                                      borderRadius: "12px",
                                      cursor: "pointer",
                                      padding: "5px 7px",
                                      marginBottom: "10px",
                                      marginLeft: "25%",
                                      marginTop: "6%",
                                    }}
                                  >
                                    <CloudDownloadIcon /> Descargar Documento
                                  </a>
                                ) : (
                                  <>
                                    <CardMedia
                                      component="iframe"
                                      height="150"
                                      src={item.value}
                                      alt={`Documento ${item.label}`}
                                    />
                                    <a
                                      href={item.value}
                                      target="_blank"
                                      style={{
                                        backgroundColor: colorUI,
                                        color: "white",
                                        borderRadius: "12px",
                                        cursor: "pointer",
                                        padding: "5px 7px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Abrir PDF
                                    </a>
                                  </>
                                )}
                              </CardActionArea>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
                </Box>

                {ConfiguradorData.Estatus === "Rechazada" && (
                  <p>
                    <strong>Observaciones:</strong> <br />
                    {ConfiguradorData.observaciones ?? "N/A"}
                  </p>
                )}
                {ValidStatus.includes(ConfiguradorData.Estatus) && (
                  <>
                    <TextField
                      rows={3}
                      multiline
                      label="Observaciones"
                      value={area}
                      onChange={(e) => setArea(e.target.value)}
                    />
                  </>
                )}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ marginRight: "auto" }}
              sx={{
                backgroundColor: "#6f1d46",
              }}
              onClick={handleCloseDinamicForm}
            >
              Cerrar
            </Button>
            {ValidStatus.includes(ConfiguradorData.Estatus) && (
              <>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleVincu(5)}
                >
                  Rechazar
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6f1d46",
                  }}
                  onClick={() => handleVincu(2)}
                >
                  Validar
                </Button>
              </>
            )}
            {ConfiguradorData?.Estatus ===
              "Pendiente de envio de resultado" && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubirArchivoFinal}
                >
                  Enviar Documento
                </Button>
              </>
            )}
          </DialogActions>
        </Box>
      </Modal>

      {cedula.open ? (
        <ModalCertificadoCedula
          data={cedula}
          open={cedula.open}
          close={() => handleCloseModalCedula()}
          closeandRefresh={() => handleFinish()}
        />
      ) : null}

      {cedula.openSolicitudCertificado ? (
        <ModalCertificadoCedula
          type="SolicitudCertificado"
          data={cedula}
          open={cedula.open}
          close={() => handleCloseModalCedula()}
          closeandRefresh={() => handleFinish()}
        />
      ) : null}

      {cedula.openE ? (
        <ModalCertificadoCedulaEstado
          data={cedula}
          open={cedula.openE}
          close={() => handleCloseModalCedula()}
          closeandRefresh={() => handleFinish()}
        />
      ) : null}

      {cedula.openP ? (
        <ModalCertificadoCedulaEstadoPago
          data={cedula}
          open={cedula.openP}
          close={() => handleCloseModalCedula()}
          closeandRefresh={() => handleFinish()}
        />
      ) : null}

      {cedula.openCedulaProvicional ? (
        <ModalCedulaProvisional
          type="SolicitudCertificadoProvisional"
          data={cedula}
          open={cedula.openCedulaProvicional}
          close={() => handleCloseModalCedula()}
          closeandRefresh={() => handleFinish()}
        />
      ) : null}
    </>
  );
};

export default SolicitudCertificadoView;
