import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { services } from "../services/api";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import swal from "sweetalert";
import GifLoader from "../components/ui/gifLoader";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import SelectBusqueda from "../components/SelectConBusqueda";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { CardContent, Card } from "@mui/material";

const colorUI = "#6f1d46";
// const colorUI = "#8d949e";

const ModalCertificadoCedula = ({
  type = "",
  data,
  open,
  close,
  closeandRefresh,
}) => {
  const [rechazado, setRechazado] = useState(true);
  const handleRechazado = () => {
    setRechazado(false);
    setBotones(true);
    setForm({ ...form, estatus: 5 });
  };
  const [aceptado, setAceptado] = useState(true);
  const handleAceptado = () => {
    setAceptado(false);
    setBotones(true);
    setForm({ ...form, estatus: 2 });
  };
  const [botones, setBotones] = useState(false);
  const aceptadoRef = useRef(null);
  const [documentos, setDocumentos] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [instituciones, setInstituciones] = useState([]);
  // const [profesiones, setProfesiones] = useState([]);

  const CardFile = styled(Card)(({ theme }) => ({
    height: "200px",
    width: "250px",
    margin: "auto",
    marginTop: "20px",
    borderRadius: "12px",
    boxShadow: "1px 0px 7px 0px rgba(0, 0, 0, 0.75)",
    "& .MuiCardContent-root": {
      // borderColor: '#6A0F49',
      borderColor: "#8d949e",
      borderStyle: "dotted",
      height: "80%",
      margin: "20px 20px 20px 20px",
    },
  }));

  const [Files, setFiles] = useState({
    DocumentoResultado: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
  });

  const obtenerCatalogos = async (e) => {
    // const dataProfesiones = await services("POST", "catalogos/profesiones", {})
    const dataInstitusiones = await services(
      "POST",
      "catalogos/instituciones",
      {}
    );
    // setProfesiones({
    //     ...profesiones,
    //     profesion: dataProfesiones.data[0]
    // })
    setInstituciones({
      ...instituciones,
      instituciones: dataInstitusiones.data,
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChangeFiles = (name, file) => {
    setFiles({
      ...Files,
      [name]: {
        ...Files[name],
        previewicono: URL.createObjectURL(file),
        frameicono: false,
        Archivo: file,
      },
    });
  };

  const handleDragOverFather = (event) => {
    event.preventDefault();
  };

  const handleChangeInstitucion = (e) => {
    setForm({
      ...form,
      selectOpcion: e,
      institucion_educativa: e.id,
      claveInstitucion: e.cct,
    });
    // console.log(form.claveInstitucion);
  };

  const handleDragEnterFather = (event, card) => {
    event.preventDefault();
    setIsDraggingOver(true);
    if (card == "file") {
      event.currentTarget.style.border = `2px dashed ${colorUI}`;
      event.currentTarget.style.filter = "blur(1px)";
    } else if (card == "content") {
    }
  };

  const handleDragLeaveFather = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    if (!isDraggingOver) {
      event.currentTarget.style.border = "none";
      event.currentTarget.style.filter = "none";
    }
  };

  const handleDropFather = (event, name) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const file = event.dataTransfer.files[0];

    handleChangeFiles(name, file);

    event.currentTarget.style.border = "";
    event.currentTarget.style.filter = "";
  };

  const [form, setForm] = useState({
    folio: "",
    estatus: "",
    clave_profesion: "",
    observaciones: "",
    estado: "",
    clave_Institucion: "", // este es para el TextField
    claveInstitucion: "", //este es para select de instituciones
    nombreInstitucion: "",
    selectOpcion: "",
    autoridad_expide: "",
  });
  const rechazadoRef = useRef(null);

  const [datos, setDatos] = useState({});
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "scroll",
    p: 4,
  };
  const [mostrarCamposInstitucion, setMostrarCamposInstitucion] =
    useState(false);

  const handleCheckboxChange = (event) => {
    setMostrarCamposInstitucion(event.target.checked);
    setForm({
      ...form,
      estado: "",
      clave_Institucion: "", //para el Text Field
      nombreInstitucion: "",
    });

    if (event.target.checked) {
      setForm({
        ...form,
        claveInstitucion: "",
      });
    }
  };

  const obtDatos = async () => {
    setForm({ ...form, folio: data.folio });
    setDatos(data);

    if (type === "SolicitudCertificado") {
      setDocumentos([
        { nombre: "Título", url: data.data.titulo_diploma_grado_archivo },
        { nombre: "Servicio Social", url: data.data.servicio_social_archivo },
        {
          nombre: "Certificado Estudios",
          url: data.data.certificado_estudios_archivo,
        },
        {
          nombre: "Certificado Anterior",
          url: data.data.certificado_estudios_anterior_archivo,
        },
        { nombre: "Cédula Anterior", url: data.data.cedula_anterior_archivo },
        {
          nombre: "Examen Profesional",
          url: data.data.acta_examen_profesional_archivo,
        },
        {
          nombre: "Acta de Nacimiento",
          url: data.data.solicitante.acta_nacimiento_archivo,
        },
        {
          nombre: "Identificación Oficial",
          url: data.data.solicitante.identificacion_archivo,
        },
        { nombre: "Firma", url: data.data.solicitante.firma_archivo },
        { nombre: "Fotografía", url: data.data.solicitante.fotografia_archivo },
        { nombre: "CURP", url: data.data.solicitante.curp_archivo },
      ]);
    }
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    backgroundColor: "#6f1d46",
    "&:hover": {
      backgroundColor: "#8d3461",
      // backgroundColor: "#8d949e",
    },
  }));

  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleValidada = async () => {
    setLoader(true);
    data = new FormData();

    data.append("folio", form.folio);
    // data.append("documento", Files["DocumentoResultado"].Archivo);
    data.append("estatus", 4);

    let dataDatos = null;

    if (type === "") {
      dataDatos = await services(
        "POST",
        "see/profesiones/validarRegistroCedula",
        data
      );
    } else {
      dataDatos = await services("POST", "see/profesiones/validarCedula", data);
    }

    if (dataDatos.status === 200) {
      const linkSource = `data:application/pdf;base64,${dataDatos.data.constancia}`;
      const downloadLink = document.createElement("a");
      const fileName = "Constancia de registro de cédula.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      swal({
        title: "Terminado",
        text: "Solicitud Actualizada con Éxito",
        icon: "success",
      });
      setLoader(false);
    } else {
      swal({
        title: "¡Error!",
        text: "No se subio",
        icon: "error",
      });
      setLoader(false);
    }
  };

  const handlePagado = async () => {
    setLoader(true);
    data = new FormData();

    data.append("folio", form.folio);
    data.append("documento", Files["DocumentoResultado"].Archivo);
    data.append("estatus", 4);

    const dataDatos = await services(
      "POST",
      "see/profesiones/validarCedula",
      data
    );

    if (dataDatos.status === 200) {
      swal({
        title: "Terminado",
        text: "Archivo Subido con Éxito",
        icon: "success",
      });
      setLoader(false);
    } else {
      swal({
        title: "¡Error!",
        text: "No se subio",
        icon: "error",
      });
      setLoader(false);
    }
  };

  const handleNombreInstitucion = (e) => {
    setForm({
      ...form,
      nombreInstitucion: e.target.value,
    });
  };
  const handleEstado = (e) => {
    setForm({
      ...form,
      estado: e.target.value,
    });
  };
  const handleClaveInstitucion = (e) => {
    setForm({
      ...form,
      clave_Institucion: e.target.value,
    });
  };

  const guardar = async () => {
    let dataDatos = null;

    if (!rechazado) {
      if (form.observaciones !== "") {
        const validacion = {
          folio: form.folio,
          estatus: form.estatus,
          observaciones: form.observaciones,
        };

        if (type === "") {
          dataDatos = await services(
            "POST",
            "see/profesiones/validarRegistroCedula",
            validacion
          );
        } else {
          dataDatos = await services(
            "POST",
            "see/profesiones/validarCedula",
            validacion
          );
        }

        if (dataDatos.status === 200) {
          swal({
            title: "¡Éxito!",
            text: "solicitud Actualizada",
            icon: "success",
            button: "Aceptar",
          }).then(() => {
            closeandRefresh();
          });
        } else {
          swal({
            title: "¡Error!r",
            text: dataDatos.error,
            icon: "error",
          });
        } // window.location.reload(false)
      } else {
        swal({
          title: "¡Error!",
          text: "El campo observaciones es requerido",
          icon: "warning",
        });
      }
    } else {
      const claveInstitucion = form.claveInstitucion
        ? form.claveInstitucion
        : form.clave_Institucion;

      const validacion = {
        folio: form.folio,
        estatus: form.estatus,
        clave_profesion: form.clave_profesion,
        clave_institucion: claveInstitucion,
        institucion: form.nombreInstitucion,
        entidad: form.estado,
        autoridad_expide: form.autoridad_expide,
      };

      const institucionText = form.nombreInstitucion !== "";
      const entidadText = form.estado !== "";
      const claveInstitucionSelect = form.claveInstitucion;
      const claveInstitucionText = form.clave_Institucion !== "";

      // if (claveInstitucionSelect) {

      if (type === "") {
        dataDatos = await services(
          "POST",
          "see/profesiones/validarRegistroCedula",
          validacion
        );
      } else {
        dataDatos = await services(
          "POST",
          "see/profesiones/validarCedula",
          validacion
        );
      }

      // } else if (institucionText && entidadText && claveInstitucionText) {

      //     dataDatos = await services("POST", "see/profesiones/validarRegistroCedula", validacion);
      // } else {
      //     dataDatos = await services("POST", "see/profesiones/validarCedula", validacion)

      // }
      if (dataDatos.status === 200) {
        // const linkSource = `data:application/pdf;base64,${dataDatos.data.constancia}`
        // const downloadLink = document.createElement("a")
        // const fileName = "Constancia de registro de cédula.pdf"

        // downloadLink.href = linkSource
        // downloadLink.download = fileName
        // downloadLink.click()

        swal({
          title: "¡Éxito!",
          text: "Solicitud actualizada, se generó el número de registro ",
          icon: "success",
          button: "Aceptar",
        }).then(() => {
          closeandRefresh();
        });
      } else {
        swal({
          title: "¡Error!",
          text: dataDatos.data,
          icon: "warning",
        });
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      obtenerCatalogos();
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      obtDatos();
    }
  }, []);

  useEffect(() => {
    if (aceptadoRef.current) {
      aceptadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [aceptado]);

  useEffect(() => {
    if (!rechazado && rechazadoRef.current) {
      rechazadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [rechazado]);

  return (
    <div>
      <Modal
        open={true}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Detalles de Cédula
            </Typography>
            <IconButton
              size="large"
              onClick={() => {
                close();
              }}
            >
              <CloseRoundedIcon fontSize="inherit"></CloseRoundedIcon>
            </IconButton>
          </Box>

          <Divider />

          <Grid container spacing={1}>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Estatus: </strong> {datos?.estatus}
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Fecha: </strong>{" "}
                {dayjs(datos?.fecha).format("YYYY-MM-D")}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Folio: </strong> {datos?.folio}
              </Typography>
            </Grid>

            <Grid className="my-4 d-flex justify-content-center" item md={12}>
              {data.estatus === "Validada" && data.data.pago ? (
                <a
                  href={data.data.pago}
                  target="_blank"
                  rel="noreferrer"
                  className="cta cta--guinda p-3 text-white"
                  style={{ width: "max-content", borderRadius: "10px" }}
                >
                  Generar formato de pago <InsertDriveFileIcon />
                </a>
              ) : data.estatus === "Terminada" && data.data.constancia ? (
                <a
                  href={data.data.constancia}
                  target="_blank"
                  rel="noreferrer"
                  className="cta cta--guinda p-3 text-white"
                  style={{ width: "max-content", borderRadius: "10px" }}
                >
                  Descargar documento <FileDownloadIcon />
                </a>
              ) : null}
            </Grid>

            {datos?.lcaptura ? (
              <Grid item md={12}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Línea de captura: </strong> {datos?.lcaptura}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          {type === "SolicitudCertificado" ? (
            <div className="container my-3">
              <div className="border-bottom--guinda text-big mb-2 text-bold">
                Datos Personales
              </div>
            </div>
          ) : null}

          <Grid container spacing={1}>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>CURP: </strong> {datos.data?.solicitante?.curp}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* <strong>Nombre:</strong> {datos.data?.solicitante?.nombre} {datos.data?.solicitante?.apellido_Pat} {datos.data?.solicitante?.apellido_Mat} */}
                <strong>Nombre:</strong>{" "}
                {datos.data?.solicitante?.nombres
                  ? datos.data?.solicitante?.nombres
                  : datos.data?.solicitante?.nombre}{" "}
                {datos.data?.solicitante?.apellido_Pat}{" "}
                {datos.data?.solicitante?.apellido_Mat}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Teléfono:</strong> {datos.data?.solicitante?.telefono}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Correo: </strong> {datos.data?.solicitante?.email}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Calle: </strong> {datos.data?.solicitante?.calle}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Número: </strong> {datos.data?.solicitante?.no_ext}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Entidad Federativa: </strong>{" "}
                {datos.data?.solicitante?.entidad_fede}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Municipio: </strong>{" "}
                {datos.data?.solicitante?.municipio}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Código Postal: </strong>{" "}
                {datos.data?.solicitante?.codigo_Post}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Colonia: </strong> {datos.data?.solicitante?.colonia}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Localidad: </strong>{" "}
                {datos.data?.solicitante?.localidad}
              </Typography>
            </Grid>
            {datos.data?.solicitante?.lugar_nacimiento ? (
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Lugar de Nacimiento: </strong>{" "}
                  {datos.data?.solicitante?.lugar_nacimiento}
                </Typography>
              </Grid>
            ) : null}
            {datos.data?.solicitante?.fecha_nacimiento ? (
              <Grid item md={4}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Fecha de Nacimiento: </strong>{" "}
                  {datos.data?.solicitante?.fecha_nacimiento}
                </Typography>
              </Grid>
            ) : null}

            {type === "" ? (
              <>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Número de cédula: </strong>{" "}
                    {datos.data?.numero_cedula}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Profesión: </strong> {datos.data?.profesion}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Fecha titulación: </strong>{" "}
                    {datos.data?.fecha_titulacion}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Fecha expedición de cédula: </strong>{" "}
                    {datos.data?.fecha_expedicion}
                  </Typography>
                </Grid>
              </>
            ) : null}
            {type === "SolicitudCertificado" ? (
              <>
                <div className="container my-3">
                  <div className="border-bottom--guinda text-big mb-2 text-bold">
                    Datos Académicos
                  </div>
                </div>

                {/* <Grid item md={6}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            <strong>Profesión: </strong> {datos.data?.profesion}
                                        </Typography>
                                    </Grid> */}

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Grado Solicitado: </strong> {datos.data?.grado}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Carrera/Posgrado: </strong> {datos.data?.profesion}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Duración: </strong>{" "}
                    {datos.data?.duracion_carrera_posgrado}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Institución Educativa: </strong>{" "}
                    {datos.data?.institucion}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Periodicidad: </strong>{" "}
                    {datos.data?.periodo === "Otro"
                      ? `${datos.data?.otro_periodo}`
                      : `${datos.data?.periodo}`}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Tipo Institución: </strong>{" "}
                    {datos.data?.tipo_insitucion === 1 ? "Privada" : "Publica"}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Servicio Social: </strong>{" "}
                    {datos.data?.servicio_social === 0 ||
                    datos.data?.servicio_social === "0"
                      ? "Si"
                      : datos.data?.servicio_social === 1 ||
                        datos.data?.servicio_social === "1"
                      ? "No"
                      : "No Aplica"}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Folio Certificado de Estudios: </strong>{" "}
                    {datos.data?.folio_certificado}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Fecha Ingreso: </strong> {datos.data?.fecha_ingreso}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Fecha Egreso: </strong> {datos.data?.fecha_egreso}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Fecha Examen Profesional: </strong>{" "}
                    {datos.data?.fecha_examen_profesional}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Fecha Expedición del Título: </strong>{" "}
                    {datos.data?.fecha_expedicion_titulo}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Modalidad: </strong>{" "}
                    {datos.data?.modalidad === "Otro"
                      ? datos.data?.otro_modalidad
                      : datos.data?.modalidad}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Tipo de Titulación: </strong>{" "}
                    {datos.data?.titulacion === "Otro"
                      ? datos.data?.otro_titulacion
                      : datos.data?.titulacion}
                  </Typography>
                </Grid>

                <div className="container my-3">
                  <div className="border-bottom--guinda text-big mb-2 text-bold">
                    Datos Antecedentes de Estudios
                  </div>
                </div>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Nivel Antecedente de Estudios: </strong>{" "}
                    {datos.data?.antecedente.grado}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Institución Educativa: </strong>{" "}
                    {datos.data?.antecedente.institucion_educativa}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Localidad de la Institución: </strong>{" "}
                    {datos.data?.antecedente.localidad_institucion}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Cédula Anterior: </strong>{" "}
                    {datos.data?.antecedente.cedula_anterior}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Fecha Ingreso: </strong>{" "}
                    {datos.data?.antecedente.fecha_ingreso}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Fecha Egreso: </strong>{" "}
                    {datos.data?.antecedente.fecha_egreso}
                  </Typography>
                </Grid>
              </>
            ) : null}

            <div className="container my-3">
              <div className="border-bottom--guinda text-big mb-2 text-bold">
                Archivos adjuntos
              </div>
            </div>

            {type === "" ? (
              <>
                <Grid item md={4}>
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>Identificación oficial:</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={datos.data?.solicitante?.identificacion_archivo}
                    className="preview-iframe"
                  ></iframe>
                  <a
                    target="_blank"
                    className="botonDescargaArchivoCedula"
                    href={datos.data?.solicitante?.identificacion_archivo}
                  >
                    Ver archivo
                  </a>
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>Título profesional:</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={datos.data?.titulo_archivo}
                    className="preview-iframe"
                  ></iframe>
                  <a
                    target="_blank"
                    className="botonDescargaArchivoCedula"
                    href={datos.data?.titulo_archivo}
                  >
                    Ver archivo
                  </a>
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>Cédula profesional:</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={datos.data?.cedula_archivo}
                    className="preview-iframe"
                  ></iframe>
                  <a
                    target="_blank"
                    className="botonDescargaArchivoCedula"
                    href={datos.data?.cedula_archivo}
                  >
                    Ver archivo
                  </a>
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>CURP:</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={datos.data?.solicitante.curp_archivo}
                    className="preview-iframe"
                  ></iframe>
                  <a
                    target="_blank"
                    className="botonDescargaArchivoCedula"
                    href={datos.data?.solicitante.curp_archivo}
                  >
                    Ver archivo
                  </a>
                </Grid>
              </>
            ) : (
              documentos.map((item) => (
                <Grid item md={4}>
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>{item.nombre}</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={item.url}
                    className="preview-iframe"
                  ></iframe>
                  <a
                    target="_blank"
                    className="botonDescargaArchivoCedula"
                    href={item.url}
                  >
                    Ver archivo
                  </a>
                </Grid>
              ))
            )}
          </Grid>

          {type === "SolicitudCertificado" && datos.estatus === "Pagado" ? (
            <>
              <div className="container my-3">
                <div className="border-bottom--guinda text-big mb-2 text-bold">
                  Subir Archivo
                </div>
              </div>

              <div className="SubirArchivo">
                <Grid item xs={12} sm={8} md={4}>
                  <Grid
                    container
                    spacing={1}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <CardFile
                      hidden={!Files["DocumentoResultado"].frameicono}
                      onDrop={(event) => {
                        handleDropFather(event, "DocumentoResultado");
                      }}
                      onDragOver={(event) => {
                        handleDragOverFather(event);
                        handleDragEnterFather(event, "file");
                      }}
                      onDragEnter={(event) => {
                        handleDragEnterFather(event, "file");
                      }}
                      onDragLeave={handleDragLeaveFather}
                    >
                      <CardContent
                        onDrop={(event) => {
                          handleDropFather(event, "DocumentoResultado");
                        }}
                        onDragOver={handleDragOver}
                        onDragEnter={(event) => {
                          handleDragEnterFather(event, "content");
                        }}
                      >
                        <Typography
                          sx={{ fontSize: 15, color: colorUI }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Desliza el Documento en formato PDF
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ color: colorUI, fontWeight: "bold" }}
                        >
                          Cédula de patente
                        </Typography>
                      </CardContent>
                    </CardFile>
                    <iframe
                      id="frameiconoFiles"
                      src={Files["DocumentoResultado"].previewicono}
                      className="preview-iframe"
                      hidden={Files["DocumentoResultado"].frameicono}
                    ></iframe>
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <label
                        htmlFor={`input${"DocumentoResultado"}`}
                        style={{
                          backgroundColor: colorUI,
                          color: "white",
                          borderRadius: "12px",
                          cursor: "pointer",
                          padding: "5px 7px",
                          marginBottom: "10px",
                        }}
                      >
                        cargar archivo
                      </label>
                      <input
                        type="file"
                        id={`input${"DocumentoResultado"}`}
                        style={{ display: "none" }}
                        accept=".pdf"
                        onChange={(e) =>
                          handleChangeFiles(
                            "DocumentoResultado",
                            e.target.files[0]
                          )
                        }
                      />
                      <DeleteForeverOutlinedIcon
                        style={{ color: "red", cursor: "pointer" }}
                        fontSize="large"
                        onClick={() => {
                          setFiles({
                            ...Files,
                            ["DocumentoResultado"]: {
                              previewicono: "",
                              frameicono: true,
                              Archivo: null,
                            },
                          });
                        }}
                        sx={
                          Files["DocumentoResultado"].frameicono
                            ? { display: "none" }
                            : {}
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : null}

          {datos.estatus === "Abierto" ||
          datos.estatus === "En cambio" ||
          datos.estatus === "En Proceso" ? (
            <Box
              id="botones"
              sx={{ mt: 2 }}
              display="flex"
              justifyContent="flex-end"
              hidden={botones}
            >
              <Button
                onClick={handleRechazado}
                variant="contained"
                color="error"
              >
                Rechazar
              </Button>
              <Button
                onClick={handleAceptado}
                sx={{ ml: 2, backgroundColor: "#6f1d46" }}
                variant="contained"
              >
                Aceptar
              </Button>
            </Box>
          ) : datos.estatus === "Pagado" ? (
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={() => closeandRefresh()}>
                Cerrar
              </ColorButton>
              <Button
                onClick={handlePagado}
                sx={{ ml: 2 }}
                variant="contained"
                color="success"
              >
                Enviar Cédula
              </Button>
            </Box>
          ) : datos.estatus === "Validada" ? (
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={() => closeandRefresh()}>
                Cerrar
              </ColorButton>
              <ColorButton sx={{ ml: 2 }} onClick={handleValidada}>
                Aceptar
              </ColorButton>
            </Box>
          ) : (
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={() => closeandRefresh()}>
                Cerrar
              </ColorButton>
            </Box>
          )}

          <Box
            id="rechazado"
            hidden={rechazado}
            ref={rechazadoRef}
            tabIndex={-1}
          >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                id="standard-multiline-static"
                name="observaciones"
                onChange={handleChange}
                value={form.observaciones}
                label="Observaciones"
                multiline
                rows={2}
                variant="standard"
              />
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={guardar}>Guardar y enviar</ColorButton>
            </Box>
          </Box>
          <Box id="aceptado" ref={aceptadoRef} sx={{ mt: 3 }} hidden={aceptado}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="clave_profesion"
                  value={form.clave_profesion}
                  onChange={handleChange}
                  label="Clave de Profesión"
                  variant="standard"
                />
              </Grid>
              {type !== "SolicitudCertificado" && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="autoridad_expide"
                    value={form.autoridad_expide}
                    onChange={handleChange}
                    label="Autoridad que expide la cédula"
                    variant="standard"
                  />
                </Grid>
              )}

              <Grid item md={7}>
                <SelectBusqueda
                  opciones={instituciones.instituciones}
                  funcion={handleChangeInstitucion}
                  selectOpcion={form.selectOpcion}
                />
              </Grid>
              <Grid className="mt-4 ms-5" item md={4}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox onChange={handleCheckboxChange} />}
                    label="Otra institución"
                  />
                </FormGroup>
              </Grid>

              {mostrarCamposInstitucion && (
                <>
                  <Grid item md={6}>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { width: "35ch" } }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="estado"
                        name="estado"
                        value={form.estado}
                        onChange={handleEstado}
                        label="Estado:"
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { width: "35ch" } }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="nombreInstitucion"
                        name="nombreInstitucion"
                        value={form.nombreInstitucion}
                        onChange={handleNombreInstitucion}
                        label="Nombre de Institución:"
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { width: "35ch" } }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="clave_Institucion"
                        name="clave_Institucion"
                        value={form.clave_Institucion}
                        onChange={handleClaveInstitucion}
                        label="Clave de la Institución:"
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={guardar}>Guardar y Enviar</ColorButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      {loader ? <GifLoader></GifLoader> : null}
    </div>
  );
};

export default ModalCertificadoCedula;
