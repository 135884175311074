import React, { useState, useEffect, useContext } from "react";
import OverLayIU from "./overlay_ui";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
// import MenuItem from '@mui/material/MenuItem';
import IconUI from "../ui/icon_ui";
import { GrClose } from "react-icons/gr";

import { services } from "../../services/api";

import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import InputAdornment from "@mui/material/InputAdornment";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import DotLoader from "../../components/ui/dotloader";
import { NotificacionContext } from "../../context/notificacion_context";
import CapturaContext from "../../context/captura_context/capturaContext";
import { useHistory } from "react-router-dom";
import useSharedState from "../../hooks/useActivatePinPad";
import GifLoader from "../../components/ui/gifLoader.js";
import swal from "sweetalert";

function createMarkup(text) {
  return { __html: text };
}

export default function PopUp({ html, onclose, tipo, bodyUpdate }) {
  // const colorUI ="#4A001F";
  const colorUI = "#8d949e";
  const { setDatosTabla } = useContext(CapturaContext);
  const { showNotification } = useContext(NotificacionContext);
  const {
    switchActivatePinpad,
    setSwitchActivatePinpad,
    activarUsuarioPinPad,
  } = useSharedState();

  const history = useHistory();

  const [state] = useState({
    type: tipo,
  });
  // console.log("yisus", bodyUpdate);
  const [gratuito] = useState(bodyUpdate?.gratuito ? true : false);

  const [validState, setValidState] = useState({
    claveDependencia: false,
    nombreDependencia: false,
  });

  const [oficinaState, setOficina] = useState({
    apioficinas: null,
    buscando: true,
  });

  const [stateUpdate, setStateUpdate] = useState({
    municipioEdit: "",
    oficinaEdit: "",
    rolEdit: "",
  });

  const [data, setdata] = useState({
    id: "",
    claveDependencia: "",
    nombreDependencia: "",
    pinpad: 0,
  });

  const [loader, setLoader] = useState(false);

  // console.log("DATA TRAIDA DEL CONSUMO DE LISTA", data.pinpad);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (bodyUpdate) {
        setdata({
          ...data,
          id: bodyUpdate.id,
          claveDependencia: bodyUpdate.claveDependencia,
          nombreDependencia: bodyUpdate.nombre,
          pinpad: bodyUpdate.pinpad,
        });
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const SortArray = (x, y) => {
    if (x.DESCRIPCION < y.DESCRIPCION) {
      return -1;
    }
    if (x.DESCRIPCION > y.DESCRIPCION) {
      return 1;
    }
    return 0;
  };

  const consumoApi = async () => {
    setdata({ ...data, loading: true });
    let municipios = await services("GET", `catalago/MUNICIPIO`, null);
    let oficinas = await services("GET", `catalago/OFICINA`, null);
    let roles = await services("GET", `usuarios/roles/list`, null);
    let dependencias = await services("GET", `dependencia/listar`, null);

    if (
      municipios.status === 200 &&
      oficinas.status === 200 &&
      roles.status === 200 &&
      dependencias.status === 200
    ) {
      let array_ordered = municipios.data.TB_RESULTADO;
      array_ordered = array_ordered.sort(SortArray);

      setLoader(false);
      //Guardando respuesta de apis en el state
      setdata({
        ...data,
        apimunicipios: array_ordered,
        apioficinas: oficinas.data.TB_RESULTADO,
        apiroles: roles,
        apidependencias: dependencias,
        loading: false,
      });

      if (state.type === "update") {
        //Completando objeto para edición de Municipio
        const getObjectoMunicipio = array_ordered.filter((item) => {
          return item.IDENTIFICADOR === bodyUpdate.id_municipio;
        });

        //Completando objeto para edición de Oficina
        const getObjectoOficina = oficinas.data.TB_RESULTADO.filter((item) => {
          return item.IDENTIFICADOR === bodyUpdate.id_oficina;
        });

        //Completando objeto para edición de Rol
        const getObjectoRol = roles.data.filter((item) => {
          return item.name === bodyUpdate.rol;
        });

        setStateUpdate({
          ...stateUpdate,
          municipioEdit: getObjectoMunicipio[0],
          oficinaEdit: getObjectoOficina[0],
          rolEdit: getObjectoRol[0],
        });
      }
    } else {
    }
  };

  const consumoApiOficina = async (oficina) => {
    let oficinas = await services("GET", `catalago/OFICINA/${oficina}`, null);
    let oficinasDataApi = [];
    if (oficinas.status === 200) {
      if (!Array.isArray(oficinas.data.TB_RESULTADO)) {
        oficinasDataApi = [oficinas.data.TB_RESULTADO];
      } else {
        oficinasDataApi = oficinas.data.TB_RESULTADO;
      }
    }
    setOficina({
      ...oficinaState,
      apioficinas: oficinasDataApi,
      buscando: false,
    });
  };

  const saveDependenciaApi = async (body) => {
    let guardarDependencia = await services("POST", `dependencia/crear`, body);

    if (guardarDependencia.status === 200) {
      if (!guardarDependencia.data.created) {
        showNotification(
          "warning",
          "Alerta!",
          guardarDependencia.data.error,
          null,
          4
        );
      } else {
        let dataDependenciasApi = await services(
          "GET",
          `dependencia/listar`,
          null
        );
        // console.log(dataDependenciasApi, "dataDependenciasApi")
        setDatosTabla(dataDependenciasApi.data);

        showNotification(
          "success",
          "Acción Exitosa!",
          "La dependencia se guardó correctamente",
          null,
          4
        );
        onclose();
      }
    } else {
    }
  };

  const updateDependenciaApi = async (bodyUpdate, bodyUpdateOld) => {
    let updateDependencia = await services(
      "PUT",
      `dependencia/editar/${bodyUpdate.id}`,
      bodyUpdate
    );

    if (updateDependencia.status === 200) {
      let dataDependenciasApi = await services(
        "GET",
        `dependencia/listar`,
        null
      );
      setDatosTabla(dataDependenciasApi.data);
      showNotification(
        "success",
        "Acción Exitosa!",
        "La dependencia se actualizó correctamente",
        null,
        4
      );
      setLoader(false);
      onclose();
    } else {
      showNotification(
        "options",
        "Alerta!",
        updateDependencia.data.mensage,
        null,
        4
      );
    }
  };

  const enviarDatosSap = () => {
    let stateValidationUserRegister = {
      validClaveDependencia: false,
      validNombreDependencia: false,
    };

    if (data.nombreDependencia === "" || data.claveDependencia === "") {
      if (data.nombreDependencia === "") {
        stateValidationUserRegister.validNombreDependencia = true;
      }
      if (data.claveDependencia === "") {
        stateValidationUserRegister.validClaveDependencia = true;
      }

      setValidState({
        ...validState,
        claveDependencia: stateValidationUserRegister.validClaveDependencia,
        nombreDependencia: stateValidationUserRegister.validNombreDependencia,
      });
      showNotification(
        "warning",
        "Campos requeridos!",
        "Por favor, llena todos los campos requeridos",
        null,
        4
      );
    } else {
      let body = {
        claveDependencia: data.claveDependencia,
        nombre: data.nombreDependencia,
        gratuito: gratuito ? 1 : 0,
        pinpad: data.pinpad,
      };
      saveDependenciaApi(body);
    }
  };

  const actualizarDatosSAp = () => {
    setLoader(true);
    let stateValidationUserRegister = {
      validClaveDependencia: false,
      validNombreDependencia: false,
    };

    if (data.nombreDependencia === "" || data.claveDependencia === "") {
      if (data.nombreDependencia === "") {
        stateValidationUserRegister.validNombreDependencia = true;
      }
      if (data.claveDependencia === "") {
        stateValidationUserRegister.validClaveDependencia = true;
      }

      setValidState({
        ...validState,
        claveDependencia: stateValidationUserRegister.validClaveDependencia,
        nombreDependencia: stateValidationUserRegister.validNombreDependencia,
      });

      showNotification(
        "warning",
        "Campos requeridos!",
        "Por favor, llena todos los campos requeridos",
        null,
        4
      );
    } else {
      let bodyUpdated = {
        id: data.id,
        nombre: data.nombreDependencia,
        claveDependencia: data.claveDependencia,
        gratuito: gratuito ? 1 : 0,
        pinpad: data.pinpad,
      };

      updateDependenciaApi(bodyUpdated, bodyUpdate);
    }
  };

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     setLoader(true);
  //     consumoApi();
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  // const NoOptionsMessage = props => {
  //     return (
  //         <components.NoOptionsMessage {...props}>
  //             <span className="custom-css-class">No Hay Resultados</span>
  //         </components.NoOptionsMessage>
  //     );
  // };

  return (
    <>
      {loader ? <GifLoader></GifLoader> : null}
      <OverLayIU
        onClick={() => {
          onclose();
        }}
      />
      <div className="wizard">
        {data.loading ? (
          <DotLoader />
        ) : (
          <>
            <div className="d-flex mb-3">
              <div className="col"></div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  onclose();
                }}
              >
                <IconUI size={15}>
                  <GrClose></GrClose>
                </IconUI>
              </div>
            </div>

            <div className="container my-3">
              {state.type === "update" ? (
                <div className="border-bottom--guinda text-big mb-2 text-bold">
                  {" "}
                  {`Edición de Dependencia ${data.nombreDependencia}`}
                </div>
              ) : (
                <div className="border-bottom--guinda text-big mb-2 text-bold">
                  {" "}
                  Registro de Dependencia
                </div>
              )}
            </div>

            {/* Registro Usuario */}
            {state.type === "form" ? (
              <form
                encType="multipart/form-data"
                method="post"
                name="formFisica"
              >
                <Grid container spacing={3}>
                  <Grid align="left" item xs={12} sm={12} md={6}>
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Clave de Dependencia *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={validState.claveDependencia}
                        onChange={(event) => {
                          setdata({
                            ...data,
                            claveDependencia: event.target.value,
                          });
                        }}
                        onKeyPress={(event) => {
                          // maxLengthLimitInput(event, 55, 'email');
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="claveDependencia"
                        name="claveDependencia"
                        aria-describedby="my-helper-text"
                        required
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa la clave de la dependencia.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid align="right" item xs={12} sm={12} md={6}>
                    <FormControl sx={{ marginTop: 3, width: "100%" }}>
                      <InputLabel
                        htmlFor="my-input"
                        sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                      >
                        Nombre de la Dependencia *
                      </InputLabel>
                      <Input
                        fullWidth
                        error={validState.nombreDependencia}
                        onChange={(event) => {
                          setdata({
                            ...data,
                            nombreDependencia: event.target.value,
                          });
                        }}
                        onKeyPress={(event) => {
                          // maxLengthLimitInput(event, 17, 'curp');
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase", color: colorUI },
                        }}
                        id="nombreDependencia"
                        name="nombreDependencia"
                        aria-describedby="my-helper-text"
                        required
                      />
                      <FormHelperText id="my-helper-text">
                        Ingresa el nombre de la dependencia.
                      </FormHelperText>
                    </FormControl>
                    <FormGroup>
                      <FormControlLabel
                        onChange={(e) => {
                          setdata({
                            ...data,
                            pinpad: e.target.checked ? 1 : 0,
                          });
                        }}
                        control={<Checkbox />}
                        label="Activar pago con PinPad"
                      />
                    </FormGroup>
                  </Grid>

                  {/* <Grid align="center" item xs={6} sm={6} md={6}>
                                   
                                    </Grid> */}
                </Grid>
                <div className="container">
                  <Grid sx={{ marginTop: 3 }} container spacing={2}>
                    <Grid align="center" item xs={12} sm={6} md={6}></Grid>
                    <Grid align="right" item xs={12} sm={6} md={6}>
                      <div
                        style={{ width: 200 }}
                        className="cta cta--guinda"
                        onClick={enviarDatosSap}
                      >
                        {" "}
                        Registrar
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </form>
            ) : (
              <div dangerouslySetInnerHTML={createMarkup(html)}></div>
            )}

            {/* Edición de información */}
            {state.type === "update" ? (
              <>
                <form
                  encType="multipart/form-data"
                  method="post"
                  name="formFisica"
                >
                  <Grid container spacing={3}>
                    <Grid align="left" item xs={12} sm={12} md={6}>
                      <FormControl sx={{ marginTop: 3, width: "100%" }}>
                        <InputLabel
                          htmlFor="my-input"
                          sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                        >
                          Clave de Dependencia *
                        </InputLabel>
                        <Input
                          fullWidth
                          error={validState.claveDependencia}
                          onChange={(event) => {
                            setdata({
                              ...data,
                              claveDependencia: event.target.value,
                            });
                          }}
                          inputProps={{
                            style: {
                              textTransform: "uppercase",
                              color: colorUI,
                            },
                          }}
                          id="claveDependencia"
                          name="claveDependencia"
                          aria-describedby="my-helper-text"
                          required
                          value={data.claveDependencia}
                        />
                        <FormHelperText id="my-helper-text">
                          Ingresa la clave de la dependencia.
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid align="right" item xs={12} sm={12} md={6}>
                      <FormControl sx={{ marginTop: 3, width: "100%" }}>
                        <InputLabel
                          htmlFor="my-input"
                          sx={{ "& .MuiInputLabel-root": { color: colorUI } }}
                        >
                          Nombre de la Dependencia *
                        </InputLabel>
                        <Input
                          fullWidth
                          value={data.nombreDependencia}
                          error={validState.nombreDependencia}
                          onChange={(event) => {
                            setdata({
                              ...data,
                              nombreDependencia: event.target.value,
                            });
                          }}
                          inputProps={{
                            style: {
                              textTransform: "uppercase",
                              color: colorUI,
                            },
                          }}
                          id="nombreDependencia"
                          name="nombreDependencia"
                          aria-describedby="my-helper-text"
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              {data.consultacurp === "success" ? (
                                <OfflinePinIcon color="success" />
                              ) : (
                                <></>
                              )}
                              {data.consultacurp === "error" ? (
                                <ReportGmailerrorredIcon color="error" />
                              ) : (
                                <></>
                              )}
                            </InputAdornment>
                          }
                        />
                        <FormHelperText id="my-helper-text">
                          Ingresa el nombre de la dependencia.
                        </FormHelperText>
                      </FormControl>
                      <FormGroup>
                        <FormControlLabel
                          checked={data.pinpad === 1}
                          onChange={(e) => {
                            setdata({
                              ...data,
                              pinpad: e.target.checked ? 1 : 0,
                            });
                          }}
                          // checked
                          control={<Checkbox />}
                          label={`${
                            data.pinpad === 1
                              ? "Pagos con pinpad activado"
                              : "Activar pago con PinPad"
                          }`}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid align="center" item xs={12} sm={12} md={12}>
                      <div
                        style={{
                          display: "grid",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* <div style={{ width: 400 , marginBottom:"10%" }} 
                                        onClick={
                                           () =>{ history.push(`/areas`)}
                                        }
                                        className="cta cta--guinda" 
                                            >  Asignar/Ver Categorías</div> */}

                        <div
                          style={{ width: 400 }}
                          onClick={() => {
                            history.push(`/areas/${data.nombreDependencia}`);
                          }}
                          className="cta cta--guinda"
                        >
                          {" "}
                          Administrar Áreas y Conceptos
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <div className="container">
                    <Grid sx={{ marginTop: 3 }} container spacing={2}>
                      <Grid align="center" item xs={12} sm={6} md={6}></Grid>
                      <Grid align="right" item xs={12} sm={6} md={6}>
                        {/* <div className="cta bg-success text-center text-white">
                          Activar pago con PinPad
                        </div> */}
                        <div
                          style={{ width: 200 }}
                          className="cta cta--guinda"
                          onClick={async () => {
                            await actualizarDatosSAp();
                            // await onclose();
                          }}
                        >
                          {" "}
                          Guardar
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </>
            ) : (
              <div dangerouslySetInnerHTML={createMarkup(html)}></div>
            )}
          </>
        )}
      </div>
    </>
  );
}
