import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { services } from "../services/api";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import swal from "sweetalert";
import SelectBusqueda from "../components/SelectConBusqueda";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ModalCertificadoCedula = ({ data, open, close, closeandRefresh }) => {
  const [rechazado, setRechazado] = useState(true);
  const handleRechazado = () => {
    setRechazado(false);
    setBotones(true);
    setForm({ ...form, estatus: 5 });
  };
  const [aceptado, setAceptado] = useState(true);
  const handleAceptado = () => {
    setAceptado(false);
    setBotones(true);
    setForm({ ...form, estatus: 2 });
  };
  const [botones, setBotones] = useState(false);
  const aceptadoRef = useRef(null);
  const [instituciones, setInstituciones] = useState([]);

  // const [fecha_exp, setFechaExp] = useState(dayjs(new Date()));

  const [form, setForm] = useState({
    selectOpcion: "",
    folio: "",
    estatus: "",
    clave_profesion: "",
    observaciones: "",
    estado: "",
    claveInstitucion: "", // para el select  de instituciones
    clave_Institucion: "", //para el Text Field
    nombreInstitucion: "",
  });
  const [mostrarCamposInstitucion, setMostrarCamposInstitucion] =
    useState(false);

  const handleCheckboxChange = (event) => {
    setMostrarCamposInstitucion(event.target.checked);
    setForm({
      ...form,
      estado: "",
      clave_Institucion: "", //para el Text Field
      nombreInstitucion: "",
    });

    if (event.target.checked) {
      setForm({
        ...form,
        claveInstitucion: "",
        selectOpcion: "",
      });
    }
  };

  const rechazadoRef = useRef(null);

  const obtenerCatalogos = async (e) => {
    // const dataProfesiones = await services("POST", "catalogos/profesiones", {})
    const dataInstitusiones = await services(
      "POST",
      "catalogos/instituciones",
      {}
    );
    // setProfesiones({
    //     ...profesiones,
    //     profesion: dataProfesiones.data[0]
    // })
    setInstituciones({
      ...instituciones,
      instituciones: dataInstitusiones.data,
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      obtenerCatalogos();
    }
  }, []);

  const [datos, setDatos] = useState({});
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "scroll",
    p: 4,
  };

  const obtDatos = async () => {
    setForm({ ...form, folio: data.folio });
    setDatos(data);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    backgroundColor: "#6f1d46",
    // backgroundColor: '#4a001f',
    "&:hover": {
      backgroundColor: '#8d3461"',
      // backgroundColor: '#4a001f',
    },
  }));
  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleChangeInstitucion = (e) => {
    setForm({
      ...form,
      selectOpcion: e,
      institucion_educativa: e.id,
      claveInstitucion: e.cct,
    });
  };

  const handleNombreInstitucion = (e) => {
    setForm({
      ...form,
      nombreInstitucion: e.target.value,
    });
  };
  const handleEstado = (e) => {
    setForm({
      ...form,
      estado: e.target.value,
    });
  };
  const handleClaveInstitucion = (e) => {
    setForm({
      ...form,
      clave_Institucion: e.target.value,
    });
  };

  // console.log("NORMAL", form.claveInstitucion);
  const guardar = async () => {
    // console.log("GUARDAR", form.claveInstitucion);
    if (form.observaciones !== "") {
      const validacion = {
        folio: form.folio,
        estatus: form.estatus,
        observaciones: form.observaciones,
      };
      const dataDatos = await services(
        "POST",
        "see/profesiones/validarRegistroTitulo",
        validacion
      );

      if (dataDatos.status === 200) {
        swal({
          title: "¡Éxito!",
          text: "Solicitud Actualizada",
          icon: "success",
          button: "Aceptar",
        }).then(() => {
          closeandRefresh();
        });
      } else {
        swal({
          title: "¡Error!",
          text: dataDatos.error,
          icon: "error",
        });
      } // window.location.reload(false)
    } else {
      const claveInstitucion = form.claveInstitucion
        ? form.claveInstitucion
        : form.clave_Institucion;

      const validacion = {
        folio: form.folio,
        estatus: form.estatus,
        clave_profesion: form.clave_profesion,
        clave_institucion: claveInstitucion,
        institucion: form.nombreInstitucion,
        entidad: form.estado,
      };

      const institucionText = form.nombreInstitucion !== "";
      const entidadText = form.estado !== "";
      const claveInstitucionSelect = !!form.claveInstitucion;
      const claveInstitucionText = form.clave_Institucion !== "";

      // console.log(form.estatus)

      if (
        claveInstitucionSelect ||
        (institucionText && entidadText && claveInstitucionText)
      ) {
        if (form.estatus === 2) {
          const dataDatos = await services(
            "POST",
            "see/profesiones/validarRegistroTitulo",
            validacion
          );

          // console.log(dataDatos);

          if (dataDatos.status === 200) {
            swal({
              title: "¡Éxito!",
              text: "Solicitud actualizada correctamente.",
              icon: "success",
              button: "Aceptar",
            }).then(() => {
              closeandRefresh();
            });
          } else if (dataDatos.status === 422) {
            swal({
              title: "Cuidado",
              text: dataDatos.data,
              icon: "warning",
            });
          } else {
            swal({
              title: "¡Error!",
              text: "Todos los campos son requeridos",
              icon: "warning",
            });
          }
        } else {
          swal({
            title: "Atención",
            text: "Todos los campos son requeridos",
            icon: "warning",
          });
        }
      } else {
        swal({
          title: "¡Error!",
          text: "Todos los campos son requeridos",
          icon: "warning",
        });
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      obtDatos();
    }
  }, []);

  useEffect(() => {
    if (aceptadoRef.current) {
      aceptadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [aceptado]);

  useEffect(() => {
    if (!rechazado && rechazadoRef.current) {
      rechazadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [rechazado]);

  return (
    <div>
      <Modal
        open={true}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Detalles de Título Profesional
            </Typography>
            <IconButton
              size="large"
              onClick={() => {
                close();
              }}
            >
              <CloseRoundedIcon fontSize="inherit"></CloseRoundedIcon>
            </IconButton>
          </Box>

          <Divider />
          <Grid container spacing={1}>
            <Grid className="my-4 d-flex justify-content-center" item md={12}>
              {data.estatus === "Validada" && data.data.pago ? (
                <a
                  href={data.data.pago}
                  target="_blank"
                  rel="noreferrer"
                  className="cta cta--guinda p-3 text-white"
                  style={{ width: "max-content", borderRadius: "10px" }}
                >
                  Generar formato de pago <InsertDriveFileIcon />
                </a>
              ) : data.estatus === "Terminada" && data.data.constancia ? (
                <a
                  href={data.data.constancia}
                  target="_blank"
                  rel="noreferrer"
                  className="cta cta--guinda p-3 text-white"
                  style={{ width: "max-content", borderRadius: "10px" }}
                >
                  Descargar documento <FileDownloadIcon />
                </a>
              ) : null}
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>CURP: </strong> {datos.data?.solicitante?.curp}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Nombre:</strong> {datos.data?.solicitante?.nombre}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Teléfono:</strong> {datos.data?.solicitante?.telefono}
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Correo: </strong> {datos.data?.solicitante?.email}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Calle: </strong> {datos.data?.solicitante?.calle}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Número: </strong> {datos.data?.solicitante?.no_ext}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Entidad Federativa: </strong>{" "}
                {datos.data?.solicitante?.entidad_fede}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Municipio: </strong>{" "}
                {datos.data?.solicitante?.municipio}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Código Postal: </strong>{" "}
                {datos.data?.solicitante?.codigo_Post}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Colonia: </strong> {datos.data?.solicitante?.colonia}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Localidad: </strong>{" "}
                {datos.data?.solicitante?.localidad}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Profesión: </strong> {datos.data?.profesion}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Institución: </strong> {datos.data?.institucion}
              </Typography>
            </Grid>
            {datos.data?.numero_cedula && (
              <Grid item md={6}>
                <Typography id="modal-modal-description" sx={{ mt: 4 }}>
                  <strong>Número de cédula: </strong>{" "}
                  {datos.data?.numero_cedula}
                </Typography>
              </Grid>
            )}

            <Grid item md={6}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Fecha titulación: </strong>{" "}
                {datos.data?.fecha_titulacion}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Fecha expedición de título: </strong>{" "}
                {datos.data?.fecha_expedicion}
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                <strong>Identificación oficial:</strong>
              </Typography>
              <iframe
                id="frameFotografia"
                src={datos.data?.solicitante?.identificacion_archivo}
                className="preview-iframe"
              ></iframe>
              <a
                target="_blank"
                className="botonDescargaArchivoCedula"
                href={datos.data?.solicitante?.identificacion_archivo}
              >
                Ver archivo
              </a>
            </Grid>
            <Grid item md={4}>
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                <strong>Título profesional:</strong>
              </Typography>
              <iframe
                id="frameFotografia"
                src={datos.data?.titulo_archivo}
                className="preview-iframe"
              ></iframe>
              <a
                target="_blank"
                className="botonDescargaArchivoCedula"
                href={datos.data?.titulo_archivo}
              >
                Ver archivo
              </a>
            </Grid>
            <Grid item md={4}>
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                <strong>Cédula profesional / Acta de titulación:</strong>
              </Typography>
              <iframe
                id="frameFotografia"
                src={datos.data?.cedula_archivo}
                className="preview-iframe"
              ></iframe>
              <a
                target="_blank"
                className="botonDescargaArchivoCedula"
                href={datos.data?.cedula_archivo}
              >
                Ver archivo
              </a>
            </Grid>
            <Grid item md={4}>
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                <strong>CURP:</strong>
              </Typography>
              <iframe
                id="frameFotografia"
                src={datos.data?.solicitante?.curp_archivo}
                className="preview-iframe"
              ></iframe>
              <a
                target="_blank"
                className="botonDescargaArchivoCedula"
                href={datos.data?.solicitante?.curp_archivo}
              >
                Ver archivo
              </a>
            </Grid>
          </Grid>
          {datos.estatus === "Abierto" ||
          datos.estatus === "En cambio" ||
          datos.estatus === "En Proceso" ? (
            <Box
              id="botones"
              sx={{ mt: 2 }}
              display="flex"
              justifyContent="flex-end"
              hidden={botones}
            >
              <Button
                onClick={handleRechazado}
                variant="contained"
                color="error"
              >
                Rechazar
              </Button>
              <Button
                onClick={handleAceptado}
                sx={{ ml: 2 }}
                variant="contained"
                style={{ backgroundColor: "#6f1d46" }}
              >
                Aceptar
              </Button>
            </Box>
          ) : (
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={() => closeandRefresh()}>
                Cerrar
              </ColorButton>
            </Box>
          )}

          <Box
            id="rechazado"
            hidden={rechazado}
            ref={rechazadoRef}
            tabIndex={-1}
          >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                id="standard-multiline-static"
                name="observaciones"
                onChange={handleChange}
                value={form.observaciones}
                label="Observaciones"
                multiline
                rows={2}
                variant="standard"
              />
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={guardar}>Guardar y enviar</ColorButton>
            </Box>
          </Box>
          <Box id="aceptado" ref={aceptadoRef} sx={{ mt: 3 }} hidden={aceptado}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="clave_profesion"
                  value={form.clave_profesion}
                  inputProps={{ maxLength: 15 }}
                  onChange={handleChange}
                  label="Clave de profesión"
                  variant="standard"
                />
              </Grid>

              <Grid item md={7} className="me-3 mt-4">
                {!mostrarCamposInstitucion ? (
                  <SelectBusqueda
                    opciones={instituciones.instituciones}
                    funcion={handleChangeInstitucion}
                    selectOpcion={form.selectOpcion}
                  />
                ) : null}
              </Grid>

              <Grid className="mt-5 ms-5" item md={4}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox onChange={handleCheckboxChange} />}
                    label="Otra institución"
                  />
                </FormGroup>
              </Grid>
              {mostrarCamposInstitucion && (
                <>
                  <Grid item md={6}>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { width: "35ch" } }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        required
                        id="estado"
                        name="estado"
                        value={form.estado}
                        onChange={handleEstado}
                        label="Estado:"
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { width: "35ch" } }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        required
                        id="nombreInstitución"
                        name="nombreInstitución"
                        value={form.nombreInstitucion}
                        onChange={handleNombreInstitucion}
                        label="Nombre de Institución:"
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { width: "35ch" } }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        required
                        id="claveInstitucion"
                        name="claveInstitucion"
                        value={form.clave_Institucion}
                        onChange={handleClaveInstitucion}
                        label="Clave de la Institución:"
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={guardar}>Guardar y Enviar</ColorButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalCertificadoCedula;
