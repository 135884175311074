import React, { useState, useEffect, useContext } from "react";
import { services } from "../services/api";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MUIDataTable from "mui-datatables";
import IconUI from "../components/ui/icon_ui";
import {
  AiOutlineLock,
  AiOutlineAudit,
  AiOutlineEye,
  AiOutlineBook,
} from "react-icons/ai";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import Select, { components } from "react-select";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import GifLoader from "../components/ui/gifLoader";

import { GrClose } from "react-icons/gr";
import { NotificacionContext } from "../context/notificacion_context";

const Turnadotramite = () => {
  const { showNotification } = useContext(NotificacionContext);
  const [loading, setLoading] = useState(false);

  const [state, setDatos] = useState({
    dataTramites: [],
    dataAreas: [],
    dataDependencias: [],
    apiestatus: [],
    observacion: "",
    selectedDep: [],
    selectedArea: [],
  });
  const [modal, setModal] = useState({
    detalles: false,
    turnar: false,
    historial: false,
  });
  const [historial, setHistorial] = useState([]);

  const [tramite, setTramite] = useState({});

  const [turnado, setTurnado] = useState({
    tramite_id: "",
    area_nueva: "",
    observaciones: "",
    area_actual: "",
  });
  const [dependencia, setDependencia] = useState();// eslint-disable-line no-unused-vars

  const [area, setArea] = useState();

  const [areas, setAreas] = useState();

  const options = {
    selectableRows: false,
    tableBodyHeight: "auto",
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "tablaUsuarios.csv",
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
  };
  const optionsH = {
    selectableRows: false,
    tableBodyHeight: "auto",
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "tablaHistorial.csv",
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "white",
    // height: "60%",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    height: "auto",
  };

  const styleH = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "auto",
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "15px",
    overflowY: "auto",
    maxHeight: "80vh",
    p: 4,
  };

  const styleR = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "auto",
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "15px",
    overflowY: "auto",
    maxHeight: "80vh",
    p: 4,
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let id = state.dataTramites[dataIndex].id;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {id}
            </div>
          );
        },
      },
    },
    {
      name: "FormularioTramite",
      label: "Nombre",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = state.dataTramites[dataIndex].FormularioTramite;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "TramiteFecha",
      label: "Fecha del Trámite",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = state.dataTramites[dataIndex].TramiteFecha;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "estatus_id",
      label: "Estatus",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let status = state.dataTramites[dataIndex].estatus_id;
          let estatus = state.apiestatus.find((stat) => stat.id === status);

          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {estatus.descripcion}
            </div>
          );
        },
      },
    },

    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro gap-2"
                  : "gap-2 w-100 d-flex justify-content-center align-items-center "
              }
            >
              <div
                style={{ height: 30, width: 30, zIndex: 0 }}
                className="cta cta--warning cta--icon icon_btn"
                onClick={() => {
                  verDetalles(state.dataTramites[dataIndex].id);
                  setModal({ ...modal, detalles: true });
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <AiOutlineEye />
                </IconUI>
                <div className="icon_btn_help">Ver Registro</div>
              </div>
              {localStorage.getItem("rol") !== "revisor" && (
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--secondary cta--icon icon_btn"
                  onClick={() => {
                    verHistorial(state.dataTramites[dataIndex].id);
                    setModal({ ...modal, historial: true });
                  }}
                >
                  <IconUI size={"20px"} color={"white"}>
                    <AiOutlineBook />
                  </IconUI>
                  <div className="icon_btn_help">Ver Historial</div>
                </div>
              )}
              <div
                style={{
                  height: 30,
                  width: 30,
                  zIndex: 0,
                  pointerEvents:
                    state.dataTramites[dataIndex].estatus_id === 4
                      ? "none"
                      : "auto",
                }}
                disabled={
                  state.dataTramites[dataIndex].estatus_id === 4 ? true : false
                }
                className="cta cta--primary cta--icon icon_btn"
                onClick={() => {
                  setModal({ ...modal, turnar: true });
                  setTurnado({
                    tramite_id: state.dataTramites[dataIndex].id,
                    area_actual: state.dataTramites[dataIndex].areas_id,
                  });
                  setDependencia(
                    state.dataTramites[dataIndex].dependenciaDeTramite.id
                  );
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <AiOutlineAudit />
                </IconUI>
                <div className="icon_btn_help">Turnar</div>
              </div>
              <div
                style={{
                  height: 30,
                  width: 30,
                  zIndex: 0,
                  pointerEvents:
                    state.dataTramites[dataIndex].estatus_id === 4
                      ? "none"
                      : "auto",
                }}
                disabled={
                  state.dataTramites[dataIndex].estatus_id === 4 ? true : false
                }
                className="cta cta--danger cta--icon icon_btn"
                onClick={() => {
                  showNotification(
                    "option",
                    "¿Estás seguro de realizar esta acción?",
                    "Al dar click en Aceptar se cerrará el trámite",
                    () => {
                      let body = {
                        tramite_id: state.dataTramites[dataIndex].id,
                        estatus_id: 4,
                      };
                      cerrarTramite(body);
                    },
                    null
                  );
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <AiOutlineLock />
                </IconUI>
                <div className="icon_btn_help">Cerrar</div>
              </div>
            </div>
          </>
        ),
      },
    },
  ];
  const columnsH = [
    {
      name: "area",
      label: "Área",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let area = historial[dataIndex].area;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {area}
            </div>
          );
        },
      },
    },
    {
      name: "usuario",
      label: "Usuario",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let usr = historial[dataIndex].usuario;

          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {usr}
            </div>
          );
        },
      },
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let fecha = historial[dataIndex].fecha;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {fecha}
            </div>
          );
        },
      },
    },
    {
      name: "comentarios",
      label: "Comentarios",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let coment = historial[dataIndex].comentarios;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {coment}
            </div>
          );
        },
      },
    },
  ];

  const verHistorial = async (id) => {
    setLoading(true);

    let detallesTramite = await services(
      "GET",
      `gratuitos/tramites/edit/historial_tramite/${id}`
    );
    if (detallesTramite.status === 200) {
      setHistorial(detallesTramite.data.data);
    } else {
      showNotification(
        "error",
        "Acción No Exitosa!",
        "La operación no se realizó correctamente",
        null,
        4
      );
    }
    setLoading(false);
  };
  const verDetalles = async (id) => {
    setLoading(true);

    let detallesTramite = await services(
      "GET",
      `gratuitos/tramites/edit/${id}`
    );
    if (detallesTramite.status === 200) {
      setTramite(detallesTramite.data);
    } else {
      showNotification(
        "error",
        "Acción No Exitosa!",
        "La operación no se realizó correctamente",
        null,
        4
      );
    }
    setLoading(false);
  };

  const cerrarTramite = async (body) => {
    setLoading(true);

    let cerrarTramite = await services(
      "POST",
      `gratuitos/tramites/edit/cambiar_estatus`,
      body
    );
    if (cerrarTramite.status === 200) {
      showNotification(
        "success",
        "Acción Exitosa!",
        "La operación se realizó correctamente",
        null,
        4
      );
      consumoApi();
    } else {
      showNotification(
        "error",
        "Acción No Exitosa!",
        "La operación no se realizó correctamente",
        null,
        4
      );
    }
    setLoading(false);
  };
  const consumoApi = async () => {
    setLoading(true);

    let dataApi = await services("GET", `gratuitos/tramites/index`, null);
    let dataEstatus = await services("GET", "solicitudes/estatus/list");
    let dependencias = await services("GET", `dependencia/listar`, null);
    // let areas = await services("FREEAPIGET", "areas/index", null);
    let areas = await services("GET", "gratuitos/areas/index", null);

    if (dataApi.status === 200) {
      if (localStorage.getItem("rol") === "Administrador") {
        setDatos({
          ...state,
          dataTramites: dataApi.data.data.tramites.reverse(),
          apiestatus: dataEstatus.data,
          dataDependencias: dependencias.data,
        });
        setAreas(areas.data.data);
      } else {
        setDatos({
          ...state,
          dataTramites: dataApi.data.data.tramites.reverse(),
          apiestatus: dataEstatus.data,
          dataDependencias: dependencias.data,
          dataAreas: areas.data.data,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      consumoApi();
    }

    return () => {
      isMounted = false;
    };
     // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setModal({
      detalles: false,
      turnar: false,
      historial: false,
    });
    setTramite({});
    setDatos({ ...state, observacion: "", selectedDep: [], selectedArea: [] });
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };
  const handleChangeDependencia = (item) => {
    setDependencia(item.id);
    if (localStorage.getItem("rol") === "Administrador") {
      const areasEncontrada = areas.filter(
        (area) => area.id_dependencia === item.id
      );
      // console.log(areasEncontrada);
      setDatos({
        ...state,
        selectedDep: item,
        dataAreas: areasEncontrada,
      });
    } else {
      setDatos({
        ...state,
        selectedDep: item,
      });
    }
  };
  const handleChangeArea = (item) => {
    setDatos({
      ...state,
      selectedArea: item,
    });
    setArea(item.id);
  };

  const turnarTramite = async () => {
    if (area !== "" && area !== undefined) {
      swal({
        title: "¿Estás Seguro de Turnar el Trámite?",
        text: `Se actualizarán los datos del trámite`,
        buttons: {
          cancel: "Cancelar",
          turnar: "Turnar",
        },
      }).then(async (result) => {
        if (result === "turnar") {
          setLoading(true);

          let turn = {
            tramite_id: turnado.tramite_id,
            area_actual: turnado.area_actual,
            area_nueva: area,
            observaciones: state.observacion,
          };
          let turnar = await services(
            "POST",
            `gratuitos/tramites/edit/turnar_tramite`,
            turn
          );
          if (turnar.status === 200) {
            swal("Ok", "Trámite Turnado Correctamente", "success");
            let dataApi = await services(
              "GET",
              `gratuitos/tramites/index`,
              null
            );
            setDatos({
              ...state,
              dataTramites: dataApi.data.data.tramites.reverse(),
            });
            handleClose();
          }
          setLoading(false);
        }
      });
    } else {
      swal("Advertencia", "Falta llenar campos", "warning");
    }
  };

  return (
    <>
      {loading && <GifLoader />}
      <div className="container">
        <div className="container my-3">
          <div className="border-bottom--guinda text-big mb-2 text-bold">
            Turnado de trámites
          </div>
        </div>

        <Box sx={{ flexGrow: 1 }}>
          <Grid className="container mt-5 mb-5">
            <Grid item xs={8}>
              <MUIDataTable
                style={{ Overflow: "hidden" }}
                title={"Listado de Trámites"}
                data={state.dataTramites}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </Box>
        <Modal
          open={modal.turnar}
          onClose={handleClose}
          aria-labelledby="modal-add-new-item"
          aria-describedby="modal-add-item"
        >
          <Box sx={style}>
            <div className="d-flex mb-3">
              <div className="col"></div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleClose();
                }}
              >
                <IconUI size={15}>
                  <GrClose></GrClose>
                </IconUI>
              </div>
            </div>
            <div className="container my-3">
              <div className="border-bottom--guinda text-big mb-2 text-bold">
                Turnar Trámite
              </div>
            </div>
            <Box sx={{ flexGrow: 1, marginTop: "3%" }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={12} key={0}>
                  <TextField
                    value={state.observacion}
                    onChange={(e) => {
                      setDatos({ ...state, observacion: e.target.value });
                    }}
                    id="observacion"
                    label="Observación"
                    variant="standard"
                    fullWidth
                  />
                </Grid>
                {localStorage.getItem("rol") === "Administrador" ? (
                  <Grid item xs={2} sm={4} md={12} key={0}>
                    <InputLabel
                      style={{ marginLeft: 0, marginTop: 35 }}
                      id="demo-simple-select-helper-label"
                    >
                      Dependencias *
                    </InputLabel>
                    <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
                      <Select
                        style={{ zIndex: 110 }}
                        options={state.dataDependencias}
                        placeholder="Seleccione Dependencia"
                        getOptionValue={(options) => options.claveDependencia}
                        getOptionLabel={(options) => options.nombre}
                        onChange={(options) => handleChangeDependencia(options)}
                        id="id_dependencia"
                        name="id_dependencia"
                        components={{ NoOptionsMessage }}
                        value={state.selectedDep}
                      />

                      <FormHelperText>
                        Selecciona una dependencia.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item xs={2} sm={4} md={12} key={0}>
                  <InputLabel
                    style={{ marginLeft: 0, marginTop: 35 }}
                    id="demo-simple-select-helper-label"
                  >
                    Área *
                  </InputLabel>
                  <FormControl sx={{ minWidth: "100%", maxWidth: "100%" }}>
                    <Select
                      style={{ zIndex: 110 }}
                      options={state.dataAreas}
                      placeholder="Seleccione un Área"
                      getOptionValue={(options) => options.id}
                      getOptionLabel={(options) => options.nombre}
                      onChange={(options) => handleChangeArea(options)}
                      id="id_areas"
                      name="id_areas"
                      components={{ NoOptionsMessage }}
                      value={state.selectedArea}
                    />
                    <FormHelperText>Selecciona un área.</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <div className="container">
              <Grid sx={{ marginTop: 3 }} container spacing={2}>
                <Grid
                  align="center"
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  style={{ marginTop: "20px" }}
                >
                  <div
                    style={{ width: 200 }}
                    className="cta cta--guinda"
                    onClick={() => {
                      turnarTramite();
                    }}
                  >
                    <div className="me-2"></div>Turnar
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Modal>

        <Modal
          open={modal.historial}
          onClose={handleClose}
          aria-labelledby="modal-add-new-item"
          aria-describedby="modal-add-item"
        >
          <Box sx={styleH}>
            <div className="d-flex mb-3">
              <div className="col"></div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleClose();
                }}
              >
                <IconUI size={15}>
                  <GrClose></GrClose>
                </IconUI>
              </div>
            </div>
            <div className="container my-3">
              <div className="border-bottom--guinda text-big mb-2 text-bold">
                Historial del Trámite
              </div>
            </div>
            <Box sx={{ flexGrow: 1, marginTop: "3%" }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={12} md={12} key={0}>
                  <MUIDataTable
                    style={{ Overflow: "hidden" }}
                    title={"Historial"}
                    data={historial}
                    columns={columnsH}
                    options={optionsH}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={modal.detalles}
          onClose={handleClose}
          aria-labelledby="modal-add-new-item"
          aria-describedby="modal-add-item"
        >
          <Box sx={styleR}>
            <div className="d-flex mb-3">
              <div className="col"></div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleClose();
                }}
              >
                <IconUI size={15}>
                  <GrClose></GrClose>
                </IconUI>
              </div>
            </div>
            <div className="container my-3">
              <div className="border-bottom--guinda text-big mb-2 text-bold">
                Registros del Trámite {tramite?.data?.plantillaTramite.nombre}
              </div>
            </div>
            <Box sx={{ flexGrow: 1, marginTop: "3%" }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {tramite?.data?.elementos.map((item, index) => {
                  // console.log(item, "item");
                  return (
                    <Grid item xs={4} sm={8} md={12} key={index}>
                      <FormLabel
                        component="legend"
                        style={{
                          borderBottom: "1px solid #ccc",
                          paddingBottom: "8px",
                        }}
                      >
                        <span style={{ fontWeight: "bolder" }}>
                          {item.label}:
                        </span>{" "}
                        {/* {item.options.length > 0 ? item.options.find((opt) => opt.id === item.valor)?.opcion: item.valor} */}
                        {item.options.length > 0
                          ? item.options.find((opt) => opt.id === item.valor)
                              ?.opcion ||
                            item.options.find((opt) => opt.opcion === item.valor)
                              ?.opcion ||
                            (item.valor === "1" ? "ACTIVO" : null) ||
                            (item.valor === "0" ? "INACTIVO" : null)
                          : item.valor}
                      </FormLabel>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Turnadotramite;
