import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import GifLoader from "../../components/ui/gifLoader";
import { Button, TextField } from "@mui/material";
import { services } from "../../services/api";

import dayjs from "dayjs";

const options = {
  selectableRows: false,
  tableBodyHeight: "auto",
  textLabels: {
    body: {
      noMatch: "Lo sentimos, no hay coincidencias en la búsqueda",
      toolTip: "Clasificar",
      columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
    },
    pagination: {
      next: "Siguiente",
      previous: "Anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tabla",
    },
    filter: {
      all: "Todo",
      title: "Filtros",
      reset: "Restaurar",
    },
    viewColumns: {
      title: "Ver columnas",
      titleAria: "Mostrar/Ocultar columnas en la tabla",
    },
    selectedRows: {
      text: "columna(s) selecciona(as)",
      delete: "Eliminar",
      deleteAria: "Eliminar filas seleccionadas",
    },
  },
  responsive: "scrollMaxHeight",
  downloadOptions: {
    filename: "tablaSolicitudes.csv",
    filterOptions: {
      useDisplayedColumnsOnly: false,
      useDisplayedRowsOnly: false,
    },
  },
};

const ReportesView = () => {
  const [state, setState] = useState({
    loading: false,

    data: [],
  });

  const columns = [
    {
      name: "folio",
      label: "Folio",
    },
    {
      name: "fecha_creacion",
      label: "Fecha Creación",
    },
    {
      name: "fechaValidacion",
      label: "Fecha Validación",
    },
    {
      name: "lcaptura",
      label: "Línea de captura",
      options: {
        customBodyRenderLite: (dataIndex, i) => {
          let descrip = state.data[dataIndex].lcaptura;
          return (
            <>
              <div
                style={{ height: "60px" }}
                className={
                  "w-100 d-flex justify-content-center align-items-center "
                }
              >
                <p> {descrip}</p>
              </div>
            </>
          );
        },
      },
    },
    {
      name: "importe",
      label: "Importe",
    },
    {
      name: "fechaPago",
      label: "Fecha Pago",
    },
    {
      name: "concepto",
      label: "Concepto",
    },
    {
      name: "descripcion",
      label: "Descripción",
      options: {
        customBodyRenderLite: (dataIndex, i) => {
          let descrip = state.data[dataIndex].descripcion.replace(
            "Solicitud de Registro De Cédula Provisional",
            "Solicitud de Registro De Cédula Provisional Con Acta de Titulación"
          );
          return (
            <>
              <div
                style={{ height: "60px" }}
                className={
                  "w-100 d-flex justify-content-center align-items-center "
                }
              >
                <p> {descrip}</p>
              </div>
            </>
          );
        },
      },
    },
    {
      name: "nombre",
      label: "Nombre",
    },
    {
      name: "profesion",
      label: "Profesión",
    },
    {
      name: "num_registro",
      label: "Num. Registro",
    },
    {
      name: "fecha_registro",
      label: "Fecha Registro",
    },
    {
      name: "usuarioValido",
      label: "Usuario Valido",
    },
    {
      name: "monto_total",
      label: "Monto Total",
    },
  ];
  let date = new Date();
  let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [filtros, setFiltros] = useState({
    fechaI: dayjs().format("YYYY-01-01"),
    fechaF: dayjs().format(`YYYY-MM-${ultimoDia.getDate()}`),
  });

  const handleFiltros = ({ target }) => {
    const { name, value } = target;
    setFiltros({ ...filtros, [name]: value });
  };

  let getApi = async () => {
    setState({ ...state, loading: true });

    let response = await services(
      "GET",
      `sep/profezionalizacion/reporte/${filtros.fechaI}/${filtros.fechaF}`,
      null
    );

    if (response.status === 200) {
      setState({ ...state, loading: false, data: response.data });
    } else {
      console.error("Error");
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getApi();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {state.loading ? <GifLoader></GifLoader> : null}
      <div>
        <div
          style={{ columnGap: 15, rowGap: 20 }}
          className="d-flex flex-wrap flex-row justify-content-center m-2"
        >
          <TextField
            InputLabelProps={{ shrink: true }}
            onChange={handleFiltros}
            value={filtros.fechaI}
            name="fechaI"
            label="Fecha Inicio"
            size="small"
            type="date"
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            onChange={handleFiltros}
            value={filtros.fechaF}
            name="fechaF"
            label="Fecha Fin"
            size="small"
            type="date"
          />
          <Button
            variant="contained"
            sx={{ backgroundColor: "#6f1d46" }}
            onClick={() => getApi()}
          >
            Filtrar
          </Button>
        </div>
        <div className="card m-3 text-center">
          <MUIDataTable
            style={{ Overflow: "hidden" }}
            title="Bandeja de Reportes"
            data={state.data}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </>
  );
};

export default ReportesView;
