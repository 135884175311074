import { useState, useEffect, useContext } from "react";
import { services } from "../services/api";
import { useHistory } from "react-router-dom";
import IconUI from "../components/ui/icon_ui";
import Container from "@mui/material/Container";
import { BsSearch } from "react-icons/bs";
import capturaContext from "../context/captura_context/capturaContext";
import GifLoader from "../components/ui/gifLoader";
import { toTop } from "../utils/animationFunctions";
import imagenError from "../assets/img/mensajeError.png";
import { FaIdCard, FaSchool } from "react-icons/fa";
import { TbBuildingWarehouse } from "react-icons/tb";
import {
  GiLifeInTheBalance,
  GiStabbedNote,
  GiArchiveRegister,
  GiPoliceOfficerHead,
} from "react-icons/gi";
import {
  MdNaturePeople,
  MdOutlineEmojiTransportation,
  MdDirectionsTransit,
  MdVerified,
} from "react-icons/md";
import { VscVerified } from "react-icons/vsc";
import { AiOutlineFileProtect } from "react-icons/ai";
import { IoMdCar } from "react-icons/io";
import { buscarSubCategorias } from "../utils/busqueda";
import { usePINPAD } from "../hooks/usePINPAD";

// import {GrServices} from 'react-icons/gr'

export default function HomeView() {
  // toTop()

  const { setTitle, setConcept } = useContext(capturaContext);
  // const colorCajas = "6A0F49";
  const colorCajas = "#6f1d46";
  const { usuario_pinpad, password_pinpad } = JSON.parse(
    localStorage.getItem("usuario")
  );
  const serial = localStorage.getItem("serial");
  const history = useHistory();
  const [loaderPinpad, setLoaderPinpad] = useState(false);
  const [state, setState] = useState({
    getCategorias: [],
    tempGetCategorias: [],
    getConceptos: [],
    loading: false,
    error: false,
    valueSearch: "",
    hover: false,
    itemHover: null,
  });
  const { urlPINPAD } = usePINPAD({ state, setState, setLoaderPinpad });
  const getCategorias = async () => {
    toTop();

    setState({ ...state, loading: true });
    let categorias = await services("GET", "categorias", null);
    let conceptos = await services("GET", `categorias/3`, null);
    // let response = await services("GET", "gratuitos/tramites/index", null);
    // console.log(response, "response");

    // if (response.status === 200) {
    //   setFormulario({ ...formulario, data: response.data.data, loading: false, })
    // }

    if (categorias.status === 200) {
      if (conceptos.status === 200) {
        setState({
          ...state,
          getConceptos: conceptos.data.TB_CATEGORIAS,
          getCategorias: categorias.data.TB_CATEGORIA,
          tempGetCategorias: categorias.data.TB_CATEGORIA,
          loading: false,
          error: false,
          textEmpty: true,
        });
      } else {
        setState({
          ...state,
          getCategorias: [],
          tempGetCategorias: [],
          loading: false,
          error: true,
        });
      }
    } else {
      setState({
        ...state,
        getCategorias: [],
        tempGetCategorias: [],
        loading: false,
        error: true,
      });
    }
  };

  const filterCategoria = (categoria, valueSearch) => {
    if (!valueSearch) {
      return categoria;
    }

    return reacomodo(buscarSubCategorias(categoria, valueSearch));
  };

  const reacomodo = (conceptoarray) => {
    const estructura = [];
    let id_categoria = "";
    conceptoarray.forEach((row, index) => {
      if (row.ID_CATEGORIA !== id_categoria) {
        estructura.push({
          ID_CATEGORIA: row.ID_CATEGORIA,
          DESC_CATEGORIA: row.DESC_CATEGORIA,
          TB_CONCEPTOS: conceptoarray.filter((item) => {
            return item.ID_CATEGORIA === row.ID_CATEGORIA
              ? item.TEXTO_CONCEPTO
              : null;
          }),
        });
        id_categoria = row.ID_CATEGORIA;
      }
    });

    return estructura;
     
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let token = localStorage.getItem("token");
      if (token) {
        getCategorias();
      } else {
        history.replace("/");
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const stylebusqueda = "d-flex flex-column py-3 height--header h-100 ";
  const stylebusquedaUno = "d-flex flex-column py-3 height--header ";

  return (
    <>
      {usuario_pinpad && password_pinpad && serial === "1" && (
        <>
          <iframe
            id="iframeTo"
            src={urlPINPAD}
            title="Pinpad"
            width="0"
            height="0"
            sx={{
              display: "none",
            }}
            allow="serial;geolocation;"
          ></iframe>
        </>
      )}
      <div
        className={
          state.tempGetCategorias.length !== 1 ? stylebusqueda : stylebusquedaUno
        }
      >
        {loaderPinpad || state.loading ? (
          <div className="vh-100">
            <GifLoader></GifLoader>
          </div>
        ) : (
          <>
            {state.error ? (
              <div>
                <Container>
                  <div
                    style={{ height: "800px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <img src={imagenError} alt="ImagenDeError"></img>
                  </div>
                </Container>
              </div>
            ) : (
              <div className="container my-3">
                <div className="border-bottom--guinda text-big mb-2 text-bold ">
                  Pago de Diversos
                </div>
                <div className="card my-2 animate__animated animate__fadeIn  animate__slow">
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      className="form-input--search"
                      placeholder="Buscador por Nombre"
                      onChange={(evt) => {
                        console.log(evt.target.value);

                        const textValue = evt.currentTarget.value
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "");
                        const filtered = filterCategoria(
                          state.getConceptos,
                          textValue
                        );

                        if (textValue === "") {
                          setState({
                            ...state,
                            tempGetCategorias: state.getCategorias,
                            textEmpty: true,
                          });
                        } else {
                          setState({
                            ...state,
                            tempGetCategorias: filtered,
                            textEmpty: false,
                          });
                        }
                      }}
                    />
                    <div className=" form-input--searchIcon">
                      <IconUI size={"25px"} color={colorCajas}>
                        <BsSearch />
                      </IconUI>
                    </div>
                  </div>
                </div>

                <div className="" style={{ height: "auto" }}>
                  {state.tempGetCategorias.length === 0 ? (
                    <div className="mt-5">
                      <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className=" d-flex justify-content-center align-items-center flex-column">
                          <div className="text-big text-bold text-guinda text-center">
                            {" "}
                            ¡ Lo sentimos !
                          </div>
                          <div className="text my-3">
                            No encontramos ningún registro.
                          </div>
                          <IconUI
                            size={"70px"}
                            // color={'#4A001F'}
                            color={"#8d949e"}
                          >
                            <BsSearch />
                          </IconUI>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className="row d-flex justify-content-between align-items-between  animate__animated animate__fadeInUp animate__slow min-height-mobile m-0"
                    style={{
                      fontSize: "12px",
                      textAlign: "justify",
                      height: "auto",
                    }}
                  >
                    {state.tempGetCategorias?.map((item, i) => {
                      return (
                        <>
                          <div
                            key={i}
                            onMouseEnter={() => {
                              setState({
                                ...state,
                                hover: true,
                                itemHover: item,
                              });
                            }}
                            onMouseLeave={() => {
                              setState({
                                ...state,
                                hover: false,
                                itemHover: item,
                              });
                            }}
                            className="cardAnimate d-flex flex-column col-sm-12 col-lg-2 text-center m-lg-1 mt-3 cursor-pointer"
                            onClick={() => {
                              toTop();
                              setTitle(item.DESC_CATEGORIA);
                              setConcept("");
                              history.push(`subcategoria/${item.ID_CATEGORIA}`);
                            }}
                          >
                            {state.hover && state.itemHover === item ? (
                              <>
                                <div className="boxleft"></div>
                                <div className="col"></div>
                                <div
                                  className={
                                    state.hover && state.itemHover === item
                                      ? "d-flex justify-content-center align-items-center animate__animated animate__fadeInDown"
                                      : "d-flex justify-content-center align-items-center"
                                  }
                                >
                                  {item.ID_CATEGORIA === "GR01" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE EXPEDICIÓN Y RENOVACIÓN DE LICENCIAS PARA CONDUCIR VEHÍCU" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <FaIdCard />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR02" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE PROTECCIÓN AMBIENTAL Y DESARROLLO TERRITORIAL" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <MdNaturePeople />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR03" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE LA FISCALÍA GENERAL DEL ESTADO" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <GiLifeInTheBalance />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR04" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS EN MATERIA DE EDUCACIÓN" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <FaSchool />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR05" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE LA DIRECCIÓN DEL NOTARIADO Y ARCHIVO GENERAL DE NOTARÍAS" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <GiStabbedNote />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR06" ||
                                  item.DESC_CATEGORIA ===
                                    "PRESTACIÓN DE SERVICIOS DE LA COORDINACIÓN DE PROTECCIÓN CIVIL DEL EST" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <AiOutlineFileProtect />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR07" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DEL REGISTRO CIVIL" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <GiArchiveRegister />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR08" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE SEGURIDAD PRIVADA" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <GiPoliceOfficerHead />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR09" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS Y TRÁMITES EN MATERIA DE TRÁNSITO Y MOVILIDAD" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <MdOutlineEmojiTransportation />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR10" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE TRANSPORTE PÚBLICO" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <MdDirectionsTransit />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR11" ||
                                  item.DESC_CATEGORIA ===
                                    "SUMINISTRO DE HOLOGRAMAS PARA VERIFICACIÓN VEHICULAR (APROVECHAMIENTOS" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <MdVerified />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR12" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS PARA LA PRESTACIÓN DE SERVICIOS INMOBILIARIOS" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <TbBuildingWarehouse />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR13" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE REGISTRO Y CONTROL VEHICULAR DE TRANSPORTE PARTICULAR" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <IoMdCar />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR14" ||
                                  item.DESC_CATEGORIA ===
                                    "SUMINISTRO DE HOLOGRAMAS PARA VERIFICACIÓN VEHICULAR (PRODUCTOS)" ? (
                                    <IconUI size={"30px"} color={"white"}>
                                      <VscVerified />
                                    </IconUI>
                                  ) : null}
                                </div>
                                <div
                                  className={
                                    state.hover && state.itemHover === item
                                      ? "animate__animated animate__fadeInDown "
                                      : ""
                                  }
                                >
                                  {item.DESC_CATEGORIA}
                                </div>

                                <div className="col"></div>
                                <div className="d-flex ">
                                  <div className="col"></div>
                                  <div className=" boxRight"></div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className=" boxleft"></div>
                                <div className="col"></div>
                                <div
                                  className={
                                    state.hover === false &&
                                    state.itemHover === item
                                      ? "animate__animated animate__fadeInUp "
                                      : " "
                                  }
                                >
                                  {item.DESC_CATEGORIA}
                                </div>
                                <div
                                  className={
                                    state.hover === false &&
                                    state.itemHover === item
                                      ? "d-flex justify-content-center align-items-center animate__animated animate__fadeInUp"
                                      : "d-flex justify-content-center align-items-center"
                                  }
                                >
                                  {item.ID_CATEGORIA === "GR01" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE EXPEDICIÓN Y RENOVACIÓN DE LICENCIAS PARA CONDUCIR VEHÍCU" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <FaIdCard />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR02" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE PROTECCIÓN AMBIENTAL Y DESARROLLO TERRITORIAL" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <MdNaturePeople />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR03" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE LA FISCALÍA GENERAL DEL ESTADO" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <GiLifeInTheBalance />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR04" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS EN MATERIA DE EDUCACIÓN" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <FaSchool />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR05" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE LA DIRECCIÓN DEL NOTARIADO Y ARCHIVO GENERAL DE NOTARÍAS" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <GiStabbedNote />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR06" ||
                                  item.DESC_CATEGORIA ===
                                    "PRESTACIÓN DE SERVICIOS DE LA COORDINACIÓN DE PROTECCIÓN CIVIL DEL EST" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <AiOutlineFileProtect />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR07" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DEL REGISTRO CIVIL" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <GiArchiveRegister />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR08" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE SEGURIDAD PRIVADA" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <GiPoliceOfficerHead />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR09" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS Y TRÁMITES EN MATERIA DE TRÁNSITO Y MOVILIDAD" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <MdOutlineEmojiTransportation />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR10" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE TRANSPORTE PÚBLICO" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <MdDirectionsTransit />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR11" ||
                                  item.DESC_CATEGORIA ===
                                    "SUMINISTRO DE HOLOGRAMAS PARA VERIFICACIÓN VEHICULAR (APROVECHAMIENTOS" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <MdVerified />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR12" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS PARA LA PRESTACIÓN DE SERVICIOS INMOBILIARIOS" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <TbBuildingWarehouse />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR13" ||
                                  item.DESC_CATEGORIA ===
                                    "SERVICIOS DE REGISTRO Y CONTROL VEHICULAR DE TRANSPORTE PARTICULAR" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <IoMdCar />
                                    </IconUI>
                                  ) : null}
                                  {item.ID_CATEGORIA === "GR14" ||
                                  item.DESC_CATEGORIA ===
                                    "SUMINISTRO DE HOLOGRAMAS PARA VERIFICACIÓN VEHICULAR (PRODUCTOS)" ? (
                                    <IconUI size={"30px"} color={colorCajas}>
                                      <VscVerified />
                                    </IconUI>
                                  ) : null}
                                </div>
                                <div className="col"></div>
                                <div className="d-flex ">
                                  <div className="col"></div>
                                  <div className=" boxRight"></div>
                                </div>
                              </>
                            )}
                          </div>
                          {state.textEmpty ? (
                            ""
                          ) : (
                            <div className="card-search scrollItems col-sm-12 col-lg-9 mt-1">
                              <li>
                                {item.TB_CONCEPTOS === undefined
                                  ? ""
                                  : item.TB_CONCEPTOS.map((row, index) => (
                                      <ol
                                        className="cta--subcategorias"
                                        onClick={() => {
                                          toTop();
                                          setTitle(item.DESC_CATEGORIA);
                                          setConcept(row.TEXTO_CONCEPTO);
                                          history.push(
                                            `subcategoria/${item.ID_CATEGORIA}`
                                          );
                                        }}
                                      >
                                        {row.TEXTO_CONCEPTO.toUpperCase()}
                                      </ol>
                                    ))}
                              </li>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
