import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { services } from "../services/api";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import swal from "sweetalert";

const ModalCertificadoCedula = ({ data, open, close, closeandRefresh }) => {
  const [rechazado, setRechazado] = useState(true);
  const handleRechazado = () => {
    setRechazado(false);
    setBotones(true);
    setForm({ estatus: 4 });
  };
  const [aceptado, setAceptado] = useState(true);
  const handleAceptado = () => {
    setAceptado(false);
    setBotones(true);
    setForm({ ...form, estatus: 2 });
  };
  const [botones, setBotones] = useState(false);
  const aceptadoRef = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [qrData, setQRData] = useState("");

  // const [fecha_exp, setFechaExp] = useState(dayjs(new Date()));

  const [form, setForm] = useState({
    folio: "",
    estatus: "",
    clave_profesion: "",
    observaciones: "",
  });
  const rechazadoRef = useRef(null);

  const [datos, setDatos] = useState({});
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "scroll",
    p: 4,
  };

  const obtDatos = async () => {
    setForm({ ...form, folio: data.folio });
    setDatos(data);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    backgroundColor: "#6f1d46",
    // backgroundColor: "#8d949e",
    "&:hover": {
      backgroundColor: "#a43b6f",
      // backgroundColor: "#8d949e",
    },
  }));

  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const guardar = async () => {
    const validacion = {
      folio: form.folio,
      estatus: 4,
    };
    const dataDatos = await services(
      "POST",
      "see/profesiones/validarRegistroTitulo",
      validacion
    );
    // console.log(dataDatos, "dataDatos");
    if (dataDatos.status === 200) {
      setQRData(dataDatos.data.qr);

      // setModalOpen(true);
      // swal({
      //     title: "¡Exito!",
      //     text: "Solicitud Actualizada",
      //     icon: "success",
      //     button: "Aceptar",
      // }).then(() => {

      //     // closeandRefresh()
      //     console.log(dataDatos.qr)

      // })
    } else {
      swal({
        title: "¡Error!",
        text: dataDatos.error,
        icon: "error",
      });
    }
  };
  const handlePrintQR = () => {
    downloadPDF(qrData, "QR", null, "pdf");
  };

  const downloadPDF = (documentLink, name, callback, extension) => {
    try {
      const linkSource = `data:application/${extension};base64,${documentLink}`;
      const downloadLink = document.createElement("a");
      const fileName = `${name}.${extension}`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      if (callback !== null) {
        callback();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      obtDatos();
    }
  }, []);

  useEffect(() => {
    if (aceptadoRef.current) {
      aceptadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [aceptado]);

  useEffect(() => {
    if (!rechazado && rechazadoRef.current) {
      rechazadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [rechazado]);

  return (
    <div>
      <Modal
        open={true}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Detalles de Título Profesional (PAGADO)
            </Typography>
            <IconButton
              size="large"
              onClick={() => {
                close();
              }}
            >
              <CloseRoundedIcon fontSize="inherit"></CloseRoundedIcon>
            </IconButton>
          </Box>

          <Divider />
          <Grid container spacing={1}>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>CURP: </strong> {datos.data?.solicitante?.curp}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Nombre:</strong> {datos.data?.solicitante?.nombre}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Teléfono:</strong> {datos.data?.solicitante?.telefono}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Correo: </strong> {datos.data?.solicitante?.email}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Calle: </strong> {datos.data?.solicitante?.calle}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Número: </strong> {datos.data?.solicitante?.no_ext}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Entidad Federativa: </strong>{" "}
                {datos.data?.solicitante?.entidad_fede}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Municipio: </strong>{" "}
                {datos.data?.solicitante?.municipio}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Código Postal: </strong>{" "}
                {datos.data?.solicitante?.codigo_Post}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Colonia: </strong> {datos.data?.solicitante?.colonia}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Localidad: </strong>{" "}
                {datos.data?.solicitante?.localidad}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Profesión: </strong> {datos.data?.profesion}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Número de cédula: </strong> {datos.data?.numero_cedula}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Institución: </strong> {datos.data?.institucion}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Fecha titulación: </strong>{" "}
                {datos.data?.fecha_titulacion}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Fecha expedición: </strong>{" "}
                {datos.data?.fecha_expedicion}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                <strong>Identificación oficial:</strong>
              </Typography>
              <iframe
                id="frameFotografia"
                src={datos.data?.solicitante?.identificacion_archivo}
                className="preview-iframe"
              ></iframe>
              <a
                target="_blank"
                className="botonDescargaArchivoCedula"
                href={datos.data?.solicitante?.identificacion_archivo}
              >
                Ver archivo
              </a>
            </Grid>
            <Grid item md={4}>
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                <strong>Título profesional:</strong>
              </Typography>
              <iframe
                id="frameFotografia"
                src={datos.data?.titulo_archivo}
                className="preview-iframe"
              ></iframe>
              <a
                target="_blank"
                className="botonDescargaArchivoCedula"
                href={datos.data?.titulo_archivo}
              >
                Ver archivo
              </a>
            </Grid>
            <Grid item md={4}>
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                <strong>Cédula profesional:</strong>
              </Typography>
              <iframe
                id="frameFotografia"
                src={datos.data?.cedula_archivo}
                className="preview-iframe"
              ></iframe>
              <a
                target="_blank"
                className="botonDescargaArchivoCedula"
                href={datos.data?.cedula_archivo}
              >
                Ver archivo
              </a>
            </Grid>
            <Grid item md={4}>
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                <strong>CURP:</strong>
              </Typography>
              <iframe
                id="frameFotografia"
                src={datos.data?.solicitante?.curp_archivo}
                className="preview-iframe"
              ></iframe>
              <a
                target="_blank"
                className="botonDescargaArchivoCedula"
                href={datos.data?.solicitante?.curp_archivo}
              >
                Ver archivo
              </a>
            </Grid>
          </Grid>
          {qrData ? (
            <div className="d-flex justify-content-center my-5">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Typography sx={{ mt: 2, textAlign: "center" }}>
                  <strong>QR:</strong>
                </Typography>
                <iframe
                  id="frameFotografia"
                  src={`data:application/pdf;base64,${qrData}`}
                  className="preview-iframe"
                ></iframe>

                <Box display="flex" justifyContent="flex-end" mt={1}>
                  <ColorButton onClick={handlePrintQR}>
                    Descargar QR
                  </ColorButton>
                </Box>
              </div>
            </div>
          ) : null}
          {datos.estatus === "Pagado" ? (
            <>
              {qrData ? null : (
                <Box
                  id="botones"
                  sx={{ mt: 2 }}
                  display="flex"
                  justifyContent="flex-end"
                  hidden={botones}
                >
                  <Button onClick={guardar} variant="contained" color="success">
                    Aceptar
                  </Button>
                </Box>
              )}
              <Box sx={{ mt: 2 }} display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => closeandRefresh()}
                  variant="contained"
                  color="success"
                >
                  Cerrar
                </Button>
              </Box>
            </>
          ) : (
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={() => closeandRefresh()}>
                Cerrar
              </ColorButton>
            </Box>
          )}
        </Box>
      </Modal>

      {/* <QRModal open={modalOpen} qrData={qrData} /> */}
    </div>
  );
};

export default ModalCertificadoCedula;
