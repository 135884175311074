import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { components } from "react-select";
import { useEffect, useState } from "react";
import { services } from "../services/api";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import swal from "sweetalert";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import EditIcon from "@mui/icons-material/Edit";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import GifLoader from "../components/ui/gifLoader";

const colorUI = "#6f1d46";
// const colorUI = "#8d949e";

const styleModalItem = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: 500,
  overflow: "hidden",
  overflowY: "scroll",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: "150px",
  maxHeight: "150px",
}));

const ItemInicio = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "15px",
  minHeight: "250px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005cbf"),
  backgroundColor: colorUI,
  "&:hover": {
    backgroundColor: colorUI,
  },
}));

const ItemOrdenProceso = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
  width: "100%",
  borderRadius: "30px",
  lineHeight: "60px",
}));

export const ConfiguraTramite = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [cantidadValidar, setCcantidad] = useState({
    min: false,
    size: false,
    mimes: false,
    valuemin: "",
    valuesize: "",
    valuemimes: "",
  });
  const [opciones, setOpciones] = useState([]);

  const [Configstate, setConfigstate] = useState({
    Formulario: {
      pagoLinea: 0,
      Firma: 1,
      categoria: "",
      claveIngreso: "",
      validacion: 1,
      observaciones: "",
      documento: 0,
      Formularios: [
        {
          nombre: "",
          campos: [],
        },
      ],
    },
  });

  const [ItemState, setItemState] = useState({
    name: "",
    id: Configstate.Formulario.Formularios[0].campos.length,
    validaciones: "",
    label: "",
    type: "",
    value: "",
    options: [],
  });

  const [openModalItem, setOpenModalItem] = useState({
    opcion: 0,
    open: false,
    type: "",
    size: false,
    min: false,
    edit: false,
    opcionEdit: "",
  });

  const [stateProceso, setStateProceso] = useState({
    validacionTramite: true,
    pagoLinea: false,
    entregaResultado: false,
  });

  const [state, setState] = useState({
    categoriaSelected: "",
    ConceptoSelected: "",
    categorias: [],
    conceptos: [],
    validarCategoria: true,
    conceptosSelect: [],
    categoriasInputs: [],
    validaciones: [],
    categoria: "",
    concepto: "",
    nombreForm: "",
    descripcionForm: "",
    Opcionuno: "",
    loading: true,
    mimesData: [],
  });

  // const handleOpenModalItem = () => setOpenModalItem({...openModalItem, open:true});
  const handleCloseModalItem = () => {
    setOpenModalItem({
      ...openModalItem,
      open: false,
      size: false,
      min: false,
    });

    limpiarOpciones();
    limpiarvalidaciones();
  };

  const handleCloseModalItemEdit = () => {
    setOpenModalItem({
      ...openModalItem,
      open: false,
      size: false,
      min: false,
      edit: false,
      opcionEdit: "",
    });

    setItemState({
      name: "",
      id: Configstate.Formulario.Formularios[0].campos.length,
      validaciones: "",
      label: "",
      type: "",
      value: "",
      options: [],
    });
    limpiarvalidaciones();
  };

  const ItemSeleccionado = (id, type, idValidacion) => {
    setOpenModalItem({ ...openModalItem, open: true, opcion: id, type: type });

    if (idValidacion != "") {
      GetValidaciones(idValidacion);
    }
  };

  const handleDeleteComponent = (id) => {
    setConfigstate({
      Formulario: {
        ...Configstate.Formulario,
        Formularios: [
          {
            nombre: Configstate.Formulario.Formularios[0].nombre,
            campos: Configstate.Formulario.Formularios[0].campos.filter(
              (item) => item.id != id
            ),
          },
        ],
      },
    });
  };

  const setComponent = (component) => {
    switch (component.type) {
      case "text":
      case "password":
      case "number":
      case "email":
      case "url":
      case "tel":
        return (
          <>
            <TextField
              id={component.id}
              sx={{ width: "350px" }}
              required={component.validaciones == "required" ? true : false}
              label={component.label}
              name={component.name}
              type={component.type}
              defaultValue={component.value}
            />

            <IconButton
              aria-label="delete"
              id={`eliminar${component.id}`}
              onClick={() => {
                handleDeleteComponent(component.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="editar"
              id={`editar${component.id}`}
              onClick={() => {
                handleEditComponent(component.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      case "textarea":
        return (
          <>
            <TextField
              id={component.id}
              sx={{ width: "350px" }}
              required={component.validaciones == "required" ? true : false}
              label={component.label}
              name={component.name}
              type={component.type}
              multiline
              maxRows={4}
              defaultValue={component.value}
            />

            <IconButton
              aria-label="delete"
              id={`eliminar${component.id}`}
              onClick={() => {
                handleDeleteComponent(component.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="editar"
              id={`editar${component.id}`}
              onClick={() => {
                handleEditComponent(component.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      case "file":
        return (
          <>
            <InputLabel className="mx-2  FormularioDinamicoRadios">
              {component.label}
            </InputLabel>
            <TextField
              id={component.id}
              fullWidth
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              type={component.type}
              error={component.error ? true : false}
              defaultValue={component.value}
              helperText={component.mensajeError ? component.mensajeError : ""}
            />

            <IconButton
              aria-label="delete"
              id={`eliminar${component.id}`}
              onClick={() => {
                handleDeleteComponent(component.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="editar"
              id={`editar${component.id}`}
              onClick={() => {
                handleEditComponent(component.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      case "date":
      case "datetime-local":
        return (
          <>
            <InputLabel className="mx-2">{component.label}</InputLabel>
            <TextField
              id={component.id}
              sx={{ width: "350px" }}
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              type={component.type}
              defaultValue={component.value}
              placeholder="DD/MM/YYYY"
            />
            <IconButton
              aria-label="delete"
              id={`eliminar${component.id}`}
              onClick={() => {
                handleDeleteComponent(component.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="editar"
              id={`editar${component.id}`}
              onClick={() => {
                handleEditComponent(component.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      case "select":
        return (
          <>
            <p style={{ color: "#6b1e49" }}>
              {component.label}
              <IconButton
                aria-label="delete"
                id={`eliminar${component.id}`}
                onClick={() => {
                  handleDeleteComponent(component.id);
                }}
              >
                <DeleteIcon />
              </IconButton>

              <IconButton
                aria-label="editar"
                id={`editar${component.id}`}
                onClick={() => {
                  handleEditComponent(component.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </p>
            <InputLabel className="mx-2"> </InputLabel>

            <Select
              required={component.validaciones == "required" ? true : false}
              name={component.name}
              id={component.id}
              options={component.options}
              placeholder="Seleccione una opción"
              getOptionValue={(options) => options.id}
              getOptionLabel={(options) => options.value}
            />
          </>
        );

      case "switch":
        return (
          <>
            <FormGroup>
              <FormControlLabel
                name={component.name}
                sx={{ marginLeft: "25%" }}
                id={component.id}
                required={component.validaciones == "required" ? true : false}
                control={<Switch />}
                label={component.label}
              />
              <IconButton
                aria-label="delete"
                id={`eliminar${component.id}`}
                onClick={() => {
                  handleDeleteComponent(component.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                aria-label="editar"
                id={`editar${component.id}`}
                onClick={() => {
                  handleEditComponent(component.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </FormGroup>
          </>
        );

      case "checkbox":
        return (
          <>
            <FormLabel component="legend">
              {component.label}{" "}
              <IconButton
                aria-label="delete"
                id={`eliminar${component.id}`}
                onClick={() => {
                  handleDeleteComponent(component.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                aria-label="editar"
                id={`editar${component.id}`}
                onClick={() => {
                  handleEditComponent(component.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </FormLabel>

            <FormGroup sx={{ marginLeft: "25%" }} name={component.name}>
              {component.options.map((item) => (
                <FormControlLabel
                  id={item.id}
                  value={item.value}
                  required={component.validaciones == "required" ? true : false}
                  control={<Checkbox />}
                  label={item.value}
                />
              ))}
            </FormGroup>
          </>
        );

      case "radio":
        return (
          <>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                {component.label}
                <IconButton
                  aria-label="delete"
                  id={`eliminar${component.id}`}
                  onClick={() => {
                    handleDeleteComponent(component.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  aria-label="editar"
                  id={`editar${component.id}`}
                  onClick={() => {
                    handleEditComponent(component.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                {component.options.map((item) => (
                  <FormControlLabel
                    value={item.value}
                    control={<Radio />}
                    label={item.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
    }
  };

  const handleEditComponent = (id) => {
    const itemEdit = Configstate.Formulario.Formularios[0].campos.filter(
      (item) => {
        return item.id === id;
      }
    );

    //  console.log(itemEdit)

    setItemState({
      name: itemEdit[0].label,
      id: itemEdit[0].id,
      validaciones: "",
      label: itemEdit[0].label,
      type: itemEdit[0].type,
      value: "",
      options: [],
    });

    if (itemEdit[0].options) {
      if (itemEdit[0].options.length > 0) {
        const primeraopc = itemEdit[0].options.shift();
        setState({ ...state, Opcionuno: primeraopc.id });
        setOpciones(itemEdit[0].options);
      }
    }

    setOpenModalItem({
      ...openModalItem,
      edit: true,
      opcionEdit: itemEdit[0].label,
    });
  };

  const ComponentesList = [
    {
      idValidacion: "",
      type: "",
      tipo: "INPUT",
      valor: "1",
      icon: (
        <p className="componentesmuestra">
          <TextField
            disabled
            sx={{ backgroundColor: "#fff" }}
            id="muestrainput"
            label="Nombre"
            defaultValue="Caja de entrada"
            variant="outlined"
          />
        </p>
      ),
    },
    {
      idValidacion: "11",
      type: "radio",
      tipo: "BOTÓN OPCIÓN",
      valor: "2",
      icon: (
        <p className="componentesmuestra">
          opción 1<RadioButtonCheckedIcon /> opción 2
          <RadioButtonUncheckedIcon />
        </p>
      ),
    },

    {
      idValidacion: "12",
      type: "select",
      tipo: "SELECCIÓN",
      valor: "3",
      icon: (
        <p className="componentesmuestra">
          <Select options={[]} placeholder="Seleccione una Categoría" />
        </p>
      ),
    },
    // {
    //     idValidacion:"14", type: "switch", tipo: "ACTIVADOR", valor: "4", icon: <p className='componentesmuestra'>
    //         <FormGroup>
    //             <FormControlLabel sx={{ marginLeft: "25%" }} control={<Switch defaultChecked />} label="Es valido" />
    //         </FormGroup>
    //     </p>
    // },
    {
      idValidacion: "10",
      type: "checkbox",
      tipo: "CHECKS",
      valor: "5",
      icon: (
        <p className="componentesmuestra">
          <FormGroup sx={{ marginLeft: "25%" }}>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Opción 1"
            />
            <FormControlLabel
              required
              control={<Checkbox />}
              label="Opción 2"
            />
          </FormGroup>
        </p>
      ),
    },
  ];

  const ColorButtonSecond = styled(Button)(({ theme }) => ({
    color: colorUI,
    backgroundColor: "#ffc3d0",
    border: "none",
    "&:hover": {
      transform: "scale(1.005)",
      boxShadow: "3px 3px 6px 0px rgba(0, 0, 0, 0.2)",
    },
  }));

  useEffect(async () => {
    let isMounted = true;

    if (isMounted) {
      let categoriasData = await services("GET", "categorias", null);
      let conceptosData = await services("GET", `categorias/3`, null);
      let TiposInput = await services("GET", `formularios/inputs`, null);
      // let validaciones = await services('GET', 'formularios/validaciones', null);

      setState({
        ...state,
        categorias: categoriasData.data.TB_CATEGORIA,
        conceptos: conceptosData.data.TB_CATEGORIAS,
        categoriasInputs: TiposInput.data.filter(
          (item) =>
            item.tipo != "switch" &&
            item.tipo != "select" &&
            item.tipo != "radio" &&
            item.tipo != "checkbox"
        ),
        loading: false,
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Hay Resultados</span>
      </components.NoOptionsMessage>
    );
  };

  const handleChangeCategoria = (id, item) => {
    // console.log(state.conceptos, "conceptos Data")
    const filterConcept = state.conceptos.filter((item) => {
      return item.ID_CATEGORIA === id;
    });
    // console.log(filterConcept, "filters")
    setState({
      ...state,
      categoriaSelected: item,
      validarCategoria: false,
      conceptosSelect: filterConcept[0].TB_CONCEPTOS,
      categoria: id,
    });
  };

  const handleAddItem = (type) => {
    if (ItemState.name !== "") {
      if (type != "") {
        ItemState.type = type;
      }
      if (state.Opcionuno != "") {
        ItemState.options.push({ id: state.Opcionuno, value: state.Opcionuno });
      }
      if (opciones.length > 0) {
        opciones.map((item) => {
          ItemState.options.push({ id: item.value, value: item.value });
        });
      }
      ItemState.name = ItemState.name.replace(" ", "-");

      if (cantidadValidar.min) {
        ItemState.validaciones = `${ItemState.validaciones}|min:${cantidadValidar.valuemin}`;
      }
      if (cantidadValidar.size) {
        ItemState.validaciones = `${ItemState.validaciones}|size:${cantidadValidar.valuesize}`;
      }
      if (cantidadValidar.mimes) {
        let documentos = "";
        cantidadValidar.valuemimes.map((item) => {
          documentos = `${documentos},${item.value}`;
        });
        ItemState.validaciones = `${ItemState.validaciones}|mimes:${documentos}`;
      }

      if (type === "switch") {
        ItemState.value = false;
      }

      Configstate.Formulario.Formularios[0].campos.push(ItemState);

      limpiar();
    } else {
      swal("¡Atención!", "El nombre es requerido", "warning");
    }
  };

  const handleUpdateItem = (type) => {
    if (type != "") {
      ItemState.type = type;
    }
    if (state.Opcionuno != "") {
      ItemState.options.push({ id: state.Opcionuno, value: state.Opcionuno });
    }
    if (opciones.length > 0) {
      opciones.map((item) => {
        ItemState.options.push({ id: item.value, value: item.value });
      });
    }
    ItemState.name = ItemState.name.replace(" ", "-");

    if (cantidadValidar.min) {
      ItemState.validaciones = `${ItemState.validaciones}|min:${cantidadValidar.valuemin}`;
    }
    if (cantidadValidar.size) {
      ItemState.validaciones = `${ItemState.validaciones}|size:${cantidadValidar.valuesize}`;
    }

    if (type === "switch") {
      ItemState.value = false;
    }

    // console.log("item a editar", ItemState);
    setConfigstate({
      ...Configstate,
      Formulario: {
        ...Configstate.Formulario,
        Formularios: [
          {
            ...Configstate.Formulario.Formularios[0],
            campos: Configstate.Formulario.Formularios[0].campos.map((item) => {
              if (item.id === ItemState.id) {
                return ItemState;
              } else {
                return item;
              }
            }),
          },
        ],
      },
    });

    limpiar();
    handleCloseModalItemEdit();
  };

  const limpiar = () => {
    setItemState({
      name: "",
      id: Configstate.Formulario.Formularios[0].campos.length,
      validaciones: "",
      label: "",
      type: "",
      value: "",
      options: [],
    });
    handleCloseModalItem();
    limpiarvalidaciones();
  };

  const limpiarvalidaciones = () => {
    setCcantidad({
      min: false,
      size: false,
      mimes: false,
      valuemin: "",
      valuesize: "",
      valuemimes: "",
    });
  };

  const setOldFormName = (nombre, descripcion, concepto, item) => {
    setState({
      ...state,
      nombreForm: nombre,
      descripcionForm: descripcion,
      concepto: concepto,
      ConceptoSelected: item,
    });
  };

  const handleChangeConcepto = async (id) => {
    let response = await services(
      "GET",
      `formularios/formulario/${state.categoria}/${id.TP_INGRESO}`,
      null
    );

    if (response.data.estatus != 404) {
      //\n ¿Qué le gustaría hacer?
      swal({
        title: "Formulario Encontrado",
        text: "Nuestro sistema detecto un formulario existente para este concepto.",
        icon: "warning",
        //"Crear formulario desde cero",
        buttons: ["Cancelar", "Editar formulario existente"],
        confirmButton: true,
        //  confirmButtonText:"Editar formulario existente",
        className: "alertFormularioEncontrado",
      }).then((Editar) => {
        if (Editar) {
          setOldFormName(
            response.data.formulario[0].nombre,
            response.data.descripcion,
            id.TP_INGRESO,
            id
          );
          setStateProceso({
            validacionTramite: true,
            pagoLinea: response.data.pagoLinea === 1 ? true : false,
            entregaResultado: response.data.documento === 1 ? true : false,
          });
          setConfigstate({
            Formulario: {
              pagoLinea: 1,
              Firma: 1,
              categoria: "",
              id: response.data.id,
              claveIngreso: "",
              validacion: 1,
              observaciones: response.data.descripcion,
              documento: 1,
              Formularios: [
                {
                  nombre: response.data.formulario[0].nombre,
                  campos: response.data.formulario[0].campos,
                },
              ],
            },
          });
        } else {
          // swal({
          //     title: "Seguro",
          //     text:"Al crear un nuevo formulario ",
          //     icon: "warning",
          //     buttons: ["Crear formulario desde cero", "Editar formulario existente"],
          //     confirmButton: true,
          //     className:"alertFormularioEncontrado"
          //   }).then((Aceptar) =>{
          //     if (Aceptar) {
          //         setOldFormName(state.nombreForm,state.descripcionForm,id.TP_INGRESO, id)
          //         setConfigstate({
          //             Formulario:
          //             {
          //                 "pagoLinea": 1,
          //                 "Firma": 1,
          //                 "id": response.data.id,
          //                 "categoria": "",
          //                 "claveIngreso": "",
          //                 "validacion": 1,
          //                 "observaciones": "",
          //                 "documento": 1,
          //                 "Formularios": [
          //                     {
          //                         "nombre": "",
          //                         "campos": []
          //                     }
          //                 ]
          //             }
          //         })
          //     }else{
          //     }
          //   })
        }
      });
    } else {
      setOldFormName(
        state.nombreForm,
        state.descripcionForm,
        id.TP_INGRESO,
        id
      );
    }
  };

  const agregar = () => {
    if (opciones.length < 10) {
      setOpciones((s) => {
        return [
          ...s,
          {
            id: opciones.length,
            type: "text",
            value: "",
          },
        ];
      });
    }
  };

  const limpiarOpciones = () => {
    setOpciones([]);
    handleClose();
  };

  const handleChangeDinamicInput = (e) => {
    e.preventDefault();

    const index = parseInt(e.target.id);
    setOpciones((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;

      return newArr;
    });
  };

  const handleDeleteInput = (e, item) => {
    e.preventDefault();

    const filterOpciones = opciones.filter((input) => {
      return input !== item;
    });

    setOpciones(filterOpciones);
  };

  // const handleDelete = () => {
  //     console.info('You clicked the delete icon.');
  //   };

  const validacionesInputSet = (item) => {
    let array = "";
    let verificar = [];
    let min = false;
    let size = false;
    let mimes = false;

    item.map((item) => {
      verificar.push(item.validacion);
      if (item.requiere_valor == 1) {
      } else {
        array = `${array}${item.validacion}|`;
      }
    });

    if (verificar.includes("min")) {
      min = true;
    }
    if (verificar.includes("size")) {
      size = true;
    }
    if (verificar.includes("mimes")) {
      mimes = true;
    }

    setCcantidad({ ...cantidadValidar, min: min, size: size, mimes: mimes });

    enviarNuevoComponent(array);
  };

  const enviarNuevoComponent = (array) => {
    setItemState({
      ...ItemState,
      validaciones: array.substring(0, array.length - 1),
    });
  };

  const validForm = () => {
    return (
      state.nombreForm != "" &&
      state.descripcionForm != "" &&
      state.categoria != "" &&
      state.concepto != ""
    );
  };

  const AlertPagoLinea = () => {
    if (stateProceso.pagoLinea) {
      swal({
        title: "Precaución",
        text: "Al seleccionar pago en línea el sistema agregara automaticamente los campos de RFC y Razón Social, \n Si usted agrego alguno de estos campos seleccione CANCELAR y eliminelos. \n Si no agrego estos campos o ya los elimino presioné CONTINUAR",
        icon: "warning",
        buttons: ["Cancelar", "Continuar"],
        confirmButton: true,
        className: "alertFormularioEncontrado",
      }).then((aceptar) => {
        if (aceptar) {
          SetToBackend();
        }
      });
    } else {
      SetToBackend();
    }
  };

  const SetToBackend = async () => {
    if (validForm()) {
      Configstate.Formulario.categoria = state.categoria;
      Configstate.Formulario.claveIngreso = state.concepto;
      Configstate.Formulario.Formularios[0].nombre = state.nombreForm;
      Configstate.Formulario.pagoLinea = stateProceso.pagoLinea ? 1 : 0;
      Configstate.Formulario.validacion = stateProceso.validacionTramite
        ? 1
        : 0;
      Configstate.Formulario.documento = stateProceso.entregaResultado ? 1 : 0;
      Configstate.Formulario.observaciones = state.descripcionForm;

      if (Configstate.Formulario.Formularios[0].campos.length < 1) {
        swal("¡Atención!", "Añade por lo menos un elemento", "warning");
      } else {
        const response = await services(
          "POST",
          "formularios/crear",
          Configstate.Formulario
        );

        if (response.status == 422) {
          swal("¡Atención!", response.error, "warning");
        }
        if (response.status == 200) {
          swal("Ok", "Formulario creado", "success");
          limpiarProceso();
          limpiarOpciones();
          limpiar();
          limpiarJson();
          limpiarAllState();
        }
      }
    } else {
      swal("¡Atención!", "LLena todos los campos", "warning");
    }
  };

  const limpiarJson = () => {
    setConfigstate({
      Formulario: {
        pagoLinea: 1,
        Firma: 1,
        observaciones: "",
        categoria: "",
        claveIngreso: "",
        validacion: 1,
        documento: 1,
        Formularios: [
          {
            nombre: "",
            campos: [],
          },
        ],
      },
    });
  };
  const limpiarProceso = () => {
    setStateProceso({
      validacionTramite: true,
      pagoLinea: false,
      entregaResultado: false,
      mimesData: [],
    });
  };

  const limpiarAllState = () => {
    setState({
      ...state,

      ConceptoSelected: "",
      categoriaSelected: "",
      validarCategoria: true,
      conceptosSelect: [],

      categoria: "",
      concepto: "",
      nombreForm: "",
      descripcionForm: "",
      Opcionuno: "",
      mimesData: [],
    });
  };

  const handleOpcioneComponent = (e) => {
    setState({ ...state, Opcionuno: e.target.value });
  };

  const handlesetCategoriaCampo = (options) => {
    setItemState({ ...ItemState, type: options.tipo });

    GetValidaciones(options.id);
  };

  const GetValidaciones = async (id) => {
    let validaciones = await services(
      "GET",
      `formularios/validaciones/${id}`,
      null
    );
    let mimesValues = [];

    validaciones.data.map((item) => {
      if (item.validacion === "mimes") {
        item.values.map((docs) => {
          mimesValues.push({ label: docs, value: docs });
        });
      }
    });

    setState({
      ...state,
      validaciones: validaciones.data,
      mimesData: mimesValues,
    });
  };

  return (
    <div className="container">
      {state.loading ? (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
          <GifLoader></GifLoader>
        </div>
      ) : (
        <></>
      )}

      <div className="text-big mt-4">Configura tu trámite</div>

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <ItemInicio>
              <p style={{ marginBottom: "10%", marginTop: "4%" }}>
                <TextField
                  fullWidth
                  id="inputNombre"
                  label="Nombre del Formulario"
                  placeholder="Nombre del Formulario"
                  variant="standard"
                  value={state.nombreForm}
                  onChange={(e) => {
                    setState({ ...state, nombreForm: e.target.value });
                  }}
                />
              </p>
              <p>
                <TextField
                  fullWidth
                  id="inputDescripcion"
                  label="Descripción"
                  placeholder="Descripción"
                  variant="standard"
                  value={state.descripcionForm}
                  onChange={(e) => {
                    setState({ ...state, descripcionForm: e.target.value });
                  }}
                />
              </p>
            </ItemInicio>
          </Grid>
          <Grid item xs={6}>
            <ItemInicio>
              <div className="mt-2">
                <InputLabel className="mx-2">Categorias</InputLabel>
                <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                  <Select
                    value={state.categoriaSelected}
                    options={state.categorias}
                    placeholder="Seleccione una Categoría"
                    getOptionValue={(options) => options.ID_CATEGORIA}
                    getOptionLabel={(options) => options.DESC_CATEGORIA}
                    onChange={(options) =>
                      handleChangeCategoria(options.ID_CATEGORIA, options)
                    }
                    components={{ NoOptionsMessage }}
                  />
                </FormControl>
              </div>

              <div className="mt-2">
                <InputLabel className="mx-2">Conceptos</InputLabel>
                <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                  <Select
                    value={state.ConceptoSelected}
                    options={state.conceptosSelect}
                    placeholder="Seleccione un Concepto"
                    getOptionValue={(options) => options.TP_INGRESO}
                    getOptionLabel={(options) => options.TEXTO_CONCEPTO}
                    isDisabled={state.validarCategoria}
                    onChange={(options) => handleChangeConcepto(options)}
                    components={{ NoOptionsMessage }}
                  />
                </FormControl>
              </div>
            </ItemInicio>
          </Grid>
        </Grid>
      </Box>

      <div className="mt-2">
        <ColorButtonSecond
          onClick={handleOpen}
          style={{
            marginTop: "2%",
            marginBottom: "2%",
            backgroundColor: "#6f1d46",
            color: "#fff",
          }}
          variant="outlined"
          endIcon={<AddBoxIcon />}
        >
          {" "}
          Añadir Elemento{" "}
        </ColorButtonSecond>
      </div>

      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Vista Previa
        </div>
      </div>

      <div className="mt-2">
        <Box sx={{ width: "100%" }}>
          <Grid
            direction="row"
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            columns={{ xs: 1, sm: 2, md: 2 }}
          >
            {Configstate.Formulario.Formularios[0].campos.map((item) => (
              <Grid item xs={1} direction="row">
                {setComponent(item)}
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>

      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Configuración del Proceso
        </div>
        <FormGroup>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                checked={stateProceso.validacionTramite}
                onChange={() => {
                  setStateProceso({
                    ...stateProceso,
                    validacionTramite: !stateProceso.validacionTramite,
                  });
                }}
              />
            }
            label="Validación del Trámite"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stateProceso.pagoLinea}
                onChange={() => {
                  setStateProceso({
                    ...stateProceso,
                    pagoLinea: !stateProceso.pagoLinea,
                  });
                }}
              />
            }
            label="Pago en Línea"
          />
          {/* <FormControlLabel  control={<Checkbox />} label="Análisis de Resultados" /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={stateProceso.entregaResultado}
                onChange={() => {
                  setStateProceso({
                    ...stateProceso,
                    entregaResultado: !stateProceso.entregaResultado,
                  });
                }}
              />
            }
            label="Entrega del Resultado del Trámite"
          />
        </FormGroup>

        <ColorButton
          sx={{
            marginTop: "3%",
            marginLeft: "75%",
            backgroundColor: "#6f1d46",
          }}
          onClick={AlertPagoLinea}
          variant="outlined"
        >
          {" "}
          Finalizar Configuracion{" "}
        </ColorButton>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Selecciona el tipo de campo que quieres para tu formulario...
          </Typography>
          <Box sx={{ flexGrow: 1, marginTop: "5%" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {ComponentesList.map((item, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Item
                    onClick={() => {
                      ItemSeleccionado(item.tipo, item.type, item.idValidacion);
                    }}
                  >
                    {item.tipo} {item.icon}{" "}
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>
          <ColorButton
            sx={{
              marginTop: "3%",
              marginLeft: "85%",
              backgroundColor: "#6f1d46",
            }}
            onClick={handleClose}
            variant="outlined"
          >
            {" "}
            Terminar{" "}
          </ColorButton>
        </Box>
      </Modal>

      <Modal open={openModalItem.edit} onClose={handleCloseModalItemEdit}>
        <Box sx={styleModalItem}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edita tu Componente {openModalItem.opcionEdit}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              sx={{ marginBottom: "2%" }}
              fullWidth
              id="Nombre_Campo"
              label="Nombre del Campo"
              defaultValue=""
              variant="outlined"
              value={ItemState.name}
              onChange={(e) =>
                setItemState({
                  ...ItemState,
                  label: e.target.value,
                  name: e.target.value,
                })
              }
            />

            {[
              "text",
              "password",
              "number",
              "email",
              "url",
              "tel",
              "file",
              "date",
              "datetime-local",
            ].includes(ItemState.type) ? (
              <div className="mt-2">
                <InputLabel className="mx-2">Categoria del campo</InputLabel>
                <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                  <Select
                    options={state.categoriasInputs}
                    placeholder="Seleccione una Categoria"
                    getOptionValue={(options) => options.id}
                    getOptionLabel={(options) => options.tipo}
                    onChange={handlesetCategoriaCampo}
                    components={{ NoOptionsMessage }}
                  />
                </FormControl>
              </div>
            ) : (
              <></>
            )}
            {ItemState.opcion === "select" ||
            openModalItem.opcion === "check" ||
            openModalItem.opcion === "radio" ? (
              <>
                <InputLabel id="demo-simple-select-helper-label">
                  Opción 1:
                </InputLabel>
                <Input
                  fullWidth
                  helperText="Ingresa la opción"
                  id="inputOpcion1"
                  type="text"
                  value={state.Opcionuno ? state.Opcionuno : ""}
                  onChange={handleOpcioneComponent}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="add folio"
                        onClick={() => {
                          agregar();
                        }}
                        sx={{ color: colorUI, backgroundColor: "#ffc3d0" }}
                      >
                        <AddToPhotosIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {opciones.length > 0 ? (
                  opciones.map((item, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          // width: 370,
                          maxWidth: "100%",
                          // display: 'flex',
                          // alignItems: 'flex-end'
                        }}
                      >
                        <InputLabel
                          sx={{ marginTop: "3%" }}
                          id={`labelFolio-${i + 1}`}
                        >
                          Opción {i + 2}:
                        </InputLabel>
                        <Input
                          // sx={{ width: 260 }}
                          fullWidth
                          id={i.toString()}
                          type={item.type}
                          value={item.value ? item.value : ""}
                          onChange={handleChangeDinamicInput}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="delete input"
                                onClick={(e) => handleDeleteInput(e, item)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Box>
                    );
                  })
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <div className="mt-2">
              <InputLabel sx={{ marginTop: "10%" }} className="mx-2">
                Validaciones para el campo
              </InputLabel>
              <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                <Select
                  // closeMenuOnSelect={false}
                  isMulti
                  options={state.validaciones}
                  placeholder="Seleccione una Categoria"
                  getOptionValue={(options) => options.validacion}
                  getOptionLabel={(options) => `${options.descripcion}`}
                  onChange={(options) => validacionesInputSet(options)}
                  components={{ NoOptionsMessage }}
                />
              </FormControl>
            </div>

            {cantidadValidar.min ? (
              <div className="mt-2">
                <TextField
                  sx={{ marginBottom: "2%" }}
                  fullWidth
                  id="min"
                  label="Ingrese el mínimo de caracteres permitidos"
                  defaultValue=""
                  variant="outlined"
                  // value={ItemState.name}
                  onChange={(e) =>
                    setCcantidad({
                      ...cantidadValidar,
                      valuemin: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <></>
            )}
            {cantidadValidar.size ? (
              <div className="mt-2">
                <TextField
                  sx={{ marginBottom: "2%" }}
                  fullWidth
                  id="size"
                  label="Ingrese el máximo de letras permitidas"
                  defaultValue=""
                  variant="outlined"
                  // value={ItemState.name}
                  onChange={(e) =>
                    setCcantidad({
                      ...cantidadValidar,
                      valuesize: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <></>
            )}
            {cantidadValidar.mimes ? (
              <div className="mt-2">
                <InputLabel sx={{ marginTop: "10%" }} className="mx-2">
                  Tipos de Archivos
                </InputLabel>
                <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                  <Select
                    // closeMenuOnSelect={false}
                    isMulti
                    options={state.mimesData}
                    placeholder="Seleccione los tipos de archivos"
                    getOptionValue={(options) => options.value}
                    getOptionLabel={(options) => `${options.label}`}
                    onChange={(options) =>
                      setCcantidad({ ...cantidadValidar, valuemimes: options })
                    }
                    components={{ NoOptionsMessage }}
                  />
                </FormControl>
              </div>
            ) : (
              <></>
            )}
          </Typography>
          <ColorButton
            sx={{ marginTop: "3%", marginLeft: "75%" }}
            onClick={() => handleUpdateItem(openModalItem.type)}
            variant="outlined"
          >
            {" "}
            Guardar{" "}
          </ColorButton>
        </Box>
      </Modal>

      <Modal
        open={openModalItem.open}
        onClose={handleCloseModalItem}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalItem}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Configura tu Componente {openModalItem.opcion}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              sx={{ marginBottom: "2%" }}
              fullWidth
              id="Nombre_Campo"
              label="Nombre del Campo"
              defaultValue=""
              variant="outlined"
              value={ItemState.name}
              onChange={(e) =>
                setItemState({
                  ...ItemState,
                  label: e.target.value,
                  name: e.target.value,
                })
              }
            />

            {openModalItem.opcion === "INPUT" ? (
              <div className="mt-2">
                <InputLabel className="mx-2">Categoria del campo</InputLabel>
                <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                  <Select
                    options={state.categoriasInputs}
                    placeholder="Seleccione una Categoria"
                    getOptionValue={(options) => options.id}
                    getOptionLabel={(options) => options.tipo}
                    onChange={handlesetCategoriaCampo}
                    components={{ NoOptionsMessage }}
                  />
                </FormControl>
              </div>
            ) : (
              <></>
            )}
            {openModalItem.opcion === "SELECCIÓN" ||
            openModalItem.opcion === "CHECKS" ||
            openModalItem.opcion === "BOTÓN OPCIÓN" ? (
              <>
                <InputLabel id="demo-simple-select-helper-label">
                  Opción 1:
                </InputLabel>
                <Input
                  fullWidth
                  helperText="Ingresa la opción"
                  id="inputOpcion1"
                  type="text"
                  // value={folioCaptura.folio ? folioCaptura.folio : ''}
                  onChange={handleOpcioneComponent}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="add folio"
                        onClick={() => {
                          agregar();
                        }}
                        sx={{ color: "white", backgroundColor: colorUI }}
                      >
                        <AddToPhotosIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {opciones.length > 0 ? (
                  opciones.map((item, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          // width: 370,
                          maxWidth: "100%",
                          // display: 'flex',
                          // alignItems: 'flex-end'
                        }}
                      >
                        <InputLabel
                          sx={{ marginTop: "3%" }}
                          id={`labelFolio-${i + 1}`}
                        >
                          Opción {i + 2}:
                        </InputLabel>
                        <Input
                          // sx={{ width: 260 }}
                          fullWidth
                          id={i.toString()}
                          type={item.type}
                          value={item.value ? item.value : ""}
                          onChange={handleChangeDinamicInput}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="delete input"
                                onClick={(e) => handleDeleteInput(e, item)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Box>
                    );
                  })
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <div className="mt-2">
              <InputLabel sx={{ marginTop: "10%" }} className="mx-2">
                Validaciones para el campo
              </InputLabel>
              <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                <Select
                  // closeMenuOnSelect={false}
                  isMulti
                  options={state.validaciones}
                  placeholder="Seleccione una Categoria"
                  getOptionValue={(options) => options.validacion}
                  getOptionLabel={(options) => `${options.descripcion}`}
                  onChange={(options) => validacionesInputSet(options)}
                  components={{ NoOptionsMessage }}
                />
              </FormControl>
            </div>

            {cantidadValidar.min ? (
              <div className="mt-2">
                <TextField
                  sx={{ marginBottom: "2%" }}
                  fullWidth
                  id="min"
                  label="Ingrese el minimo requerido"
                  defaultValue=""
                  variant="outlined"
                  // value={ItemState.name}
                  onChange={(e) =>
                    setCcantidad({
                      ...cantidadValidar,
                      valuemin: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <></>
            )}
            {cantidadValidar.size ? (
              <div className="mt-2">
                <TextField
                  sx={{ marginBottom: "2%" }}
                  fullWidth
                  id="size"
                  label="Ingrese el máximo de letras permitidas"
                  defaultValue=""
                  variant="outlined"
                  // value={ItemState.name}
                  onChange={(e) =>
                    setCcantidad({
                      ...cantidadValidar,
                      valuesize: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <></>
            )}
            {cantidadValidar.mimes ? (
              <div className="mt-2">
                <InputLabel sx={{ marginTop: "10%" }} className="mx-2">
                  Tipos de Archivos
                </InputLabel>
                <FormControl sx={{ m: 1, width: "100%", p: 1 }}>
                  <Select
                    // closeMenuOnSelect={false}
                    isMulti
                    options={state.mimesData}
                    placeholder="Seleccione los tipos de archivos"
                    getOptionValue={(options) => options.value}
                    getOptionLabel={(options) => `${options.label}`}
                    onChange={(options) =>
                      setCcantidad({ ...cantidadValidar, valuemimes: options })
                    }
                    components={{ NoOptionsMessage }}
                  />
                </FormControl>
              </div>
            ) : (
              // <div className="mt-2" >
              // <TextField
              //     sx={{ marginBottom: "2%" }}
              //     fullWidth
              //     id="size"
              //     label="Ingrese el máximo de letras permitidas"
              //     defaultValue=""
              //     variant="outlined"
              //     // value={ItemState.name}
              //     onChange={(e) => setCcantidad({ ...cantidadValidar, valuemimes: e.target.value })}
              // />
              // </div>
              <></>
            )}
          </Typography>
          <ColorButton
            sx={{ marginTop: "3%", marginLeft: "75%" }}
            onClick={() => handleAddItem(openModalItem.type)}
            variant="outlined"
          >
            {" "}
            Guardar{" "}
          </ColorButton>
        </Box>
      </Modal>
    </div>
  );
};
