import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { services } from "../services/api";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import swal from "sweetalert";
import SelectBusqueda from "../components/SelectConBusqueda";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import downloadPDF from "../utils/Base64File";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ModalCedulaProvisional = ({
  type = "",
  data,
  open,
  close,
  closeandRefresh,
}) => {
  const [rechazado, setRechazado] = useState(true);
  const handleRechazado = () => {
    setRechazado(false);
    setBotones(true);
    setForm({ ...form, estatus: 5 });
  };
  const [aceptado, setAceptado] = useState(true);
  const handleAceptado = () => {
    setAceptado(false);
    setBotones(true);
    setForm({ ...form, estatus: 2 });
  };

  const [botones, setBotones] = useState(false);
  const aceptadoRef = useRef(null);
  const [documentos, setDocumentos] = useState([]);
  const [instituciones, setInstituciones] = useState([]);

  const [form, setForm] = useState({
    selectOpcion: "",
    folio: "",
    estatus: "",
    // clave_profesion: "",
    profesion: "",
    observaciones: "",
    tomo: "",
    estado: "",
    claveInstitucion: "", // para el select  de instituciones
    clave_Institucion: "", //para el Text Field
    nombreInstitucion: "",
  });
  const rechazadoRef = useRef(null);

  const [mostrarCamposInstitucion, setMostrarCamposInstitucion] =
    useState(false);

  const handleCheckboxChange = (event) => {
    setMostrarCamposInstitucion(event.target.checked);
    setForm({
      ...form,
      estado: "",
      clave_Institucion: "",
      nombreInstitucion: "",
    });

    if (event.target.checked) {
      setForm({ ...form, claveInstitucion: "", selectOpcion: "" });
    }

    // console.log(type);
    // console.log(form.institucion_educativa);
    // console.log(form.nombreInstitucion);
    // console.log("HJOLASDLAS", form.selectOpcion);
  };

  // const handleCheckboxChange = (event) => {
  //   const isChecked = event.target.checked;
  //   setMostrarCamposInstitucion(event.target.checked);
  //   setForm((prevForm) => ({
  //     ...prevForm,
  //     estado: isChecked ? "" : prevForm.estado, // Reiniciar estado si está marcado
  //     clave_Institucion: isChecked ? "" : prevForm.clave_Institucion,
  //     nombreInstitucion: isChecked ? "" : prevForm.nombreInstitucion,
  //     // Otros campos que necesiten actualización
  //   }));
  //   if (event.target.checked) {
  //     setForm({ ...form, claveInstitucion: "" });
  //   }
  // };

  const [datos, setDatos] = useState({});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "scroll",
    p: 4,
  };

  const obtDatos = async () => {
    setForm({ ...form, folio: data.folio });
    setDatos(data);
    // console.log(data, "data");
    if (type === "SolicitudCertificadoProvisional") {
      setDocumentos([
        // { nombre: "Titulo", url: data.data.titulo_diploma_grado_archivo ?? ""},
        { nombre: "Servicio Social", url: data.data.CSS_archivo ?? "" },
        // { nombre: "Certificado Estudios", url: data.data.certificado_estudios_archivo ?? ""},
        // { nombre: "Certificado Anterior", url: data.data.certificado_estudios_anterior_archivo ?? ""},
        // { nombre: "Cedula Anterior", url: data.data.cedula_anterior_archivo ?? ""},
        {
          nombre: "Examen Profesional",
          url: data.data.acta_examen_profesional_archivo ?? "",
        },
        {
          nombre: "Acta de Nacimiento",
          url: data.data.solicitante.acta_nacimiento_archivo ?? "",
        },
        {
          nombre: "Identificación Oficial",
          url: data.data.solicitante.identificacion_archivo ?? "",
        },
        // { nombre: "Firma", url: data.data.solicitante.firma_archivo ?? ""},
        { nombre: "Curp ", url: data.data.solicitante.curp_archivo ?? "" },
        {
          nombre: "Fotografía",
          url: data.data.solicitante.fotografia_archivo ?? "",
        },
      ]);
    }
  };
  const obtenerCatalogos = async (e) => {
    // const dataProfesiones = await services("POST", "catalogos/profesiones", {})
    const dataInstitusiones = await services(
      "POST",
      "catalogos/instituciones",
      {}
    );
    // setProfesiones({
    //     ...profesiones,
    //     profesion: dataProfesiones.data[0]
    // })
    setInstituciones({
      ...instituciones,
      instituciones: dataInstitusiones.data,
    });
  };

  const handleChangeInstitucion = (e) => {
    setForm({
      ...form,
      selectOpcion: e,
      institucion_educativa: e.id,
      claveInstitucion: e.cct,
    });
    // console.log(form.claveInstitucion);
    // console.log(form.clave_Institucion);
  };

  const handleNombreInstitucion = (e) => {
    setForm({
      ...form,
      nombreInstitucion: e.target.value,
    });
  };
  const handleEstado = (e) => {
    setForm({
      ...form,
      estado: e.target.value,
    });
  };
  const handleClaveInstitucion = (e) => {
    setForm({
      ...form,
      clave_Institucion: e.target.value,
    });
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    // backgroundColor: '#4a001f',
    backgroundColor: "#6f1d46",
    "&:hover": {
      // backgroundColor: '#4a001f',
      backgroundColor: "#8d3461",
    },
  }));

  const handleChange = (e) => {
    let { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const guardar = async () => {
    let dataDatos = null;
    if (form.observaciones !== "") {
      const validacion = {
        folio: form.folio,
        estatus: form.estatus,
        observaciones: form.observaciones,
      };

      // console.log(validacion, "validacion");
      if (type === "SolicitudCertificadoProvisional") {
        dataDatos = await services(
          "POST",
          "see/profesiones/actualizaRegistroCedulaProvisional",
          validacion
        );
      }

      if (dataDatos.status === 200) {
        swal({
          title: "¡Exito!",
          text: "Solicitud Actualizada",
          icon: "success",
          button: "Aceptar",
        }).then(() => {
          closeandRefresh();
        });
      } else {
        // chris del futuro ojito aquí
        swal({
          title: "Error",
          text: dataDatos.error,
          icon: "error",
        });
      }
    } else {
      if (form.observaciones === "" && form.estatus === 5) {
        swal({
          title: "¡Atención!",
          text: "Te falta Agregar Observaciones",
          icon: "error",
        });
      } else {
        if (form.estatus === 2 && form.profesion) {
          const claveInstitucion = form.claveInstitucion
            ? form.claveInstitucion
            : form.clave_Institucion;
          const validacion = {
            folio: form.folio,
            estatus: form.estatus,
            profesion: form.profesion,
            //"tomo": form.tomo,
            foja: datos.data?.foja,
            libro: datos.data?.libro,
            clave_profesion: form.clave_profesion,
            clave_institucion: claveInstitucion,
            institucion: form.nombreInstitucion,
            entidad: form.estado,
          };

          // console.log(validacion, "validacion");

          const institucionText = form.nombreInstitucion !== "";
          const entidadText = form.estado !== "";
          const claveInstitucionSelect = form.claveInstitucion;
          const claveInstitucionText = form.clave_Institucion !== "";

          if (claveInstitucionSelect) {
            if (type === "SolicitudCertificadoProvisional") {
              dataDatos = await services(
                "POST",
                "see/profesiones/actualizaRegistroCedulaProvisional",
                validacion
              );
            }
          } else if (institucionText && entidadText && claveInstitucionText) {
            dataDatos = await services(
              "POST",
              "see/profesiones/actualizaRegistroCedulaProvisional",
              validacion
            );
          }

          if (dataDatos.status === 200) {
            swal({
              title: "¡Exito!",
              text: "Solicitud actualizada",
              icon: "success",
              button: "Aceptar",
            }).then(() => {
              closeandRefresh();
            });
          } else {
            // chris del futuro ojito aquíSX
            swal({
              title: "Error",
              text: "Los campos son requeridos",
              icon: "error",
            });
          }
        } else {
          swal({
            title: "¡Atención!",
            text: "Te falta Agregar la Clave de Profesión ó Tomo",
            icon: "error",
          });
        }
      }
    }
  };

  // const guardar = async () => {
  //   let dataDatos = null;
  //   console.log(type);
  //   if (form.observaciones !== "") {
  //     const validacion = {
  //       folio: form.folio,
  //       estatus: form.estatus,
  //       observaciones: form.observaciones,
  //     };

  //     console.log(validacion, "validacion");
  //     if (type === "SolicitudCertificadoProvisional") {
  //       dataDatos = await services(
  //         "POST",
  //         "see/profesiones/actualizaRegistroCedulaProvisional",
  //         validacion
  //       );
  //     }

  //     if (dataDatos.status === 200) {
  //       swal({
  //         title: "¡Éxito!",
  //         text: "Solicitud Actualizada",
  //         icon: "success",
  //         button: "Aceptar",
  //       }).then(() => {
  //         closeandRefresh();
  //       });
  //     } else {
  //       // chris del futuro ojito aquí

  //       swal({
  //         title: "¡Error!",
  //         text: dataDatos.error,
  //         icon: "error",
  //       });
  //     }
  //   } else {
  //     if (form.observaciones === "" && form.estatus === 5) {
  //       swal({
  //         title: "¡Atención!",
  //         text: "Te falta Agregar Observaciones",
  //         icon: "error",
  //       });
  //     } else {
  //       if (form.estatus === 2 && form.profesion) {
  //         const claveInstitucion = form.claveInstitucion
  //           ? form.claveInstitucion
  //           : form.clave_Institucion;
  //         const validacion = {
  //           folio: form.folio,
  //           estatus: form.estatus,
  //           profesion: form.profesion,
  //           //"tomo": form.tomo,
  //           foja: datos.data?.foja,
  //           libro: datos.data?.libro,
  //           clave_profesion: form.clave_profesion,
  //           clave_institucion: claveInstitucion,
  //           institucion: form.nombreInstitucion,
  //           entidad: form.estado,
  //         };

  //         console.log(validacion, "validacion");

  //         const institucionText = form.nombreInstitucion !== "";
  //         const entidadText = form.estado !== "";
  //         const claveInstitucionSelect = form.claveInstitucion;
  //         const claveInstitucionText = form.clave_Institucion !== "";

  //         if (claveInstitucionSelect) {
  //           if (type === "SolicitudCertificadoProvisional") {
  //             dataDatos = await services(
  //               "POST",
  //               "see/profesiones/actualizaRegistroCedulaProvisional",
  //               validacion
  //             );
  //           }
  //         } else if (institucionText && entidadText && claveInstitucionText) {
  //           dataDatos = await services(
  //             "POST",
  //             "see/profesiones/actualizaRegistroCedulaProvisional",
  //             validacion
  //           );
  //         }
  //         console.log(dataDatos, "dataDatos");

  //         if (dataDatos === 200) {
  //           console.log("SUCCESS", dataDatos);
  //           swal({
  //             title: "¡Éxito!",
  //             text: "Solicitud actualizada",
  //             icon: "success",
  //             button: "Aceptar",
  //           }).then(() => {
  //             closeandRefresh();
  //           });
  //         } else {
  //           // chris del futuro ojito aquíSX
  //           console.log("ERRRROOOOR", dataDatos);
  //           console.log("dataDatos normal", dataDatos);
  //           swal({
  //             title: "¡Error!",
  //             text: dataDatos
  //               ? dataDatos.error
  //               : "Error, no se pudo procesar la solicitud",
  //             icon: "error",
  //           });
  //         }
  //       } else {
  //         swal({
  //           title: "¡Atención!",
  //           text: "Te falta Agregar la Clave de Profesión ó Tomo",
  //           icon: "error",
  //         });
  //       }
  //     }
  //   }
  // };

  const handleAceptadoPagado = async () => {
    const validacion = {
      estatus: 4,
      folio: form.folio,
    };

    const dataDatos = await services(
      "POST",
      "see/profesiones/actualizaRegistroCedulaProvisional",
      validacion
    );

    if (dataDatos.status === 200) {
      // console.log(dataDatos);
      swal({
        title: "¡Éxito!",
        text: "Solicitud actualizada, se descargará el certificado al presionar aceptar.",
        icon: "success",
        button: "Aceptar",
      }).then(() => {
        downloadPDF(dataDatos.data.constancia, "constancia", null, "pdf");
        closeandRefresh();
      });
    } else {
      swal({
        title: "¡Error!",
        text: dataDatos.error,
        icon: "error",
      });
    }
  };
  // const handleChangeTomo = (e) => {
  //     setForm({ ...form, tomo: e.target.value })
  // }
  const handleChangeProfesion = (e) => {
    setForm({ ...form, profesion: e.target.value });
    // console.log(form.profesion);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      obtenerCatalogos();
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      obtDatos();
    }
  }, []);

  useEffect(() => {
    if (aceptadoRef.current) {
      aceptadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [aceptado]);

  useEffect(() => {
    if (!rechazado && rechazadoRef.current) {
      rechazadoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [rechazado]);

  return (
    <div>
      <Modal
        open={true}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Detalles de Cédula Provisional
            </Typography>
            <IconButton
              size="large"
              onClick={() => {
                close();
              }}
            >
              <CloseRoundedIcon fontSize="inherit"></CloseRoundedIcon>
            </IconButton>
          </Box>

          <Divider />

          <Grid container spacing={1}>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Estatus: </strong> {datos.estatus}
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Fecha: </strong>{" "}
                {dayjs(datos.fecha).format("YYYY-MM-DD")}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Folio: </strong> {datos.folio}
              </Typography>
            </Grid>

            <Grid className="mt-4 d-flex justify-content-center" item md={12}>
              {data.estatus === "Validada" && data.data.pago ? (
                <a
                  href={data.data.pago}
                  target="_blank"
                  rel="noreferrer"
                  className="cta cta--guinda p-3 text-white"
                  style={{ width: "max-content", borderRadius: "10px" }}
                >
                  Generar formato de pago <InsertDriveFileIcon />
                </a>
              ) : data.estatus === "Terminada" && data.data.constancia ? (
                <a
                  href={data.data.constancia}
                  target="_blank"
                  rel="noreferrer"
                  className="cta cta--guinda p-3 text-white"
                  style={{ width: "max-content", borderRadius: "10px" }}
                >
                  Descargar documento <FileDownloadIcon />
                </a>
              ) : null}
            </Grid>

            {datos.data?.lcaptura ? (
              <Grid item md={12}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <strong>Línea de captura: </strong> {datos.data?.lcaptura}
                </Typography>
              </Grid>
            ) : null}
          </Grid>

          <div className="container my-3 ms-2">
            <div className="border-bottom--guinda text-big mb-2 text-bold ">
              Datos Personales
            </div>
          </div>

          <Grid container spacing={1} style={{ marginLeft: "8px" }}>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>CURP: </strong> {datos.data?.solicitante?.curp}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Nombre:</strong> {datos.data?.solicitante?.nombre}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Fecha Nacimiento:</strong>{" "}
                {datos.data?.solicitante?.fecha_nacimiento}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Lugar Nacimiento:</strong>{" "}
                {datos.data?.solicitante?.lugar_nacimiento}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Sexo: </strong> {datos.data?.solicitante?.sexo}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Teléfono:</strong> {datos.data?.solicitante?.telefono}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Correo: </strong> {datos.data?.solicitante?.email}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Calle: </strong> {datos.data?.solicitante?.calle}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Número: </strong> {datos.data?.solicitante?.no_ext}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Entidad Federativa: </strong>{" "}
                {datos.data?.solicitante?.entidad_fede}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Municipio: </strong>{" "}
                {datos.data?.solicitante?.municipio}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Código Postal: </strong>{" "}
                {datos.data?.solicitante?.codigo_Post}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Colonia: </strong> {datos.data?.solicitante?.colonia}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Localidad: </strong>{" "}
                {datos.data?.solicitante?.localidad}
              </Typography>
            </Grid>

            <div className="container my-3 ">
              <div className="border-bottom--guinda text-big mb-2 text-bold">
                Datos Laborales
              </div>
            </div>

            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Trabaja Actualmente :</strong>{" "}
                {datos.data?.solicitante?.trabajo?.trabajo === 1 ? "Si" : "No"}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Municipio donde labora:</strong>{" "}
                {datos.data?.solicitante?.trabajo?.municipio}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Sector en que presta sus servicios:</strong>{" "}
                {datos.data?.solicitante?.trabajo?.sector === 0
                  ? "Privado"
                  : "Público"}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Calle:</strong>{" "}
                {datos.data?.solicitante?.trabajo?.calle}
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Número:</strong>{" "}
                {datos.data?.solicitante?.trabajo?.numero_ext}
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Télefono:</strong>{" "}
                {datos.data?.solicitante?.trabajo?.telefono}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Correo Electrónico:</strong>{" "}
                {datos.data?.solicitante?.trabajo?.correo}
              </Typography>
            </Grid>

            {type === "SolicitudCertificadoProvisional" ? (
              <>
                <div className="container my-3">
                  <div className="border-bottom--guinda text-big mb-2 text-bold">
                    DATOS DE ACTA DE TITULACIÓN
                  </div>
                </div>

                <Grid item md={4}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Institución: </strong> {datos.data?.institucion}
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Fecha de examen: </strong>{" "}
                    {datos.data?.fecha_examen_profesional}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Carrera: </strong>{" "}
                    {datos.data?.solicitante?.carrera}
                  </Typography>
                </Grid>
                {/* <Grid item md={4}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            <strong>Número de oficio de autorización: </strong> {datos.data?.numero_autorizacion}
                                        </Typography>
                                    </Grid> */}
                {/* <Grid item md={4}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            <strong>Número de expediente (Normal y CBTIS): </strong> {datos.data?.numero_expediente}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            <strong> Foja: </strong> {datos.data?.foja}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            <strong> Libro: </strong> {datos.data?.libro}
                                        </Typography>
                                    </Grid> */}
              </>
            ) : null}

            <div className="container my-3">
              <div className="border-bottom--guinda text-big mb-2 text-bold">
                Archivos adjuntos
              </div>
            </div>

            {type === "SolicitudCertificado" ? (
              <>
                <Grid item md={4}>
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>Identificación oficial:</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={datos.data?.solicitante?.identificacion_archivo}
                    className="preview-iframe"
                  ></iframe>
                  <a
                    target="_blank"
                    className="botonDescargaArchivoCedula"
                    href={datos.data?.solicitante?.identificacion_archivo}
                  >
                    Ver archivo
                  </a>
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>Titulo profesional:</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={datos.data?.titulo_archivo}
                    className="preview-iframe"
                  ></iframe>
                  <a
                    target="_blank"
                    className="botonDescargaArchivoCedula"
                    href={datos.data?.titulo_archivo}
                  >
                    Ver archivo
                  </a>
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>Cedula profesional:</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={datos.data?.cedula_archivo}
                    className="preview-iframe"
                  ></iframe>
                  <a
                    download
                    className="botonDescargaArchivoCedula"
                    href={datos.data?.cedula_archivo}
                  >
                    Ver archivo
                  </a>
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ mt: 2, textAlign: "center" }}>
                    <strong>Curp:</strong>
                  </Typography>
                  <iframe
                    id="frameFotografia"
                    src={datos.data?.solicitante?.curp_archivo}
                    className="preview-iframe"
                  ></iframe>
                  <a
                    download
                    className="botonDescargaArchivoCedula"
                    href={datos.data?.solicitante?.curp_archivo}
                  >
                    Ver archivo
                  </a>
                </Grid>
              </>
            ) : (
              documentos.map((item) => {
                // console.log(item)
                return (
                  <Grid item md={4}>
                    <Typography sx={{ mt: 2, textAlign: "center" }}>
                      <strong>{item.nombre}</strong>
                    </Typography>
                    <iframe
                      id="frameFotografia"
                      src={item.url}
                      className="preview-iframe"
                    ></iframe>
                    {item.nombre === "Fotografía" ? (
                      <div
                        className="botonDescargaArchivoCedula cursor-pointer"
                        onClick={() => {
                          // console.log(item.url,"item.url")
                          window.open(item.url, "_blank");
                        }}
                      >
                        Ver archivo
                      </div>
                    ) : (
                      <a
                        target="_blank"
                        className="botonDescargaArchivoCedula"
                        href={item.url}
                      >
                        Ver archivo
                      </a>
                    )}
                  </Grid>
                );
              })
            )}
          </Grid>
          {datos.estatus === "Abierto" ||
          datos.estatus === "En cambio" ||
          datos.estatus === "En Proceso" ? (
            <Box
              id="botones"
              sx={{ mt: 2 }}
              display="flex"
              justifyContent="flex-end"
              hidden={botones}
            >
              <Button
                onClick={handleRechazado}
                variant="contained"
                color="error"
              >
                Rechazar
              </Button>
              <Button
                onClick={handleAceptado}
                sx={{ ml: 2, backgroundColor: "#6f1d46" }}
                variant="contained"
              >
                Aceptar
              </Button>
            </Box>
          ) : datos.estatus === "Pagado" ? (
            <Box
              id="botones"
              sx={{ mt: 2 }}
              display="flex"
              justifyContent="flex-end"
              hidden={botones}
            >
              <ColorButton
                onClick={() => {
                  closeandRefresh();
                }}
              >
                Cerrar
              </ColorButton>
              {/* <Button onClick={handleRechazado} variant="contained" color="error">Rechazar</Button> */}
              <Button
                onClick={handleAceptadoPagado}
                sx={{ ml: 2, backgroundColor: "#6f1d46" }}
                variant="contained"
              >
                Aceptar
              </Button>
            </Box>
          ) : (
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton
                onClick={() => {
                  closeandRefresh();
                }}
              >
                Cerrar
              </ColorButton>
            </Box>
          )}

          <Box
            id="rechazado"
            hidden={rechazado}
            ref={rechazadoRef}
            tabIndex={-1}
          >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                id="standard-multiline-static"
                name="observaciones"
                onChange={handleChange}
                value={form.observaciones}
                label="Observaciones"
                multiline
                rows={2}
                variant="standard"
              />
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={guardar}>Guardar y enviar</ColorButton>
            </Box>
          </Box>
          <Box id="aceptado" ref={aceptadoRef} sx={{ mt: 3 }} hidden={aceptado}>
            <Grid container spacing={1}>
              {/* <Grid item xs={12}>
                                <TextField fullWidth name="clave_profesion" value={form.clave_profesion} onChange={handleChange} label="Clave de profesión" variant="standard" />
                            </Grid> */}
              {/**
                              <Grid item xs={12}>
                                <TextField fullWidth name="tomo" value={form.tomo} onChange={handleChangeTomo} label="Tomo" variant="standard" />
                            </Grid>
                             */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="profesión"
                  value={form.profesion}
                  onChange={handleChangeProfesion}
                  label="Clave de Profesión"
                  variant="standard"
                />
              </Grid>
              <Grid item md={7}>
                {!mostrarCamposInstitucion ? (
                  <SelectBusqueda
                    opciones={instituciones.instituciones}
                    funcion={handleChangeInstitucion}
                    selectOpcion={form.selectOpcion}
                  />
                ) : null}
              </Grid>
              <Grid className="mt-4 ms-5" item md={4}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox onChange={handleCheckboxChange} />}
                    label="Otra institución"
                  />
                </FormGroup>
              </Grid>
              {mostrarCamposInstitucion && (
                <>
                  <Grid item md={6}>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { width: "35ch" } }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="estado"
                        name="estado"
                        value={form.estado}
                        onChange={handleEstado}
                        label="Estado:"
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { width: "35ch" } }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="nombreInstitucion"
                        name="nombreInstitucion"
                        value={form.nombreInstitucion}
                        onChange={handleNombreInstitucion}
                        label="Nombre de Institución:"
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      component="form"
                      sx={{ "& > :not(style)": { width: "35ch" } }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="clave_Institucion"
                        name="clave_Institucion"
                        value={form.clave_Institucion}
                        onChange={handleClaveInstitucion}
                        label="Clave de la Institución:"
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ColorButton onClick={guardar}>Guardar y Enviar</ColorButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalCedulaProvisional;
