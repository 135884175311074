import { useState, useEffect, useRef } from "react";
import { services } from "../services/api";
import { useHistory } from "react-router-dom";
import { toTop } from "../utils/animationFunctions";
import Modal from "@mui/material/Modal";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Rating from "@mui/material/Rating";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
// icons
import HelpIcon from "@mui/icons-material/Help";

// import FactCheckIcon from "@mui/icons-material/FactCheck";
// import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import BookIcon from "@mui/icons-material/Book";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// Data

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import StarIcon from "@mui/icons-material/Star";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import validacionImagen from "../assets/img/ModuloFactura/Validacion.svg";
import FacturacionImagen from "../assets/img/ModuloFactura/Facturacion.svg";
import swal from "sweetalert";
import ReactPlayer from "react-player";

import GifLoader from "../components/ui/gifLoader.js";

// only in christmas

// import ReactAudioPlayer from 'react-audio-player';
import ReactRecaptcha3 from "react-google-recaptcha3";
import iconoValidacion from "../assets/img/validaciondocs.png";
import iconoFactura from "../assets/img/facturafacil.png";
import servicioAtencion from "../assets/img/AtencionCiuda.png";
// import ClearIcon from "@mui/icons-material/Clear";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const styleModalHelp = {
  position: "absolute",
  top: "50%",
  left: "50%",
  widht: "80%",
  transform: "translate(-50%, -50%)",
  width: 800,
  boxShadow: 24,
  p: 4,
  height: "400px",
  backgroundColor: "#fff",
  display: "flex",
};

const HomeViewPublic = () => {
  let history = useHistory();

  // const servicios = Categorias();

  const [subtitleModal, setSubtitleModal] = useState(false);

  const [modalItem, setModalItem] = useState({});

  const [servicios, setServicios] = useState([]);

  const [dataFilter, setDataFilter] = useState([]);

  const [loader, setLoader] = useState(false);

  const [StateHelp, setStateHelp] = useState({
    video: "",
    manual: "",
    modalHelp: false,
  });

  const [modalView, setModalView] = useState(false);
  const [loadingBtns, setLoadingBtns] = useState(false);
  const modalRef = useRef(null);

  const [modalServices, setModalServices] = useState(false);
  const [sectionSelected, setSectionSelected] = useState(true);
  const [sectionData, setSectionData] = useState({
    nombre: "",
    img: "",
    valoracion: "",
    numTramites: "",
    descripcion: "",
  });

  const [idComment, setIdComment] = useState(null);
  const [space, setSpace] = useState(null);

  const [dataProcedure, setDataProcedure] = useState({
    image: "",
    subtitulo: "",
    titulo: "",
    url: "",
    folio: "",
    id: 0,
    valoracionPrev: 0,
    comentarios: [],
    nextPage: null,
    prevPage: null,
    disabledNext: true,
    disabledPrev: true,
  });

  // STATE PARA LOS CUADROS DE BUSQUEDA
  const [vista, setVista] = useState({
    cuadroVerificar: false,
    btnReturn: false,
    verificarPrincipal: true,
    listadoTramites: [],
    listadoCategorias: true,
    listadoTramitesBuscar: [],
    listadoCategoriasBuscar: [],
  });

  const [state, setState] = useState({
    getCategorias: [],
    tempGetCategorias: [],
    getConceptos: [],
    loading: false,
    error: false,
    valueSearch: "",
    hover: false,
    itemHover: null,
    users: [],
    showPopUp: false,
    showWelcomeView: false,
  });

  // const iconStyle = {
  //   color: "#6f1d46",
  //   color: "#8d949e",
  //   height: "4em",
  //   width: "4em",
  // };

  const iconStyleHelp = {
    color: "#6f1d46",
    // color: "#8d949e",
    height: "2.5em",
    width: "2.5em",
  };

  const iconStyleHelpSearch = {
    color: "#6f1d46",
    // color: "#8d949e",
    height: "2em",
    width: "2em",
  };

  const textLabel = {
    color: "#6f1d46",
  };

  const iconStyleHelpp = {
    // color: "#6f1d469d",
    color: "#50b69491",
    // color: "#8d949e",
    height: "2.5em",
    width: "2.5em",
  };

  // const iconsTramites = {
  //   FactCheckIcon: <FactCheckIcon sx={iconStyle} />,
  //   WorkspacePremiumIcon: <WorkspacePremiumIcon sx={iconStyle} />,
  //   AccountBalanceIcon: <AccountBalanceIcon sx={iconStyle} />,
  //   BookIcon: <BookIcon sx={iconStyle} />,
  // };

  const getCategorias = async () => {
    setLoader(true);
    toTop();

    setState({ ...state, loading: true });
    // let categorias = await services('GET', 'categorias', null)
    // let conceptos = await services('GET', `categorias/3`, null)

    const testAll = await services("POST", "ventanillaTramites", {
      padres: 1,
    });

    // const testPrueba = await services("POST", "ventanillaTramites", {
    //   id: 114,
    // });

    // console.log("TRAMITE ESPECIFICO:", testPrueba);

    if (testAll.status === 200) {
      const categorias = testAll.data.filter((item) => item.id);

      let tramites = [];

      // const tramites = testAllFilter.data.filter(
      //   (item) => item.parent_id !== null
      // );
      for (const categoria of categorias) {
        const tramitesRes = await services("POST", "ventanillaTramites", {
          id: categoria.id,
        });

        if (tramitesRes.status === 200) {
          // Guardar los trámites obtenidos
          const tramitesAsociados = tramitesRes.data;
          tramites = [...tramites, ...tramitesAsociados];

          // Asignar los trámites a la categoría correspondiente
          categoria.tramites = tramitesAsociados;
        }
      }

      // const categoriasConTramites = categorias.map((categoria) => {
      //   const tramitesAsociados = tramites.filter(
      //     (tramite) => tramite.parent_id === categoria.id
      //   );
      //   return { ...categoria, tramites: tramitesAsociados };
      // });

      setLoader(false);
      setServicios(testAll.data);
      // DATA FILTRADA DE CATEGORIAS Y DENTRO VAN LOS TRAMITES
      setDataFilter(categorias);
      setVista({
        ...vista,
        listadoTramites: testAll.data,
      });
    }

    // console.log("TODOS LOS TRAMITES:", test2);

    // let newCategorias = categorias.data.TB_CATEGORIA.filter((item) => {
    //     return item.ID_CATEGORIA !== "GR10" && item.ID_CATEGORIA !== 'GR13' && item.ID_CATEGORIA !== 'GR03'
    // })

    // let newConceptos = conceptos.data.TB_CATEGORIAS.filter((item) => {
    //     return item.ID_CATEGORIA !== "GR10" && item.ID_CATEGORIA !== 'GR13' && item.ID_CATEGORIA !== 'GR03'
    // })

    // if (categorias.status === 200) {
    //     // setState({ ...state, getConceptos: conceptos.data.TB_CATEGORIAS, getCategorias: categorias.data.TB_CATEGORIA, tempGetCategorias: categorias.data.TB_CATEGORIA, loading: false, error: false, textEmpty: true })
    //     setState({ ...state, getConceptos: [], getCategorias: newCategorias, tempGetCategorias: newCategorias, loading: false, error: false, textEmpty: true })
    //     setLoader(false)
    // } else {
    //     setLoader(false)
    //     setState({ ...state, getCategorias: [], tempGetCategorias: [], loading: false, error: true })
    // }
  };

  const [modalRequeriments, setModalRequeriments] = useState({
    modal: false,
    id: null,
  });

  const handleModalRequeriments = (value, id) => {
    if (value === "open") {
      setModalRequeriments({
        ...modalRequeriments,
        modal: true,
        id,
      });
    } else {
      setModalRequeriments({
        ...modalRequeriments,
        modal: false,
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getCategorias();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const help = (item) => {
    setStateHelp({
      ...StateHelp,
      video: item.video,
      manual: item.manual,
      modalHelp: true,
    });
  };

  // ABRIR MODAL
  const viewModal = () => {
    setModalView(!modalView);
  };

  const viewSubtitleModal = (item) => {
    setSubtitleModal(!subtitleModal);
    setModalItem(item);
  };

  // FORMATEO DE FECHA DE CREACION
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const formattedTime = date.toLocaleTimeString("es-ES", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} a las ${formattedTime}`;
  };

  // FUNCION PARA OBTENER COMENTARIOS POR TRAMITE
  const getComments = async (cursor, idComment) => {
    setLoadingBtns(true);

    const currentIdComment =
      idComment !== undefined ? idComment : dataProcedure.id;

    let recaptcha_token = "";

    await ReactRecaptcha3.getToken().then(
      (token) => {
        // console.log(token);
        recaptcha_token = token;
        // send token with form data
        // dataToSend.token = token
        // fetch(url, { method: 'POST', body: JSON.stringify(dataToSend) })
      },
      (error) => {
        console.log(error);
      }
    );

    const dataComments = {
      recaptcha_token: recaptcha_token,
      cursor: cursor,
    };

    const { data, status } = await services(
      "POST",
      `ventanillaTramites/valoracion/comentarios/${currentIdComment}`,
      dataComments
    );

    if (status === 422) {
      swal({
        title: "¡Atención!",
        text: `${
          data !== undefined
            ? data
            : "El trámite ya ha sido valorado anteriormente."
        }`,
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        setModalView(false);
      });
    }

    if (status === 404) {
      swal({
        title: "¡Atención!",
        text: data,
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        history.push("/");
      });
    }

    if (status === 200) {
      setLoadingBtns(false);
      setDataProcedure({
        ...dataProcedure,
        comentarios: data.data,
        nextPage: data.next_cursor,
        prevPage: data.prev_cursor,
        disabledNext: data.next_page_url,
        disabledPrev: data.prev_page_url,
      });
    }
  };

  const nextPageComments = async () => {
    getComments(dataProcedure.nextPage, idComment);
  };

  const prevPageComments = async () => {
    getComments(dataProcedure.prevPage, idComment);
  };

  const handleCloseModalHelp = () => {
    setStateHelp({ ...StateHelp, modalHelp: false });
  };

  // Cargado de api
  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(
      (status) => {
        // console.log(status);
      }
    );
    // getToken();
  }, []);

  const handleFacturaFacil = () => {
    window.open("https://www.facturafacil.michoacan.gob.mx/", "_blank");
  };

  const handlegoToProcess = (item) => {
    if (item.proceso === "ConfiguradorP" && item.proceso === "Interno") {
      history.push(`/${item.url}`);
    } else if (item.proceso === "Externo") {
      window.open(
        item.url.substring(0, 3) === "htt" ? item.url : `https://${item.url}`,
        "_blank"
      );
    } else {
      window.open(item.url, "_blank");
    }
  };

  const openModalServices = () => {
    setModalServices(true);
  };

  // const Buscar = (e, type) => {
  //   console.log("Luis", vista.cuadroVerificar);
  //   console.log("Adrian", type);
  //   if (e.target.value.length !== 0) {
  //     if (type === "Categorias") {
  //       console.log(servicios);
  //       setVista({
  //         ...vista,
  //         listadoCategoriasBuscar: servicios.map((item) => {
  //           if (
  //             item.type
  //               .toLowerCase()
  //               .normalize("NFD")
  //               .replace(/[\u0300-\u036f]/g, "")
  //               .includes(
  //                 e.target.value
  //                   .toLowerCase()
  //                   .normalize("NFD")
  //                   .replace(/[\u0300-\u036f]/g, "")
  //               )
  //           ) {
  //             return item;
  //           }
  //         }),
  //       });
  //     } else {
  //       console.log(vista.listadoTramites);
  //       setVista({
  //         ...vista,
  //         listadoTramitesBuscar: vista.listadoTramites.map((item) => {
  //           if (
  //             item.titulo
  //               .toLowerCase()
  //               .normalize("NFD")
  //               .replace(/[\u0300-\u036f]/g, "")
  //               .includes(
  //                 e.target.value
  //                   .toLowerCase()
  //                   .normalize("NFD")
  //                   .replace(/[\u0300-\u036f]/g, "")
  //               )
  //           ) {
  //             return item;
  //           }
  //         }),
  //       });
  //     }
  //   } else
  //     setVista({
  //       ...vista,
  //       listadoTramitesBuscar: [],
  //       listadoCategoriasBuscar: [],
  //     });
  // };

  const inputRef = useRef(null);

  const resetInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const Buscar = (e, type) => {
    if (e.target.value.length !== 0) {
      if (type === "Categorias") {
        const categoriasEncontradas = dataFilter
          ? dataFilter
              .map((item) => {
                const categoriaType = item.type
                  ? item.type
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  : "";
                const searchTerm = e.target.value
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");

                if (categoriaType.includes(searchTerm)) {
                  return item;
                } else {
                  return null;
                }
              })
              .filter((item) => item !== null)
          : [];

        setVista({
          ...vista,
          listadoCategoriasBuscar: categoriasEncontradas,
        });
      } else {
        const tramitesEncontrados = vista.listadoTramites
          ? vista.listadoTramites
              .map((item) => {
                const tramiteTitulo = item.titulo
                  ? item.titulo
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  : "";
                const searchTerm = e.target.value
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");

                if (tramiteTitulo.includes(searchTerm)) {
                  return {
                    ...item,
                    subtitulo: item.subtitulo || "", // Asegúrate de que subtitulo esté definido
                  };
                } else {
                  return null;
                }
              })
              .filter((item) => item !== null)
          : [];

        setVista({
          ...vista,
          listadoTramitesBuscar: tramitesEncontrados,
        });
      }
    } else {
      setVista({
        ...vista,
        listadoTramitesBuscar: [],
        listadoCategoriasBuscar: [],
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [vistaActual, setVistaActual] = useState("principal");

  useEffect(() => {
    const handlePopstate = (event) => {
      if (event.state && event.state.vista) {
        setVistaActual(event.state.vista);
      } else {
        setSectionSelected(true);
        setVista({
          ...vista,
          btnReturn: false,
          verificarPrincipal: true,
          cuadroVerificar: false,
          listadoCategorias: true,
        });
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
     // eslint-disable-next-line
  }, []);

  const mostrarVistaDetalle = (nombre) => {
    const url = `${nombre}`;
    window.history.pushState({ vista: "detalle" }, "", url);
  };

  const handleGoToCategory = async (data) => {
    setSectionSelected(false);

    setSectionData({
      ...sectionData,
      nombre: data.type,
      img: data.image,
      valoracion: data.valoracion,
      numTramites: data.Ntramites,
      descripcion: data.descripcion,
    });

    mostrarVistaDetalle(data.type);

    setLoader(true);

    let test = null;

    if (data.url) {
      window.location.href = data.url;
    } else {
      test = await services("POST", "ventanillaTramites", { id: data.id });

      if (test.status === 200) {
        setLoader(false);
        setVista({
          ...vista,
          btnReturn: true,
          verificarPrincipal: false,
          cuadroVerificar: true,
          listadoCategorias: false,
          listadoTramites: test.data,
        });
      }
    }
  };

  const regresar = () => {
    window.history.back();
  };

  const newLocal = <HelpIcon sx={iconStyleHelpp} />;
  return (
    <div className="div-navidad position-relative">
      {/* <Navidad/> */}
      <div
        className="FacturaValidacion"
        onClick={() => {
          openModalServices();
        }}
      >
        <div className="PuntaIzquierda"></div>

        <div className="PuntaDerecha"></div>
        <div className="container pt-2 pt-md-1">
          <div
            className="d-flex justify-content-center align-items-center menuServiciosescritorio"
            style={{ color: "white" }}
          >
            <div className="text-center ">Facturación Electrónica</div>
            <div className=" text-center" style={{ paddingTop: "5px" }}>
              <ArrowDropDownIcon sx={{ fontSize: "3rem" }} />
            </div>

            <div>Comprobantes Digitales</div>
          </div>
          <div className="d-sm-flex d-lg-none d-md-none flex-column align-items-center justify-content-center menuServiciosMovil">
            <div className=" text-center">SERVICIOS</div>
            <div className="arrowMovil">
              <ArrowDropDownIcon sx={{ fontSize: "3rem" }} />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`d-flex flex-column justify-content-center align-items-center mx-auto container pb-3 ${
          sectionSelected ? "card-home" : "card-home-tramite"
        }`}
      >
        <div className="d-flex flex-column justify-content-center align-items-center mt-4 w-75">
          {sectionSelected ? (
            <div style={{ textAlign: "center" }}>
              <div className="content-text-home pt-4 pt-md-0  container">
                <span className="h2 text-vinoGob text-bold navidad mb-3">
                  ¡Bienvenido!
                </span>

                <p className="h5 text-vinoGob mb-1 d-none d-md-block">
                  Nos alegra mucho tenerte de vuelta en tu Ventanilla Digital de
                  Trámites. <br /> ¿Qué institución o trámite deseas consultar
                  hoy?
                </p>
                <p className="h5 text-vinoGob mb-1 d-md-none">
                  Nos alegra mucho tenerte de vuelta en nuestra plataforma.
                </p>
                {/* <br/>Por favor, haz click en el Iniciar Sesión. */}
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center mx-auto content-info-tramite">
              <div style={{ textAlign: "center" }}>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2">
                  <div>
                    <img
                      height={"120px"}
                      alt="Imagen Decorativa"
                      src={sectionData.img}
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <span className="h3 text-vinoGob text-bold sizeletrasgobTitle navidad">
                      {sectionData.nombre}
                    </span>
                  </div>
                </div>
                <p className="mt-3">{sectionData.descripcion}</p>
                <div className="d-flex flex-column flex-md-row justify-content-center gap-2 mt-3">
                  <p className="m-0 color-text fw-bold">
                    Trámites Disponibles: {sectionData.numTramites}
                    <PlaylistAddCheckIcon
                      sx={{
                        color: "#6b1e49",
                        fontSize: "15px",
                      }}
                    />
                  </p>

                  <p className="m-0 color-text fw-bold">
                    Valoración: {sectionData.valoracion}
                    <StarIcon
                      sx={{
                        color: "#fcac04",
                        fontSize: "14px",
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="mx-4 mx-md-0 w-100 content-search">
            <FormControl
              // style={{ border: "thin solid #6f1d46" }}
              fullWidth
              sx={{
                m: 1,
                backgroundColor: "white",
                borderRadius: "14px",
              }}
              variant="outlined"
            >
              <InputLabel sx={textLabel} htmlFor="outlined-adornment-password">
                {space ? (
                  <>Buscar</>
                ) : (
                  // `Busca tu ${vista.cuadroVerificar ? "Trámite" : "Categoría"}`
                  "Busca tu Trámite"
                )}
              </InputLabel>
              <OutlinedInput
                inputRef={inputRef}
                onFocus={() => setSpace(true)}
                onBlur={() => setSpace(false)}
                onChange={(e) => {
                  // Buscar(e, vista.cuadroVerificar ? "Tramite" : "Categorias");

                  Buscar(e, vista.cuadroVerificar ? "Tramite" : "Categorias");
                }}
                sx={{ borderRadius: "14px" }}
                id="outlined-adornment-password"
                type={"text"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="buscar" edge="end">
                      <SearchIcon sx={{ color: "#6f1d46" }} />
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
        </div>
        <div className="btn-back-home">
          {vista.btnReturn ? (
            <div
              className="cta cta--guinda"
              style={{ width: "max-content", borderRadius: "10px" }}
              onClick={() => {
                setSectionSelected(true);
                regresar();
                resetInput();
                setVista({
                  ...vista,
                  btnReturn: false,
                  verificarPrincipal: true,
                  cuadroVerificar: false,
                  listadoCategorias: true,
                });
              }}
            >
              {" "}
              Regresar
            </div>
          ) : null}
        </div>
      </div>

      <br />
      <br />
      <br />
      <div
        className={`mx-auto container ${
          sectionSelected ? "mt-5" : "content-tramites"
        }`}
      >
        <div className="d-lg-flex">
          <Box sx={{ flexGrow: 1, marginTop: "2%" }}>
            {/* <div className="container">
              <Grid
                sx={{ marginTop: 3 }}
                container
                alignItems="flex-start"
                spacing={2}
              >
                <Grid align="right" item xs={6} sm={3} md={3}></Grid>
              </Grid>
            </div> */}
            <Grid
              className="content-main-tramites"
              container
              spacing={2}
              columns={16}
              style={
                vista.listadoCategorias.length < 5 ? { height: "1200px" } : {}
              }
            >
              <Grid className="d-flex justify-content-center" item xs={16}>
                {vista.listadoCategorias ? (
                  <Grid
                    className="content-tramites-home w-100"
                    container
                    spacing={2}
                    columns={
                      vista.listadoCategoriasBuscar.length !== 0
                        ? { xs: 1 }
                        : { xs: 2, sm: 4, md: 6, xl: 8 }
                    }
                  >
                    {vista.listadoCategoriasBuscar.length !== 0
                      ? // PONE SOLO EL QUE ESCRIBA EN EL CUADRO DE BUSQUEDA
                        vista.listadoCategoriasBuscar.map((item) =>
                          item ? (
                            <>
                              <Grid
                                className="d-lg-flex gap-4 mt-4"
                                item
                                xs={2}
                              >
                                <Card
                                  sx={{
                                    maxWidth: 450,
                                    marginTop: "3%",
                                    minWidth: 250,
                                    backgroundColor: "transparent",
                                    boxShadow: "none",
                                  }}
                                >
                                  <CardActionArea
                                    onClick={() => {
                                      resetInput();
                                      handleGoToCategory(item);
                                    }}
                                  >
                                    <CardContent sx={{ minHeight: 170 }}>
                                      <Card className="nuevodiseño">
                                        <CardActionArea>
                                          <CardContent
                                            sx={{
                                              minHeight: 170,
                                              textAlign: "center",
                                            }}
                                          >
                                            <CardMedia
                                              sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <img
                                                width={"150px"}
                                                height={"150px"}
                                                alt="Imagen Decorativa"
                                                src={item.image}
                                                loading="lazy"
                                              />
                                            </CardMedia>
                                            <Typography
                                              sx={{ fontSize: "2rem" }}
                                              gutterBottom
                                              variant="h5"
                                              component="div"
                                              className="text-bolder text-gray90"
                                            >
                                              {item.type}
                                            </Typography>
                                          </CardContent>
                                        </CardActionArea>
                                      </Card>
                                    </CardContent>

                                    <CardContent>
                                      <Card
                                        className="responsiveDes "
                                        sx={{
                                          maxHeight: 50,
                                          minHeight: 50,
                                          borderRadius: "30px",
                                        }}
                                      >
                                        <CardActionArea>
                                          <CardContent sx={{ minHeight: 50 }}>
                                            <Typography
                                              sx={{ fontSize: "11px" }}
                                              gutterBottom
                                              variant="p"
                                              component="div"
                                              className="text-center"
                                            >
                                              {/* Asignar valoracion y tramites que no se devuelven */}
                                              {`Trámites Disponibles:`}
                                              <span className="text-bold text-guinda">{`${item.Ntramites}`}</span>
                                              <PlaylistAddCheckIcon
                                                sx={{
                                                  color: "#6b1e49",
                                                  fontSize: "15px",
                                                }}
                                              />

                                              {` Valoración:`}
                                              <span className="text-bold text-guinda">{`${item.valoracion}`}</span>
                                              <StarIcon
                                                sx={{
                                                  color: "#fcac04",
                                                  fontSize: "14px",
                                                }}
                                              />
                                            </Typography>
                                          </CardContent>
                                        </CardActionArea>
                                      </Card>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>

                                <div className="d-flex flex-column mt-1 mt-lg-0 w-100">
                                  <h3 className="text-center text-lg-start mt-3 text-guinda text-boldertext-guinda text-bolder">
                                    Trámites de {item.type}
                                  </h3>
                                  {item.tramites &&
                                    item.tramites.map(
                                      (tramite, tramiteIndex) => (
                                        <div
                                          className="px-4 py-5 sombrasprincipal mb-4 rounded-card position-relative"
                                          key={tramiteIndex}
                                        >
                                          {/* <img className="img-fluid" src={tramite.image} alt="Imagen del trámite" /> */}
                                          <h4 className="text-guinda text-bolder">
                                            {tramite.titulo}
                                          </h4>
                                          <p>{tramite.subtitulo}</p>
                                          <div className="position-absolute w-100 d-flex gap-2 bottom-0 start-0 ms-3 d-flex gap-1">
                                            <div className="d-flex gap-1">
                                              <p>
                                                <strong>Valoración:</strong>
                                              </p>
                                              <Rating
                                                className=""
                                                name="half-rating-read"
                                                defaultValue={
                                                  tramite.calificacion
                                                }
                                                precision={0.5}
                                                readOnly
                                              />
                                            </div>
                                            <div className="d-none d-lg-block">
                                              <p>
                                                <strong>
                                                  Trámites realizados:{" "}
                                                </strong>
                                                {tramite.total_tramites}
                                              </p>
                                            </div>
                                            <div className="d-none d-lg-block">
                                              <p>
                                                <strong>Valoraciones: </strong>
                                                {tramite.total_valorados}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="position-absolute top-0 end-0">
                                            <CardContent
                                              className="d-flex flex-column justify-content-center align-items-center p-0 pt-2"
                                              onClick={() =>
                                                handlegoToProcess(tramite)
                                              }
                                              sx={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <IconButton
                                                aria-label="delete"
                                                size="small"
                                              >
                                                <ArrowCircleRightIcon
                                                  sx={iconStyleHelpSearch}
                                                />
                                              </IconButton>
                                            </CardContent>
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              </Grid>
                              <div className="border-bottom w-100 mt-4"></div>
                            </>
                          ) : null
                        )
                      : // PONE TODOS
                        servicios.map((item) => (
                          <Grid item xs={2}>
                            <Card
                              className="mx-auto"
                              sx={{
                                maxWidth: 450,
                                marginTop: "3%",
                                minWidth: 250,
                                backgroundColor: "transparent",
                                boxShadow: "none",
                              }}
                            >
                              <CardActionArea
                                onClick={() => {
                                  resetInput();
                                  handleGoToCategory(item);
                                }}
                              >
                                <CardContent
                                  className="pb-1"
                                  sx={{ minHeight: 170 }}
                                >
                                  <Card className="nuevodiseño ">
                                    <CardActionArea>
                                      <CardContent
                                        sx={{
                                          height: 250,
                                          textAlign: "center",
                                        }}
                                      >
                                        <CardMedia
                                          className="mb-2"
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <img
                                            width={"150px"}
                                            height={"150px"}
                                            alt="Imagen Decorativa"
                                            src={item.image}
                                            loading="lazy"
                                          />
                                        </CardMedia>
                                        <Typography
                                          sx={{
                                            // fontSize: "2rem",
                                            marginTop: 2,
                                          }}
                                          gutterBottom
                                          variant="h5"
                                          component="div"
                                          className="text-bolder text-gray90 h4-5 mt-2"
                                        >
                                          {item.type}
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </CardContent>

                                <CardContent className="pt-0">
                                  <Card
                                    className="responsiveDes w-100"
                                    sx={{
                                      maxHeight: 60,
                                      minHeight: 50,
                                      borderRadius: "30px",
                                    }}
                                  >
                                    <CardActionArea>
                                      <CardContent sx={{ minHeight: 50 }}>
                                        <Typography
                                          sx={{ fontSize: "11px" }}
                                          gutterBottom
                                          variant="p"
                                          component="div"
                                          className="text-center d-flex flex-column gap-1 align-items-center justify-content-center"
                                        >
                                          <div>
                                            {`Trámites Disponibles:`}
                                            <span className="text-bold text-guinda">{`${item.Ntramites}`}</span>
                                            <PlaylistAddCheckIcon
                                              sx={{
                                                color: "#6b1e49",
                                                fontSize: "15px",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            {` Valoración:`}
                                            <span className="text-bold text-guinda">{`${item.valoracion}`}</span>
                                            <StarIcon
                                              sx={{
                                                color: "#fcac04",
                                                fontSize: "14px",
                                              }}
                                            />
                                          </div>
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        ))}
                  </Grid>
                ) : (
                  <>
                    {/* PONE SOLO LOS QUE SE PONEN EN EL BUSCADOR */}
                    {vista.listadoTramitesBuscar.length !== 0 ? (
                      <>
                        <Grid
                          container
                          spacing={2}
                          columns={{ xs: 1, sm: 1, md: 1 }}
                          sx={{ marginBottom: "3%" }}
                        >
                          {vista.listadoTramitesBuscar.map((item) =>
                            item ? (
                              <Grid item xs={5}>
                                <Card
                                  className="sombrasprincipal TarjetaTramitesEscritorio position-relative "
                                  sx={{
                                    // maxWidth: 1000,
                                    minWidth: "100%",
                                    boxShadow: "none",
                                    marginTop: "3%",
                                    borderRadius: "30px",
                                    // borderRadius: "30px",
                                    // maxHeight: "150px",
                                  }}
                                >
                                  {/* <CardActionArea
                                                                            // onClick={() => handlegoToProcess(item)}
                                                                        > */}

                                  <CardContent sx={{ minHeight: 50 }}>
                                    {/* <CardActionArea > */}
                                    <Grid
                                      container
                                      rowSpacing={1}
                                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                    >
                                      <Grid item xs={10}>
                                        <Grid
                                          className="h-100 gap-lg-3"
                                          container
                                          columns={{ xs: 4, sm: 12, md: 12 }}
                                        >
                                          <Grid
                                            item
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            className="me-4 me-lg-0"
                                          >
                                            <CardContent
                                              sx={{ textAlign: "center" }}
                                            >
                                              {/* {iconsTramites[item.image]} */}

                                              <img
                                                width={"100px"}
                                                height={"100px"}
                                                alt="Imagen Decorativa"
                                                src={item.image}
                                              />
                                            </CardContent>
                                          </Grid>
                                          <Grid item xs={2} sm={8} md={8}>
                                            <CardContent
                                              sx={{ textAlign: "start" }}
                                            >
                                              <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="div"
                                                className="text-guinda text-bolder"
                                              >
                                                {item.titulo}
                                              </Typography>
                                              <Typography
                                                gutterBottom
                                                variant="p"
                                                sx={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  display: "-webkit-box",
                                                  "-webkit-line-clamp": 3,
                                                  "-webkit-box-orient":
                                                    "vertical",
                                                  "max-height":
                                                    item.subtitulo.length >= 240
                                                      ? "4.6em"
                                                      : "",
                                                }}
                                              >
                                                {item.subtitulo}
                                              </Typography>
                                              {item.subtitulo.length >= 240 && (
                                                <Typography variant="p">
                                                  <button
                                                    className="border-0 hover-btn"
                                                    onClick={() => {
                                                      viewSubtitleModal(item);
                                                    }}
                                                  >
                                                    Ver más
                                                  </button>
                                                </Typography>
                                              )}
                                            </CardContent>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            className="d-flex flex-column justify-content-lg-center align-items-center mx-2 mx-lg-0 pt-4 pt-lg-0"
                                          >
                                            <IconButton
                                              aria-label="delete"
                                              size="large"
                                              onClick={() => {
                                                item.ayuda
                                                  ? help(item)
                                                  : console.log(
                                                      "no disponible"
                                                    );
                                              }}
                                            >
                                              {newLocal}
                                            </IconButton>
                                            <Rating
                                              name="half-rating-read"
                                              defaultValue={item.calificacion}
                                              precision={0.5}
                                              readOnly
                                            />
                                          </Grid>

                                          <div className="d-flex justify-content-center gap-3 w-100 me-4 position-absolute bottom-0 ">
                                            <div className="d-flex justify-content-center d-md-block">
                                              <button
                                                className="border-0 hover-btn color-text"
                                                onClick={() => {
                                                  viewModal();
                                                  getComments(null, item.id);
                                                  setIdComment(item.id);
                                                }}
                                              >
                                                {/* <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-message"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                />
                                                <path d="M8 9h8" />
                                                <path d="M8 13h6" />
                                                <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                                              </svg> */}
                                                Ver comentarios
                                              </button>{" "}
                                            </div>
                                            |
                                            {item.total_tramites ===
                                            0 ? null : (
                                              <>
                                                <p>
                                                  {/* <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  class="icon icon-tabler icon-tabler-file-check"
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 24 24"
                                                  stroke-width="1.5"
                                                  stroke="#000"
                                                  fill="none"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                >
                                                  <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                  />
                                                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                  <path d="M9 15l2 2l4 -4" />
                                                </svg> */}
                                                  Trámites Realizados:{" "}
                                                  {item.total_tramites}
                                                </p>
                                                |
                                              </>
                                            )}
                                            <p>
                                              {/* <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="icon icon-tabler icon-tabler-star"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#000"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                            </svg> */}
                                              Valoraciones:{" "}
                                              {item.total_valorados}
                                            </p>
                                          </div>
                                          {/* <Grid item xs={4} sm={8} md={12} >
                                                                                                <CardContent sx={{ textAlign: "end" }}>
                                                                                                    <Typography gutterBottom variant="p" component="div">
                                                                                                        {`Trámites Realizados: 0 | Valoración:${item.calificacion}`}
                                                                                                    </Typography>
                                                                                                </CardContent>
                                                                                            </Grid> */}
                                        </Grid>
                                      </Grid>

                                      {/* EDITAR AQUI */}
                                      <Grid
                                        item
                                        xs={2}
                                        className="d-flex flex-column content-tramite align-items-center p-0 mt-3 border-left-lg"
                                      >
                                        <CardContent
                                          className="d-flex flex-column justify-content-center align-items-center p-0"
                                          onClick={() =>
                                            handlegoToProcess(item)
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <IconButton
                                            aria-label="delete"
                                            size="large"
                                          >
                                            <ArrowCircleRightIcon
                                              sx={iconStyleHelp}
                                            />
                                          </IconButton>
                                          <Typography
                                            className="text-center m-0"
                                            gutterBottom
                                            variant="h6"
                                            component="div"
                                          >
                                            Iniciar Trámite
                                          </Typography>
                                        </CardContent>

                                        {item.id === 21 ||
                                        item.id === 18 ||
                                        item.id === 19 ? (
                                          <div>
                                            <button
                                              onClick={() => {
                                                handleModalRequeriments(
                                                  "open",
                                                  item.id
                                                );
                                              }}
                                              className="border-0 text-decoration-underline"
                                            >
                                              Ver requisitos
                                            </button>
                                          </div>
                                        ) : null}
                                      </Grid>
                                    </Grid>

                                    {/* </CardActionArea> */}
                                  </CardContent>
                                  {/* </CardActionArea> */}
                                </Card>
                              </Grid>
                            ) : null
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        {/* PONE TODOS POR DEFAULT TRAMITES */}
                        <Grid
                          container
                          spacing={0}
                          sx={{ marginBottom: "3%" }}
                          columns={{ xs: 1, sm: 1, md: 1 }}
                        >
                          {vista.listadoTramites.map((item) => (
                            <Grid item xs={5}>
                              <Card
                                className="sombrasprincipal TarjetaTramitesEscritorio position-relative "
                                sx={{
                                  // maxWidth: 1000,
                                  minWidth: "100%",
                                  boxShadow: "none",
                                  marginTop: "3%",
                                  borderRadius: "30px",
                                  // maxHeight: "230px",
                                  // height: "250px",
                                }}
                              >
                                {/* <CardActionArea
                                                                            // onClick={() => handlegoToProcess(item)}
                                                                        > */}
                                <CardContent sx={{ minHeight: 50 }}>
                                  {/* <CardActionArea > */}
                                  <Grid
                                    container
                                    rowSpacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                  >
                                    <Grid item xs={10}>
                                      <Grid
                                        className="h-100 gap-lg-3"
                                        container
                                        columns={{ xs: 4, sm: 12, md: 12 }}
                                      >
                                        <Grid
                                          item
                                          xs={2}
                                          sm={2}
                                          md={2}
                                          className="me-4 me-lg-0"
                                        >
                                          <CardContent
                                            sx={{ textAlign: "center" }}
                                          >
                                            {/* {iconsTramites[item.image]} */}
                                            <img
                                              width={"100px"}
                                              height={"100px"}
                                              alt="Imagen Decorativa"
                                              src={item.image}
                                              loading="lazy"
                                            />
                                          </CardContent>
                                        </Grid>
                                        <Grid item xs={2} sm={8} md={8}>
                                          <CardContent
                                            sx={{ textAlign: "start" }}
                                          >
                                            <Typography
                                              gutterBottom
                                              variant="h5"
                                              component="div"
                                              className="text-guinda text-bolder"
                                            >
                                              {item.titulo}
                                            </Typography>
                                            <Typography
                                              gutterBottom
                                              variant="p"
                                              sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                "-webkit-line-clamp": 3,
                                                "-webkit-box-orient":
                                                  "vertical",
                                                "max-height":
                                                  typeof item.subtitulo ===
                                                    "string" &&
                                                  item.subtitulo.length >= 240
                                                    ? "4.6em"
                                                    : "",
                                              }}
                                            >
                                              {item?.subtitulo || ""}
                                            </Typography>
                                            {item.subtitulo.length >= 240 && (
                                              <Typography variant="p">
                                                <button
                                                  className="border-0 hover-btn"
                                                  onClick={() => {
                                                    viewSubtitleModal(item);
                                                  }}
                                                >
                                                  Ver más
                                                </button>
                                              </Typography>
                                            )}
                                          </CardContent>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={1}
                                          sm={1}
                                          md={1}
                                          className="d-flex flex-column justify-content-lg-center align-items-center mx-2 mx-lg-0 pt-4 pt-lg-0"
                                        >
                                          {/* {item.ayuda !== null ?
                                                                                                <IconButton
                                                                                                    aria-label="delete"
                                                                                                    size="large"
                                                                                                    onClick={() => { help(item) }}
                                                                                                >
                                                                                                    <HelpIcon sx={iconStyleHelp} />
                                                                                                </IconButton>
                                                                                                : null} */}
                                          <IconButton
                                            aria-label="delete"
                                            size="large"
                                            onClick={() => {
                                              item.ayuda
                                                ? help(item)
                                                : console.log("no disponible");
                                            }}
                                          >
                                            {newLocal}
                                          </IconButton>
                                          <Rating
                                            name="half-rating-read"
                                            defaultValue={item.calificacion}
                                            precision={0.5}
                                            readOnly
                                          />
                                        </Grid>

                                        {/* <Grid onClick={() => handlegoToProcess(item)} item xs={4} sm={8} md={12} >
                                                                                            <CardContent sx={{ textAlign: "end" }}>
                                                                                                <Typography gutterBottom variant="p" component="div">
                                                                                                    {`Trámites Realizados: 0 | Valoración:${item.calificacion}`}
                                                                                                </Typography>
                                                                                            </CardContent>
                                                                                        </Grid> */}

                                        <div className="d-flex justify-content-center gap-3 w-100 me-4 position-absolute bottom-0 ">
                                          <div className="d-flex justify-content-center d-md-block">
                                            <button
                                              className="border-0 hover-btn color-text"
                                              onClick={() => {
                                                viewModal();
                                                getComments(null, item.id);
                                                setIdComment(item.id);
                                              }}
                                            >
                                              {/* <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-message"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                />
                                                <path d="M8 9h8" />
                                                <path d="M8 13h6" />
                                                <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                                              </svg> */}
                                              Ver comentarios
                                            </button>{" "}
                                          </div>
                                          |
                                          {item.total_tramites === 0 ? null : (
                                            <>
                                              <p>
                                                {/* <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  class="icon icon-tabler icon-tabler-file-check"
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 24 24"
                                                  stroke-width="1.5"
                                                  stroke="#000"
                                                  fill="none"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                >
                                                  <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                  />
                                                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                  <path d="M9 15l2 2l4 -4" />
                                                </svg> */}
                                                Trámites Realizados:{" "}
                                                {item.total_tramites}
                                              </p>
                                              |
                                            </>
                                          )}
                                          <p>
                                            {/* <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="icon icon-tabler icon-tabler-star"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#000"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                            </svg> */}
                                            Valoraciones: {item.total_valorados}
                                          </p>
                                        </div>
                                      </Grid>
                                    </Grid>

                                    {/* EDITAR AQUI */}
                                    <Grid
                                      item
                                      xs={2}
                                      className="d-flex flex-column content-tramite align-items-center p-0 mt-3 border-left-lg"
                                    >
                                      <CardContent
                                        className="d-flex flex-column justify-content-center align-items-center p-0"
                                        onClick={() => handlegoToProcess(item)}
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          size="large"
                                        >
                                          <ArrowCircleRightIcon
                                            sx={iconStyleHelp}
                                          />
                                        </IconButton>
                                        <Typography
                                          className="text-center m-0"
                                          gutterBottom
                                          variant="h6"
                                          component="div"
                                        >
                                          Iniciar Trámite
                                        </Typography>
                                      </CardContent>
                                      {item.id === 21 ||
                                      item.id === 18 ||
                                      item.id === 19 ? (
                                        <div>
                                          <button
                                            onClick={() => {
                                              handleModalRequeriments(
                                                "open",
                                                item.id
                                              );
                                            }}
                                            className="border-0 text-decoration-underline"
                                          >
                                            Ver requisitos
                                          </button>
                                        </div>
                                      ) : null}
                                    </Grid>
                                  </Grid>

                                  {/* </CardActionArea> */}
                                </CardContent>
                                {/* </CardActionArea> */}
                              </Card>

                              {/* Celulares tarjeta   */}

                              <Card
                                className="sombrasprincipal TarjetaTramitesMovil"
                                sx={{
                                  maxWidth: 1000,
                                  minWidth: "100%",
                                  boxShadow: "none",
                                  marginTop: "3%",
                                  borderRadius: "30px",
                                }}
                              >
                                <CardActionArea>
                                  <CardContent sx={{ minHeight: 50 }}>
                                    <CardActionArea>
                                      <Grid
                                        container
                                        rowSpacing={0}
                                        columnSpacing={{ xs: 0, sm: 0, md: 0 }}
                                      >
                                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center w-100">
                                          <Grid item xs={12}>
                                            <CardContent
                                              onClick={() =>
                                                handlegoToProcess(item)
                                              }
                                              sx={{ textAlign: "center" }}
                                            >
                                              <img
                                                width={"100px"}
                                                height={"100px"}
                                                alt="Imagen Decorativa"
                                                src={item.image}
                                              />
                                            </CardContent>
                                          </Grid>

                                          <Grid item xs={12}>
                                            <CardContent
                                              className="text-center text-md-start"
                                              onClick={() =>
                                                handlegoToProcess(item)
                                              }
                                              sx={{
                                                color: "#4b101f",
                                              }}
                                            >
                                              <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="div"
                                              >
                                                {item.titulo}
                                              </Typography>
                                            </CardContent>
                                          </Grid>
                                        </div>

                                        <Grid item xs={12}>
                                          <CardContent
                                            onClick={() =>
                                              handlegoToProcess(item)
                                            }
                                            sx={{ textAlign: "start" }}
                                          >
                                            <Typography
                                              gutterBottom
                                              variant="p"
                                              className="text-center text-md-start"
                                              sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                "-webkit-line-clamp": 3,
                                                "-webkit-box-orient":
                                                  "vertical",
                                                "max-height":
                                                  item.subtitulo.length >= 220
                                                    ? "4.6em"
                                                    : "",
                                              }}
                                            >
                                              {item.subtitulo}
                                            </Typography>
                                            {item.subtitulo.length >= 220 && (
                                              <Typography variant="p">
                                                <button
                                                  className="border-0 hover-btn"
                                                  onClick={() => {
                                                    viewSubtitleModal(item);
                                                  }}
                                                >
                                                  Ver mas
                                                </button>
                                              </Typography>
                                            )}
                                          </CardContent>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={6}
                                          className="d-flex flex-column justify-content-center align-items-center mb-5"
                                        >
                                          <IconButton
                                            aria-label="delete"
                                            size="large"
                                            onClick={() => {
                                              item.ayuda
                                                ? help(item)
                                                : console.log("no disponible");
                                            }}
                                          >
                                            <HelpIcon
                                              sx={{
                                                ...iconStyleHelp,
                                                color: "#50b69491",
                                              }}
                                            />
                                          </IconButton>
                                          <Rating
                                            name="half-rating-read"
                                            defaultValue={item.calificacion}
                                            precision={0.5}
                                            readOnly
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          className="d-flex flex-column justify-content-center align-items-center mb-4"
                                        >
                                          <CardContent
                                            onClick={() =>
                                              handlegoToProcess(item)
                                            }
                                            sx={{ textAlign: "center" }}
                                          >
                                            <IconButton
                                              aria-label="delete"
                                              size="large"
                                            >
                                              <ArrowCircleRightIcon
                                                sx={iconStyleHelp}
                                              />
                                            </IconButton>
                                            <Typography
                                              className="m-0"
                                              gutterBottom
                                              variant="h6"
                                              component="div"
                                            >
                                              Iniciar Trámite
                                            </Typography>
                                          </CardContent>
                                          {item.id === 21 ||
                                          item.id === 18 ||
                                          item.id === 19 ? (
                                            <div>
                                              <button
                                                onClick={() => {
                                                  handleModalRequeriments(
                                                    "open",
                                                    item.id
                                                  );
                                                }}
                                                className="border-0 text-decoration-underline"
                                              >
                                                Ver requisitos
                                              </button>
                                            </div>
                                          ) : null}
                                        </Grid>
                                        <div className="d-flex flex-column gap-1 w-100 me-4">
                                          <div className="d-flex d-md-block">
                                            <button
                                              className="border-0 hover-btn d-flex justify-content-center align-items-center gap-1 ps-0 color-text"
                                              onClick={() => {
                                                viewModal();
                                                getComments(null, item.id);
                                                setIdComment(item.id);
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-message d-lg-none"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#6f1d46"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                />
                                                <path d="M8 9h8" />
                                                <path d="M8 13h6" />
                                                <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                                              </svg>
                                              Ver comentarios
                                            </button>{" "}
                                          </div>

                                          {item.total_tramites === 0 ? null : (
                                            <>
                                              <p className="mb-0 d-flex align-items-center gap-1">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  class="icon icon-tabler icon-tabler-file-check d-lg-none"
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 24 24"
                                                  stroke-width="1.5"
                                                  stroke="#000"
                                                  fill="none"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                >
                                                  <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                  />
                                                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                  <path d="M9 15l2 2l4 -4" />
                                                </svg>
                                                Trámites Realizados:{" "}
                                                {item.total_tramites}
                                              </p>
                                            </>
                                          )}
                                          <p className="d-flex align-items-center gap-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="icon icon-tabler icon-tabler-star d-lg-none"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#000"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                            </svg>
                                            Valoraciones: {item.total_valorados}
                                          </p>
                                        </div>
                                      </Grid>
                                    </CardActionArea>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
        {!sectionSelected ? (
          <>
            <div className="d-none d-md-block d-flex justify-content-center text-center container mx-auto p-0 mt-2 mb-3 w-100 sombrasprincipal rounded-card">
              <a
                className="d-none d-md-block"
                href="https://www.protestaciudadana.michoacan.gob.mx/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="img-fluid rounded-card"
                  src={servicioAtencion}
                  alt="Atención ciudadana"
                  loading="lazy"
                />
              </a>
            </div>

            <p className="m-0 d-md-none mt-4 d-md-none text-center">
              Si, tuviste alguna mala experiencia con el servicio, puedes
              reportar tu caso:{" "}
              <a
                className="color-text underline"
                href="https://www.protestaciudadana.michoacan.gob.mx/"
                target="_blank"
                rel="noreferrer"
              >
                Aquí
              </a>
            </p>
          </>
        ) : null}
      </div>

      <Modal
        open={modalServices}
        onClose={() => setModalServices(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="scroll"
      >
        <Box className="ModalServicios">
          <div className="container" style={{ background: "#fff" }}>
            <div className="d-lg-flex h-100 ">
              <Box sx={{ flexGrow: 1, marginTop: "2%", width: "100%" }}>
                <Grid
                  className="mt-3"
                  container
                  rowSpacing={1}
                  columns={{ xs: 1, sm: 2, md: 2 }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid
                    item
                    xs={1}
                    className="d-flex flex-column justify-content-center align-items-center gap-3 h-100 "
                  >
                    <Card className="nuevodiseñoServiciosCard">
                      <CardActionArea
                        onClick={() => {
                          handleFacturaFacil();
                        }}
                      >
                        <CardContent
                          className="p-0 pt-4"
                          sx={{ minHeight: 250, textAlign: "center" }}
                        >
                          <CardMedia
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            <img
                              width={"60%"}
                              className="imgServiciosvalidacion"
                              alt="Imagen Decorativa"
                              src={validacionImagen}
                              loading="lazy"
                            />
                          </CardMedia>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    <div className="d-flex  flex-column flex-lg-row justify-content-center align-items-center gap-2">
                      <div>
                        <img
                          width="80px"
                          height="80px"
                          src={iconoFactura}
                          alt="Factura fácil"
                          loading="lazy"
                        />
                      </div>
                      <div className="text-center text-bolder text-gray90 h4-5">
                        Factura Fácil
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={1} className='ServiciosCardMovil'>
                                        VALIDACIÓN DE REGISTRO DOCUMENTOS
                                    </Grid> */}

                  <Grid
                    item
                    xs={1}
                    className="d-flex flex-column justify-content-center align-items-center gap-3"
                  >
                    <Card className="nuevodiseñoServiciosCard">
                      <CardActionArea
                        onClick={() => {
                          history.push(
                            "/verificar-Certificado/Comprobante-Digital"
                          );
                        }}
                      >
                        <CardContent
                          className="p-0 pt-4"
                          sx={{ minHeight: 250, textAlign: "center" }}
                        >
                          <CardMedia
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <img
                              width={"50%"}
                              className="imgServiciosfactura"
                              alt="Imagen Decorativa"
                              src={FacturacionImagen}
                              loading="lazy"
                            />
                          </CardMedia>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-1">
                      <div>
                        <img
                          width="80px"
                          height="80px"
                          src={iconoValidacion}
                          alt="Validacion de documentos"
                          loading="lazy"
                        />
                      </div>
                      <div className="text-center text-bolder text-gray90 h4-5 h-100">
                        Verificador de <br /> Comprobantes Digitales
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={1}>
                                        FACTURA FÁCIL
                                    </Grid> */}
                  {/* <Grid item xs={1} className='ServiciosCardEscritorio'>
                                        VALIDACIÓN DE REGISTRO DOCUMENTOS
                                    </Grid> */}
                </Grid>
              </Box>
            </div>
          </div>
          <div className="LineaFacturaValidador"></div>
          <div
            className="FacturaValidacionActive"
            onClick={() => {
              setModalServices(false);
            }}
          >
            <div className="PuntaIzquierdaActive"></div>

            <div className="PuntaDerechaActive"></div>
            <div className="container cajaServiciosActive">
              <div
                className="d-flex justify-content-center align-items-center menuServiciosescritorio"
                style={{ color: "white" }}
              >
                <div className="text-center">Facturación Electrónica</div>
                <div className="text-center">
                  <ArrowDropUpIcon sx={{ fontSize: "3rem" }} />
                </div>
                <div className=" text-center">Comprobantes Digitales</div>
              </div>

              <div className="d-flex flex-column justify-content-center aling-items-center menuServiciosMovil d-md-none">
                <div>SERVICIOS</div>
                <ArrowDropUpIcon
                  className="mx-auto"
                  sx={{ fontSize: "3rem" }}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={StateHelp.modalHelp}
        onClose={handleCloseModalHelp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalHelp}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            rowSpacing={1}
            columns={{ xs: 1, sm: 1, md: 1 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {StateHelp.video ? (
              <Grid item xs={1}>
                <ReactPlayer
                  // url='https://www.youtube.com/watch?v=lQBtXEAAkLU&ab_channel=kyOresu'
                  url={StateHelp.video}
                  className="react-player"
                  playing
                  width="100%"
                  height="300px"
                  controls
                />
              </Grid>
            ) : null}

            {StateHelp.manual ? (
              <Grid item xs={1} alignContent={"center"} alignItems={"center"}>
                <div
                  className="cta cta--guinda "
                  style={{
                    width: "max-content",
                    borderRadius: "30px",
                    left: "38%",
                  }}
                  onClick={() => {
                    window.open(StateHelp.manual, "_blank");
                  }}
                >
                  Manual en PDF
                </div>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Modal>

      {loader ? <GifLoader></GifLoader> : null}

      {/* MODAL PARA CARGAR COMENTARIOS POR TRAMITE */}
      {modalView ? (
        <Modal
          open={modalView}
          onClose={viewModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal} ref={modalRef}>
            <div
              className="w-100 top-0 position-sticky"
              style={{ zIndex: "999" }}
            >
              <button
                onClick={viewModal}
                className="border-0 rounded-circle bg-color position-absolute end-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-x p-1"
                  width="30"
                  height="40"
                  viewBox="0 0 24 24"
                  strokeWidth="2.5"
                  stroke="#fff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </button>
            </div>

            <h2 className="d-flex align-items-center gap-2 color-text fw-bold h4 text-center text-md-start mb-2">
              COMENTARIOS
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-message d-none d-md-block"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#6f1d46"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 9h8" />
                <path d="M8 13h6" />
                <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
              </svg>
            </h2>

            <div className="py-4">
              <ul className="p-0">
                {dataProcedure.comentarios.length === 0 ? (
                  <li>No hay comentarios en este trámite…</li>
                ) : loadingBtns ? (
                  <li>Cargando comentarios…</li>
                ) : (
                  dataProcedure.comentarios?.map((comment, index) => (
                    <div className="d-flex flex-column flex-md-row gap-2 mb-4">
                      <span className="d-md-none">Comentario {index + 1}</span>
                      <div className="w-auto d-flex justify-content-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-user-circle d-none d-md-block"
                          width="45"
                          height="45"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#6f1d46"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                          <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                          <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
                        </svg>
                      </div>
                      <div className="w-100">
                        <li
                          className="fs-5 bg-gray rounded-3 p-3 d-flex gap-2 align-items-center position-relative"
                          key={index}
                        >
                          {comment.comentario}
                          <span className="position-absolute end-0 bottom-0 fs-6 me-3 mb-1 d-none d-md-block fw-bold">
                            Creado el: {formatDate(comment.created_at)}
                          </span>
                        </li>
                      </div>
                    </div>
                  ))
                )}
              </ul>
            </div>
            <div className="d-flex flex-column gap-3 gap-md-0 flex-md-row w-100 justify-content-between">
              <button
                disabled={
                  loadingBtns || dataProcedure.disabledPrev === null
                    ? true
                    : false
                }
                onClick={() => {
                  if (modalRef.current) {
                    modalRef.current.scrollTo({ top: 0 });
                  }
                  prevPageComments();
                }}
                className={`${
                  dataProcedure.disabledPrev === null ? "opacity-25" : null
                } m-0 color-text p-2 rounded-card border-0 border-md-0 d-flex align-items-center justify-content-center`}
              >
                {loadingBtns ? (
                  <div className="spinner-border color-text" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-caret-left"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#6f1d46"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 6l-6 6l6 6v-12" />
                    </svg>
                    <span className="color-text fs-5">Atrás</span>
                  </>
                )}
              </button>
              <button
                disabled={
                  loadingBtns || dataProcedure.disabledNext === null
                    ? true
                    : false
                }
                onClick={() => {
                  if (modalRef.current) {
                    modalRef.current.scrollTo({ top: 0 });
                  }
                  nextPageComments();
                }}
                className={`${
                  dataProcedure.disabledNext === null ? "opacity-25" : null
                } m-0 border-0 p-2 rounded-card d-flex align-items-center justify-content-center`}
              >
                {loadingBtns ? (
                  <div className="spinner-border color-text" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                ) : (
                  <>
                    <span className="color-text fs-5">Siguiente</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-caret-right"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#6f1d46"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 18l6 -6l-6 -6v12" />
                    </svg>
                  </>
                )}
              </button>
            </div>
          </Box>
        </Modal>
      ) : null}

      <Modal
        open={modalRequeriments.modal}
        onClose={() => handleModalRequeriments("close")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <div className="d-flex flex-column">
            <button
              onClick={() => handleModalRequeriments("close")}
              className="border-0 rounded-circle bg-color position-absolute end-0 me-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-x p-1"
                width="30"
                height="40"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="#fff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
              </svg>
            </button>
            <h2 className="color-text fw-bold h4 mb-4">
              Requisitos del trámite
            </h2>
            {/* Cédula Provisional con acta de titulación */}
            {modalRequeriments.id === 21 ? (
              <div>
                <ul className="m-0 p-0">
                  <li className="pb-2">● Acta de nacimiento</li>
                  <li className="pb-2">● Acta de titulación</li>
                  <li className="pb-2">● Constancia de Servicio Social</li>
                  <li className="pb-2">● CURP</li>
                  <li className="pb-2">● Identificación oficial</li>
                  <li className="pb-2">● Fotografía</li>
                </ul>
              </div>
            ) : // reigstro de cedula profesional
            modalRequeriments.id === 18 ? (
              <div>
                <ul className="m-0 p-0">
                  <li>● Identificación Oficial</li>
                  <li>● CURP</li>
                  <li>● Título Profesional, Diploma o Grado</li>
                  <li>● Cédula Profesional</li>
                </ul>
              </div>
            ) : // registro de titulo
            modalRequeriments.id === 19 ? (
              <div>
                <ul className="m-0 p-0">
                  <li>● Identificación Oficial</li>
                  <li>● CURP</li>
                  <li>● Título Profesional, Diploma o Grado</li>
                  <li>● Cédula profesional/Acta de titulación</li>
                </ul>
              </div>
            ) : null}
          </div>
        </Box>
      </Modal>

      {subtitleModal && (
        <Modal
          open={subtitleModal}
          onClose={viewSubtitleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal} ref={modalRef}>
            <h1>{modalItem.titulo}</h1>
            <p>{modalItem.subtitulo}</p>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default HomeViewPublic;
