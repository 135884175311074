import { useState, useEffect } from "react";
import IconUI from "../components/ui/icon_ui";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { services } from "../services/api";
import MUIDataTable from "mui-datatables";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import Box from "@mui/material/Box";
import { FaNetworkWired } from "react-icons/fa";
import LinkIcon from "@mui/icons-material/Link";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import Card from "@mui/material/Card";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { CardContent } from "@mui/material";
import TitleIcon from "@mui/icons-material/Title";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import GifLoader from "../components/ui/gifLoader";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import KeyIcon from "@mui/icons-material/Key";

const ListadeTramites = () => {
  const colorUI = "#6f1d46";
  // const colorUI = "#8d949e";
  // const colorUISwitch = "#993367";
  const colorUISwitch = "#8d949e";

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [Hijo, setHijos] = useState();
  const [state, setState] = useState({
    rowsExpanded: [],
  });

  const [loading, setLoading] = useState(false);
  const [inputUrl, setinputUrl] = useState(false);
  const [inputUrlManual, setInputUrlManual] = useState(false);

  const [newfather, setNewFather] = useState({
    openModalAgregarPadre: false,
    titulo: "",
    descripcion: "",
    url: "",
    newFile: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    iconEdit: false,
    editFather: false,
    editId: "",
  });

  const [newChildren, setNewChildren] = useState({
    tipo: "",
    url: "",
    titulo: "",
    descripcion: "",
    manual: null,
    icono: null,
    video: null,
    dataTipos: [],
    openModal: false,
    idPadre: "",
    id_formulario: "",
    editChildren: false,
    id_Childrent: "",
    claveTramite: "",
    urlManual: "",
    urlVideo: "",
  });

  const [tramites, setTramites] = useState({
    data: [],
    newFile: {
      previewManual: "",
      frameManual: true,
      Archivo: null,

      previewVideo: "",
      frameVideo: true,
      Archivo: null,

      previewIcono: "",
      frameIcono: true,
      Archivo: null,
    },
  });

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? colorUISwitch : colorUISwitch,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: colorUISwitch,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: colorUISwitch,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const CardFile = styled(Card)(({ theme }) => ({
    height: "200px",
    width: "250px",
    margin: "auto",
    marginTop: "20px",
    borderRadius: "12px",
    boxShadow: "1px 0px 7px 0px rgba(0, 0, 0, 0.75)",
    "& .MuiCardContent-root": {
      borderColor: colorUI,
      // borderColor: '#8d949e',
      borderStyle: "dotted",
      height: "80%",
      margin: "20px 20px 20px 20px",
    },
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "#ffff",
    p: 4,
    height: "80%",
    overflow: "hidden",
    overflowY: "scroll",
  };

  const options = {
    selectableRows: "none",
    filterType: "dropdown",
    tableBodyHeight: "auto",
    expandableRows: true,
    expandableRowsHeader: false,
    // expandableRowsOnClick: true,
    rowsExpanded: state.rowsExpanded,
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      // selectedRows: {
      //   text: "columna(s) selecciona(as)",
      //   delete: "Eliminar",
      //   deleteAria: "Eliminar filas seleccionadas",
      // },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "tablaUsuarios.csv",
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },

    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;

      setHijos(tramites.data[rowMeta.dataIndex].hijos);

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <MUIDataTable
              style={{ Overflow: "hidden" }}
              data={Hijo}
              columns={columnsHijos}
              options={optionsHijos}
            />
          </TableCell>
        </TableRow>
      );
    },
    // onTableChange: (action, tableState) => {

    //   console.log("tip -> ", action)
    //   switch (action) {

    //     // case "propsUpdate":
    //     case "rowExpansionChange":

    //       let rowsExpanded = tableState.expandedRows.data.map(
    //         item => item.index
    //       );

    //       if (rowsExpanded.length > 1) {
    //         // limiting would go here
    //         rowsExpanded = rowsExpanded.slice(-1);
    //       }

    //       setState(prevState => ({
    //         rowsExpanded: rowsExpanded
    //       }));

    //       break;
    //   }
    // }
  };

  const optionsHijos = {
    selectableRows: "none",

    tableBodyHeight: "auto",

    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la búsqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "tablaUsuarios.csv",
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
  };

  const columns = [
    {
      name: "titulo",
      label: "Título",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let titulo = tramites.data[dataIndex].titulo;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {titulo}
            </div>
          );
        },
      },
    },
    {
      name: "subTitulo",
      label: "Subtítulo",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let subTitulo = tramites.data[dataIndex].subTitulo;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {subTitulo}
            </div>
          );
        },
      },
    },
    {
      name: "tipo",
      label: "Tipo",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let tipo = tramites.data[dataIndex].tipo;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {tipo}
            </div>
          );
        },
      },
    },
    {
      name: "Publicado",
      label: "Publicado",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let publicado = tramites.data[dataIndex].deleted_at;

          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={publicado ? false : true}
                    onChange={(e) => {
                      handleChangeStatus(tramites.data[dataIndex], e);
                    }}
                  />
                }
                label=""
                id={tramites.data[dataIndex].id}
              />
            </div>
          );
        },
      },
    },
    {
      name: "Liga",
      label: "Liga",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = tramites.data[dataIndex].url;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro "
                  : "w-100 d-flex justify-content-center align-items-center "
              }
            >
              <div
                style={{ height: 30, width: 30, zIndex: 0, marginRight: "1%" }}
                className="cta cta--primary cta--icon icon_btn"
                onClick={() => {
                  handleEditar(tramites.data[dataIndex], 1);
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <EditIcon />
                </IconUI>
                <div className="icon_btn_help">Editar</div>
              </div>

              <div
                style={{ height: 30, width: 30, zIndex: 0, marginRight: "1%" }}
                className="cta cta--primary cta--icon icon_btn"
                onClick={() => {
                  handleAñadirHijo(tramites.data[dataIndex].id);
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <AddToPhotosIcon />
                </IconUI>
                <div className="icon_btn_help">Añadir Hijo</div>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  const handleChangeStatus = (data, value) => {
    if (value.target.checked) {
      handleMostrar(data);
    } else {
      handleOcultar(data);
    }
  };

  const columnsHijos = [
    {
      name: "titulo",
      label: "Título",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let titulo = Hijo[dataIndex].titulo;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {titulo}
            </div>
          );
        },
      },
    },
    {
      name: "Tipo",
      label: "Tipo",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = Hijo[dataIndex].type;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "Publicado",
      label: "Publicado",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let publicado = Hijo[dataIndex].deleted_at;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={publicado ? false : true}
                    onChange={(e) => {
                      handleChangeStatus(Hijo[dataIndex], e);
                    }}
                  />
                }
                label=""
              />
            </div>
          );
        },
      },
    },
    {
      name: "Liga",
      label: "Liga",
      align: "center",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let url = Hijo[dataIndex].url;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "bg-rosaClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {url}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro "
                  : "w-100 d-flex justify-content-center align-items-center "
              }
            >
              <div
                style={{ height: 30, width: 30, zIndex: 0, marginRight: "1%" }}
                className="cta cta--primary cta--icon icon_btn"
                onClick={() => {
                  handleEditar(Hijo[dataIndex], 0);
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <EditIcon />
                </IconUI>
                <div className="icon_btn_help">Editar</div>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  const handleCloseAgregarPadre = () => {
    setNewFather({
      ...newfather,
      openModalAgregarPadre: false,
      titulo: "",
      descripcion: "",
      url: "",
      newFile: {
        previewicono: "",
        frameicono: true,
        Archivo: null,
      },
      iconEdit: false,
      editFather: false,
      editId: "",
    });
  };

  const handleMostrar = (data) => {
    swal({
      title: "Habilitar Trámite",
      text: `Se habilitara el trámite de la ventana inicial para el uso del contribuyente de manera publica`,
      icon: "warning",
      buttons: ["Cancelar", "Aceptar"],
      confirmButton: true,
    }).then(async (aceptar) => {
      if (aceptar) {
        let response = await services(
          "POST",
          `ventanillaTramites/delete/${data.id}`,
          null
        );
        if (response.status === 200) {
          swal("Ok", "Trámite Habilitado", "success");
          api();
        } else {
          swal("Error", "No fue posible Habilitar el trámite", "error");
        }
      }
    });
  };

  const handleOcultar = (data) => {
    swal({
      title: "Ocultar Trámite",
      text: `Se ocultara el tramite de la ventana inicial`,
      icon: "warning",
      buttons: ["Cancelar", "Aceptar"],
      confirmButton: true,
    }).then(async (aceptar) => {
      if (aceptar) {
        let response = await services(
          "POST",
          `ventanillaTramites/delete/${data.id}`,
          null
        );

        if (response.status === 200) {
          swal("Ok", "Trámite Ocultado", "success");
          api();
        } else {
          swal("Error", "No fue posible ocultar el trámite", "error");
        }
      }
    });
  };

  const handleAñadirHijo = (data) => {
    setNewChildren({ ...newChildren, openModal: true, idPadre: data });
  };

  const handleEditar = (data, type) => {
    // console.log(data);
    if (type === 0) {
      setNewChildren({
        ...newChildren,
        tipo: data.type,
        url: data.url,
        titulo: data.titulo,
        descripcion: data.subTitulo,
        manual: null,
        icono: null,
        video: null,
        //dataTipos:[],
        openModal: true,
        idPadre: data.parent_id,
        id_formulario: "",
        editChildren: true,
        id_Childrent: data.id,
        // SETANDO NUEVOS VALORS DE CLAVE Y URLS
        claveTramite: data.clave_tramite,
        urlVideo: data.video,
        urlManual: data.ayuda,
      });

      setTramites({
        ...tramites,
        // data:[],

        newFile: {
          previewManual: data.ayuda ? data.ayuda : "",
          frameManual: data.ayuda ? false : true,
          Archivo: data.ayuda ? data.ayuda : null,

          previewVideo: data.video ? data.video : "",
          frameVideo: data.video ? false : true,
          Archivo: data.video ? data.video : null,

          previewIcono: data.image ? data.image : "",
          frameIcono: data.image ? false : true,
          Archivo: data.image ? data.image : null,
        },
      });
    } else if (type === 1) {
      setNewFather({
        ...newfather,
        openModalAgregarPadre: true,
        titulo: data.titulo,
        descripcion: data.subTitulo,
        url: data.url,
        newFile: data.image
          ? {
              previewicono: `${data.image}`,
              frameicono: false,
              Archivo: null,
            }
          : {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
        iconEdit: data.image ? true : false,
        editFather: true,
        editId: data.id,
      });
    }
  };

  const api = async () => {
    const data = { id: 1 };

    const response = await services("GET", "ventanillaTramites/tree", null);
    // const test = await services("POST","ventanillaTramites", data);

    // console.log(test)
    if (response.status === 200) {
      setTramites({ ...tramites, data: response.data });
    }
  };

  const validateFather = () => {
    let title = newfather.titulo !== "";
    // let descripcion = newfather.descripcion !== "";
    // let url = newfather.url !== "";
    let icono = newfather.newFile.Archivo !== null;

    return title && icono;
  };

  const handleNuevoTramite = async () => {
    setLoading(true);

    if (validateFather) {
      if (newfather.editFather) {
        const databack = new FormData();

        databack.append("titulo", newfather.titulo);
        databack.append("subTitulo", newfather.descripcion);

        databack.append("url", newfather.url);

        if (newfather.newFile.Archivo !== null) {
          databack.append("image", newfather.newFile.Archivo);
        }

        const response = await services(
          "POST",
          `ventanillaTramites/crear/${newfather.editId}`,
          databack
        );

        if (response.status === 200) {
          setLoading(false);
          swal(
            "Trámite Actualizado",
            "Se Actualizo el trámite con éxito",
            "success"
          );
          handleCloseAgregarPadre();
          api();
        } else if (response.status === 422) {
          setLoading(false);
          swal("alerta", "Por Favor llene todos los campos", "warning");
        } else if (response.status === 422) {
          setLoading(false);
          swal("error", "Error de servidor, intente mas tarde", "Error");
        } else if (response.status === 500) {
          setLoading(false);
          swal("error", response.data.error, "Error");
        }
      } else {
        const databack = new FormData();

        databack.append("titulo", newfather.titulo);
        databack.append("subTitulo", newfather.descripcion);
        databack.append("url", newfather.url);
        databack.append("image", newfather.newFile.Archivo);
        // databack.append("parent_id",null)

        const response = await services(
          "POST",
          "ventanillaTramites/crear",
          databack
        );

        if (response.status === 200) {
          setLoading(false);
          swal("Tramite Agregado", "Se agrego el trámite con éxito", "success");
          handleCloseAgregarPadre();
          api();
        } else if (response.status === 422) {
          setLoading(false);
          swal("alerta", "Por Favor llene todos los campos", "warning");
        } else if (response.status === 422) {
          setLoading(false);
          swal("error", "Error de servidor, intente mas tarde", "Error");
        }
      }
    } else {
      setLoading(false);
      swal("alerta", "Por Favor llene todos los campos", "warning");
    }
  };

  const handleNuevoTramiteHijo = async () => {
    if (newChildren.titulo === "") {
      swal("Es requerido el titulo", "Por favor escriba el titulo", "info");
      return;
    } else {
      setLoading(true);
      if (newChildren.editChildren) {
        const databack = new FormData();

        databack.append("titulo", newChildren.titulo);
        databack.append("subTitulo", newChildren.descripcion);
        databack.append("url", newChildren.url);
        databack.append("clave_tramite", newChildren.claveTramite);
        if (newChildren.icono) {
          databack.append("image", newChildren.icono);
        }
        if (newChildren.video) {
          databack.append("video", newChildren.video);
        }
        if (newChildren.manual) {
          databack.append("ayuda", newChildren.manual);
        }
        // NUEVOS DATOS ENVIADOS DE URL
        if (newChildren.urlVideo) {
          databack.append("video", newChildren.urlVideo);
        }
        if (newChildren.urlManual) {
          databack.append("ayuda", newChildren.urlManual);
        }
        databack.append("type", newChildren.tipo);
        databack.append("parent_id", newChildren.idPadre);

        if (newChildren.id_formulario !== "") {
          databack.append("id_formulario", newChildren.id_formulario);
        }

        const response = await services(
          "POST",
          `ventanillaTramites/crear/${newChildren.id_Childrent}`,
          databack
        );

        if (response.status === 200) {
          setLoading(false);
          swal(
            "Tramite Actualizadó",
            "Se Actualizó el trámite con éxito",
            "success"
          );
          handleClose();
          api();
        } else if (response.status === 422) {
          setLoading(false);
          swal("Error", response.error, "error");
        }
      } else {
        const databack = new FormData();

        databack.append("titulo", newChildren.titulo);
        databack.append("subTitulo", newChildren.descripcion);
        databack.append("url", newChildren.url);
        databack.append("clave_tramite", newChildren.claveTramite);

        if (newChildren.icono) {
          databack.append("image", newChildren.icono);
        }
        if (newChildren.video) {
          databack.append("video", newChildren.video);
        }
        if (newChildren.manual) {
          databack.append("ayuda", newChildren.manual);
        }
        // NUEVOS DATOS ENVIADOS DE URL
        if (newChildren.urlVideo) {
          databack.append("video", newChildren.urlVideo);
        }
        if (newChildren.urlManual) {
          databack.append("ayuda", newChildren.urlManual);
        }
        databack.append("type", newChildren.tipo);
        databack.append("parent_id", newChildren.idPadre);

        if (newChildren.id_formulario !== "") {
          databack.append("id_formulario", newChildren.id_formulario);
        }

        const response = await services(
          "POST",
          "ventanillaTramites/crear",
          databack
        );

        if (response.status === 200) {
          setLoading(false);
          swal("Tramite Agregado", "Se agrego el trámite con éxito", "success");
          handleClose();
          api();
        } else if (response.status === 422) {
          setLoading(false);
          swal("Error", response.error, "error");
        }
      }
    }
  };

  const handleClose = () => {
    setNewChildren({
      ...newChildren,
      tipo: "",
      url: "",
      titulo: "",
      descripcion: "",
      manual: null,
      icono: null,
      video: null,
      dataTipos: [],
      openModal: false,
      idPadre: "",
      id_formulario: "",
      editChildren: false,
      id_Childrent: "",
      claveTramite: "",
      urlManual: "",
      urlVideo: "",
    });

    setinputUrl(false);
    setInputUrlManual(false);

    setTramites({
      ...tramites,

      newFile: {
        previewManual: "",
        frameManual: true,
        Archivo: null,

        previewVideo: "",
        frameVideo: true,
        Archivo: null,

        previewIcono: "",
        frameIcono: true,
        Archivo: null,
      },
    });
  };

  const handleChangeVideo = (event) => {
    const file = event.target.files[0];
    setTramites({
      ...tramites,
      newFile: {
        ...tramites.newFile,
        previewVideo: URL.createObjectURL(file),
        frameVideo: false,
        Archivo: file,
      },
    });

    setNewChildren({ ...newChildren, video: file });
  };

  const handleChangeIcono = (event) => {
    const file = event.target.files[0];
    setTramites({
      ...tramites,
      newFile: {
        ...tramites.newFile,
        previewIcono: URL.createObjectURL(file),
        frameIcono: false,
        Archivo: file,
      },
    });
    setNewChildren({ ...newChildren, icono: file });
  };

  const handleChangeManualpdf = (event) => {
    const file = event.target.files[0];
    setTramites({
      ...tramites,
      newFile: {
        ...tramites.newFile,
        previewManual: URL.createObjectURL(file),
        frameManual: false,
        Archivo: file,
      },
    });
    setNewChildren({ ...newChildren, manual: file });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  // console.log(tramites.newFile.Archivo);
  // console.log(tramites.newFile);
  const handleDrop = (event, archivo) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const file = event.dataTransfer.files[0];

    switch (archivo) {
      case "manual":
        handleChangeManualpdf({ target: { files: [file] } });
        break;

      case "video":
        handleChangeVideo({ target: { files: [file] } });
        break;

      case "icono":
        handleChangeIcono({ target: { files: [file] } });
        break;
      default:
        break;
    }

    event.currentTarget.style.border = "";
    event.currentTarget.style.filter = "";
  };

  const handleDragEnter = (event, card) => {
    event.preventDefault();
    setIsDraggingOver(true);
    if (card == "file") {
      event.currentTarget.style.border = `2px dashed ${colorUI}`;
      event.currentTarget.style.filter = "blur(1px)";
    } else if (card == "content") {
    }
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    if (!isDraggingOver) {
      event.currentTarget.style.border = "none";
      event.currentTarget.style.filter = "none";
    }
  };

  const handleTipoTramite = (data) => {
    // console.log(data.target.value);
    if (data.target.value === "ConfiguradorP") {
      apiTipoConfigurador(data.target.value);
    } else {
      if (newChildren.editChildren) {
        setNewChildren({ ...newChildren, tipo: data.target.value });
      } else {
        setNewChildren({
          ...newChildren,
          tipo: data.target.value,
          url: "",
          titulo: "",
          descripcion: "",
          claveTramite: "",
          dataTipos: [],
        });
      }
    }
  };

  // eventos icono padre

  const handleChangeIconoFather = (event) => {
    const file = event.target.files[0];
    setNewFather({
      ...newfather,
      newFile: {
        ...newfather.newFile,
        previewicono: URL.createObjectURL(file),
        frameicono: false,
        Archivo: file,
      },
      iconEdit: true,
    });
  };

  const handleDragOverFather = (event) => {
    event.preventDefault();
  };

  const handleDropFather = (event, archivo) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const file = event.dataTransfer.files[0];

    switch (archivo) {
      case "icono":
        handleChangeIconoFather({ target: { files: [file] } });
        break;
      default:
        break;
    }

    event.currentTarget.style.border = "";
    event.currentTarget.style.filter = "";
  };

  const handleDragEnterFather = (event, card) => {
    event.preventDefault();
    setIsDraggingOver(true);
    if (card == "file") {
      event.currentTarget.style.border = `2px dashed ${colorUI}`;
      event.currentTarget.style.filter = "blur(1px)";
    } else if (card == "content") {
    }
  };

  const handleDragLeaveFather = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    if (!isDraggingOver) {
      event.currentTarget.style.border = "none";
      event.currentTarget.style.filter = "none";
    }
  };

  // terminan eventos padre

  const apiTipoConfigurador = async (id) => {
    const response = await services("GET", "formularios", null);

    setNewChildren({ ...newChildren, tipo: id, dataTipos: response.data });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      api();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
          <GifLoader></GifLoader>
        </div>
      ) : (
        <></>
      )}

      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          Trámites
        </div>
      </div>

      <div className="container">
        <Grid sx={{ marginTop: 3 }} container spacing={2}>
          <Grid align="center" item xs={12} sm={6} md={6}></Grid>
          <Grid align="right" item xs={12} sm={6} md={6}>
            <div
              style={{ width: 200 }}
              className="cta cta--guinda"
              onClick={() => {
                setNewFather({ ...newfather, openModalAgregarPadre: true });
              }}
            >
              <div className="me-2" style={{ display: "contents" }}>
                <IconUI size={"20px"} color={"white"}>
                  <FaNetworkWired />
                </IconUI>{" "}
                Agregar Categoria
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <Grid className="container mt-5 mb-5">
          <Grid item xs={8}>
            <MUIDataTable
              style={{ Overflow: "hidden" }}
              title={"Listado de Trámites"}
              data={tramites.data}
              columns={columns}
              options={options}
              // components={components}
            />
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={newChildren.openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={6}>
                  <TextField
                    id="tipo"
                    select
                    label="Tipo"
                    fullWidth
                    value={newChildren.tipo}
                    onChange={(e) => {
                      handleTipoTramite(e);
                    }}
                    // defaultValue="EUR"
                    // helperText="Please select your currency"
                  >
                    <MenuItem value={"ConfiguradorP"}>Configurador</MenuItem>
                    <MenuItem value={"Interno"}>Interno</MenuItem>
                    <MenuItem value={"Externo"}>Externo</MenuItem>
                    <MenuItem value={"ConfiguradorG"}>
                      Configurador Gratuito
                    </MenuItem>
                  </TextField>
                </Grid>
                {newChildren.tipo === "ConfiguradorP" ? (
                  <Grid item xs={2} sm={4} md={6}>
                    <TextField
                      fullWidth
                      id="Categoria"
                      select
                      label="Categoria"
                      onChange={(e) => {
                        setNewChildren({
                          ...newChildren,
                          titulo: e.target.value.nombre,
                          descripcion: e.target.value.observaciones,
                          url: e.target.value.url,
                          id_formulario: e.target.value.id,
                          claveTramite: e.target.value.clave,
                        });
                      }}
                      // defaultValue="EUR"
                      // helperText="Please select your currency"
                    >
                      {newChildren.dataTipos.map((item) => (
                        <MenuItem value={item}>{item.nombre}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={5} md={12}>
                  <TextField
                    id="url"
                    label="URL"
                    fullWidth
                    disabled={
                      newChildren.tipo === "ConfiguradorP" ? true : false
                    }
                    onChange={(e) => {
                      setNewChildren({ ...newChildren, url: e.target.value });
                    }}
                    value={newChildren.url}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconUI size={"20px"} color={colorUI}>
                            <LinkIcon />
                          </IconUI>
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="titulo"
                label="Título"
                fullWidth
                disabled={newChildren.tipo === "ConfiguradorP" ? true : false}
                onChange={(e) => {
                  setNewChildren({ ...newChildren, titulo: e.target.value });
                }}
                value={newChildren.titulo}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconUI size={"20px"} color={colorUI}>
                        <TitleIcon />
                      </IconUI>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="descripcion"
                label="Descripción"
                fullWidth
                disabled={newChildren.tipo === "ConfiguradorP" ? true : false}
                onChange={(e) => {
                  setNewChildren({
                    ...newChildren,
                    descripcion: e.target.value,
                  });
                }}
                value={newChildren.descripcion}
                multiline
                maxRows={3}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconUI size={"20px"} color={colorUI}>
                        <DescriptionIcon />
                      </IconUI>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="claveTramite"
                label="Clave de Trámite"
                fullWidth
                disabled={newChildren.tipo === "ConfiguradorP" ? true : false}
                onChange={(e) => {
                  setNewChildren({
                    ...newChildren,
                    claveTramite: e.target.value,
                  });
                }}
                value={newChildren.claveTramite}
                multiline
                maxRows={3}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconUI size={"20px"} color={colorUI}>
                        <KeyIcon />
                      </IconUI>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid
                  className="d-flex flex-column align-items-center justify-content-center"
                  item
                  xs={2}
                  sm={4}
                  md={4}
                >
                  {/* URL MANUAL */}
                  {inputUrlManual ? (
                    <TextField
                      id="urlManual"
                      label="URL Manual"
                      fullWidth
                      disabled={
                        newChildren.tipo === "ConfiguradorP" ? true : false
                      }
                      onChange={(e) => {
                        setNewChildren({
                          ...newChildren,
                          urlManual: e.target.value,
                        });
                      }}
                      value={newChildren.urlManual}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconUI size={"20px"} color={colorUI}>
                              <LinkIcon />
                            </IconUI>
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  ) : (
                    <>
                      <div style={{ textAlign: "center" }}>
                        <label
                          htmlFor="ManualHijo"
                          style={{
                            backgroundColor: colorUI,
                            color: "white",
                            borderRadius: "12px",
                            cursor: "pointer",
                            padding: "5px 7px",
                            marginBottom: "10px",
                          }}
                        >
                          cargar archivo
                        </label>
                        <input
                          type="file"
                          id="ManualHijo"
                          style={{ display: "none" }}
                          accept=".pdf"
                          onChange={handleChangeManualpdf}
                        />
                        <DeleteForeverOutlinedIcon
                          style={{ color: "red", cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => {
                            setTramites({
                              ...tramites,
                              newFile: {
                                ...tramites.newFile,
                                previewManual: "",
                                frameManual: true,
                                Archivo: null,
                              },
                            });
                            setNewChildren({ ...newChildren, manual: null });
                          }}
                          sx={
                            tramites.newFile.frameManual
                              ? { display: "none" }
                              : {}
                          }
                        />
                      </div>
                      <CardFile
                        hidden={!tramites.newFile.frameManual}
                        onDrop={(event) => {
                          handleDrop(event, "manual");
                        }}
                        onDragOver={(event) => {
                          handleDragOver(event);
                          handleDragEnter(event, "file");
                        }}
                        onDragEnter={(event) => {
                          handleDragEnter(event, "file");
                        }}
                        onDragLeave={handleDragLeave}
                      >
                        <CardContent
                          onDrop={(event) => {
                            handleDrop(event, "manual");
                          }}
                          onDragOver={handleDragOver}
                          onDragEnter={(event) => {
                            handleDragEnter(event, "content");
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 15, color: colorUI }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Desliza el Manual en PDF
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: colorUI, fontWeight: "bold" }}
                          >
                            Manual
                          </Typography>
                        </CardContent>
                      </CardFile>
                      <iframe
                        id="framemanual"
                        src={tramites.newFile.previewManual}
                        className="preview-iframe"
                        hidden={tramites.newFile.frameManual}
                      ></iframe>
                    </>
                  )}

                  <div className="position-relative w-100 mt-2 d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center gap-2 mt-3">
                      <label
                        className="cursor-pointer"
                        htmlFor="checkUrlManual"
                      >
                        Colocar URL
                      </label>
                      <input
                        onClick={() => setInputUrlManual(!inputUrlManual)}
                        className="cursor-pointer"
                        id="checkUrlManual"
                        name="checkUrlManual"
                        type="checkbox"
                        // checked={newChildren.checkedBoxManual}
                        value={inputUrl}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <div style={{ textAlign: "center" }}>
                    <label
                      htmlFor="iconoHijo"
                      style={{
                        backgroundColor: colorUI,
                        color: "white",
                        borderRadius: "12px",
                        cursor: "pointer",
                        padding: "5px 7px",
                        marginBottom: "10px",
                      }}
                    >
                      cargar archivo
                    </label>
                    <input
                      type="file"
                      id="iconoHijo"
                      style={{ display: "none" }}
                      accept="image/png, .svg"
                      onChange={handleChangeIcono}
                    />
                    <DeleteForeverOutlinedIcon
                      style={{ color: "red", cursor: "pointer" }}
                      fontSize="large"
                      onClick={() => {
                        setTramites({
                          ...tramites,
                          newFile: {
                            ...tramites.newFile,
                            previewIcono: "",
                            frameIcono: true,
                            Archivo: null,
                          },
                        });
                        setNewChildren({ ...newChildren, icono: null });
                      }}
                      sx={
                        tramites.newFile.frameIcono ? { display: "none" } : {}
                      }
                    />
                  </div>

                  <CardFile
                    hidden={!tramites.newFile.frameIcono}
                    onDrop={(event) => {
                      handleDrop(event, "icono");
                    }}
                    onDragOver={(event) => {
                      handleDragOver(event);
                      handleDragEnter(event, "file");
                    }}
                    onDragEnter={(event) => {
                      handleDragEnter(event, "file");
                    }}
                    onDragLeave={handleDragLeave}
                  >
                    <CardContent
                      onDrop={(event) => {
                        handleDrop(event, "icono");
                      }}
                      onDragOver={handleDragOver}
                      onDragEnter={(event) => {
                        handleDragEnter(event, "content");
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 15, color: colorUI }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Desliza el icono en formato PNG o SVG
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: colorUI, fontWeight: "bold" }}
                      >
                        Icono
                      </Typography>
                    </CardContent>
                  </CardFile>
                  <iframe
                    id="frameicono"
                    src={tramites.newFile.previewIcono}
                    className="preview-iframe"
                    hidden={tramites.newFile.frameIcono}
                  ></iframe>
                </Grid>

                {/* URL VIDEO */}
                <Grid
                  className="d-flex flex-column justify-content-center align-items-center"
                  item
                  xs={2}
                  sm={4}
                  md={4}
                >
                  {inputUrl ? (
                    <TextField
                      id="urlVideo"
                      label="URL Video"
                      fullWidth
                      disabled={
                        newChildren.tipo === "ConfiguradorP" ? true : false
                      }
                      onChange={(e) => {
                        setNewChildren({
                          ...newChildren,
                          urlVideo: e.target.value,
                        });
                      }}
                      value={newChildren.urlVideo}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconUI size={"20px"} color={colorUI}>
                              <LinkIcon />
                            </IconUI>
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  ) : (
                    <>
                      <div style={{ textAlign: "center" }}>
                        <label
                          htmlFor="VideoHijo"
                          style={{
                            backgroundColor: colorUI,
                            color: "white",
                            borderRadius: "12px",
                            cursor: "pointer",
                            padding: "5px 7px",
                            marginBottom: "10px",
                          }}
                        >
                          cargar archivo
                        </label>
                        <input
                          type="file"
                          id="VideoHijo"
                          style={{ display: "none" }}
                          accept="video/mp4"
                          onChange={handleChangeVideo}
                        />
                        <DeleteForeverOutlinedIcon
                          style={{ color: "red", cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => {
                            setTramites({
                              ...tramites,
                              newFile: {
                                ...tramites.newFile,
                                previewVideo: "",
                                frameVideo: true,
                                Archivo: null,
                              },
                            });

                            setNewChildren({ ...newChildren, video: null });
                          }}
                          sx={
                            tramites.newFile.frameVideo
                              ? { display: "none" }
                              : {}
                          }
                        />
                      </div>

                      <CardFile
                        hidden={!tramites.newFile.frameVideo}
                        onDrop={(event) => {
                          handleDrop(event, "video");
                        }}
                        onDragOver={(event) => {
                          handleDragOver(event);
                          handleDragEnter(event, "file");
                        }}
                        onDragEnter={(event) => {
                          handleDragEnter(event, "file");
                        }}
                        onDragLeave={handleDragLeave}
                      >
                        <CardContent
                          onDrop={(event) => {
                            handleDrop(event, "video");
                          }}
                          onDragOver={handleDragOver}
                          onDragEnter={(event) => {
                            handleDragEnter(event, "content");
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 15, color: colorUI }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Desliza el video en formato MP4 (Max 10MB)
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: colorUI, fontWeight: "bold" }}
                          >
                            Video
                          </Typography>
                        </CardContent>
                      </CardFile>
                      <iframe
                        id="framemanual"
                        src={tramites.newFile.previewVideo}
                        className="preview-iframe"
                        hidden={tramites.newFile.frameVideo}
                      ></iframe>
                    </>
                  )}
                  <div className="position-relative w-100 mt-2 d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center gap-2 mt-3">
                      <label className="cursor-pointer" htmlFor="checkUrl">
                        Colocar URL
                      </label>
                      <input
                        onClick={() => setinputUrl(!inputUrl)}
                        className="cursor-pointer"
                        id="checkUrl"
                        name="checkUrl"
                        type="checkbox"
                        value={inputUrl}
                        // checked={newChildren.checkedBoxVideo}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className="container">
            <Grid sx={{ marginTop: 3 }} container spacing={2}>
              <Grid align="center" item xs={12} sm={6} md={6}></Grid>
              <Grid align="right" item xs={12} sm={6} md={6}>
                <div
                  style={{ width: 200 }}
                  className="cta cta--guinda"
                  onClick={handleNuevoTramiteHijo}
                >
                  {" "}
                  {newChildren.editChildren ? "Actualizar" : "Agregar"}
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>

      <Modal
        open={newfather.openModalAgregarPadre}
        onClose={handleCloseAgregarPadre}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="titulo"
                label="Título"
                fullWidth
                onChange={(e) => {
                  setNewFather({ ...newfather, titulo: e.target.value });
                }}
                value={newfather.titulo}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconUI size={"20px"} color={colorUI}>
                        <TitleIcon />
                      </IconUI>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <TextField
                    id="url"
                    label="URL"
                    fullWidth
                    onChange={(e) => {
                      setNewFather({ ...newfather, url: e.target.value });
                    }}
                    value={newfather.url}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconUI size={"20px"} color={colorUI}>
                            <LinkIcon />
                          </IconUI>
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <TextField
                    id="descripcion"
                    label="Descripción"
                    fullWidth
                    onChange={(e) => {
                      setNewFather({
                        ...newfather,
                        descripcion: e.target.value,
                      });
                    }}
                    value={newfather.descripcion}
                    multiline
                    maxRows={3}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconUI size={"20px"} color={colorUI}>
                            <DescriptionIcon />
                          </IconUI>
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <div style={{ textAlign: "center" }}>
                    <label
                      htmlFor="inputActa"
                      style={{
                        backgroundColor: colorUI,
                        color: "white",
                        borderRadius: "12px",
                        cursor: "pointer",
                        padding: "5px 7px",
                        marginBottom: "10px",
                      }}
                    >
                      cargar archivo
                    </label>
                    <input
                      type="file"
                      id="inputActa"
                      style={{ display: "none" }}
                      accept="image/png, .svg"
                      onChange={handleChangeIconoFather}
                    />
                    <DeleteForeverOutlinedIcon
                      style={{ color: "red", cursor: "pointer" }}
                      fontSize="large"
                      onClick={() => {
                        setNewFather({
                          ...newfather,
                          newFile: {
                            ...newfather.newFile,
                            previewicono: "",
                            frameicono: true,
                            Archivo: null,
                          },
                        });
                      }}
                      sx={
                        newfather.newFile.frameicono ? { display: "none" } : {}
                      }
                    />
                  </div>
                  <CardFile
                    hidden={!newfather.newFile.frameicono}
                    onDrop={(event) => {
                      handleDropFather(event, "icono");
                    }}
                    onDragOver={(event) => {
                      handleDragOverFather(event);
                      handleDragEnterFather(event, "file");
                    }}
                    onDragEnter={(event) => {
                      handleDragEnterFather(event, "file");
                    }}
                    onDragLeave={handleDragLeaveFather}
                  >
                    <CardContent
                      onDrop={(event) => {
                        handleDropFather(event, "icono");
                      }}
                      onDragOver={handleDragOver}
                      onDragEnter={(event) => {
                        handleDragEnterFather(event, "content");
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 15, color: colorUI }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Desliza el icono en formato PNG o SVG
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: colorUI, fontWeight: "bold" }}
                      >
                        Icono
                      </Typography>
                    </CardContent>
                  </CardFile>
                  <iframe
                    id="frameiconofather"
                    src={newfather.newFile.previewicono}
                    className="preview-iframe"
                    hidden={newfather.newFile.frameicono}
                  ></iframe>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className="container">
            <Grid sx={{ marginTop: 3 }} container spacing={2}>
              <Grid align="center" item xs={12} sm={6} md={6}></Grid>
              <Grid align="right" item xs={12} sm={6} md={6}>
                <div
                  style={{ width: 200 }}
                  className="cta cta--guinda"
                  onClick={handleNuevoTramite}
                >
                  {" "}
                  {newfather.editFather ? "Actualizar" : "Agregar"}
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ListadeTramites;
