import React, { 
  useContext, 
  useEffect, 
  // useRef, 
  useState 
} from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Button,
  FormHelperText,
} from "@mui/material";
import { services } from "../services/api";
import { styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import GifLoader from "../components/ui/gifLoader.js";
// import { FaFileSignature } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { FaSchool } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import logo4 from "../assets/img/logo4.png";

import ReactRecaptcha3 from "react-google-recaptcha3";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { NotificacionContext } from "../context/notificacion_context";

const colorUI = "#6f1d46";
// const colorUI = "#8d949e";

const CardFile = styled(Card)(({ theme }) => ({
  height: "220px",
  width: "250px",
  margin: "auto",
  marginTop: "20px",
  borderRadius: "12px",
  boxShadow: "1px 0px 7px 0px rgba(0, 0, 0, 0.75)",
  "& .MuiCardContent-root": {
    borderColor: "#6f1d46",
    // borderColor: "#8d949e",
    borderStyle: "dotted",
    height: "80%",
    margin: "20px 20px 20px 20px",
  },
}));

const RegistroCedulas = () => {
  const { folio } = useParams();
  const history = useHistory();
  const [fecha_tit, setFechaTit] = useState(dayjs(new Date()));
  const [correo_dis, setCorreoDis] = useState(false);
  const [fecha_exp, setFechaExp] = useState(dayjs(new Date()));
  const [form, setForm] = useState({
    curp: "",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    calle: "",
    id_colonia: "",
    colonia: "",
    id_entidad_federativa: "",
    entidad_federativa: "",
    telefono: "",
    correo: "",
    codigo_postal: "",
    institucion_educativa: "",
    selectOpcion: "",
    profesion: "",
    numero_cedula: "",
    fecha_titulacion: dayjs(fecha_tit).format("YYYY-MM-DD"),
    fecha_expedicion: dayjs(fecha_exp).format("YYYY-MM-DD"),
    numero: "",
    municipio: "",
    id_localidad: "",
    localidad: "",
    identificacion_archivo_actual: "",
    identificacion_archivo: "",
    nombre_identificacion_archivo: "Identificación Oficial",
    titulo_archivo_actual: "",
    titulo_archivo: "",
    nombre_titulo_archivo: "Título Profesional",
    cedula_archivo_actual: "",
    cedula_archivo: "",
    nombre_cedula_archivo: "Cédula Profesional",
    curp_archivo_actual: "",
    curp_archivo: "",
    nombre_curp_archivo: "CURP",
    estatus: "",
  });

  const [localidades, setLocalidades] = useState([]);
  const [colonias, setColonias] = useState([]);
  const [profesiones, setProfesiones] = useState([]);
  const [instituciones, setInstituciones] = useState([]);
  // const fileIne = useRef(null);
  // const fileTitulo = useRef(null);
  // const fileCedula = useRef(null);
  // const fileCurp = useRef(null);
  const formData = new FormData();
  const [errors, setErrors] = useState({});
  const [observaciones, setObservaciones] = useState({});
  const [cambio, setCambio] = useState(false);

  const [loader, setLoader] = useState({
    loading: false,
  });

  // Documentos
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const { showNotification } = useContext(NotificacionContext);

  const keyFiles = [
    { key: "Titulo", name: "Título, Diploma o Grado Original" },
    { key: "Cedula", name: "Cédula profesional / Acta de titulación" },
    { key: "Curp", name: "Archivo CURP" },
  ];

  const KeyFilesPNG = [
    { key: "IdentificacionOficial", name: "Identificación Oficial" },
  ];

  const [Files, setFiles] = useState({
    IdentificacionOficial: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    Titulo: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    Cedula: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
    Curp: {
      previewicono: "",
      frameicono: true,
      Archivo: null,
    },
  });

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChangeFiles = (name, file) => {
    setFiles({
      ...Files,
      [name]: {
        ...Files[name],
        previewicono: URL.createObjectURL(file),
        frameicono: false,
        Archivo: file,
      },
    });
    setCambio(true);
  };

  const handleDragOverFather = (event) => {
    event.preventDefault();
  };

  const handleDragEnterFather = (event, card) => {
    event.preventDefault();
    setIsDraggingOver(true);
    if (card === "file") {
      event.currentTarget.style.border = `2px dashed ${colorUI}`;
      event.currentTarget.style.filter = "blur(1px)";
    } else if (card === "content") {
    }
  };

  const handleDragLeaveFather = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    if (!isDraggingOver) {
      event.currentTarget.style.border = "none";
      event.currentTarget.style.filter = "none";
    }
  };

  const handleDropFather = (event, name) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const file = event.dataTransfer.files[0];

    handleChangeFiles(name, file);

    event.currentTarget.style.border = "";
    event.currentTarget.style.filter = "";
  };

  const handleEliminarDoc = (key) => {
    swal({
      icon: "warning",
      title: "Alerta",
      text: `Si elimina este archivo, tendrá que seleccionar uno distinto para poder guardar.`,
      buttons: ["Cancelar", "Aceptar"],
      confirmButton: true,
    }).then((result) => {
      if (result) {
        setFiles({
          ...Files,
          [key]: {
            previewicono: "",
            frameicono: true,
            Archivo: null,
          },
        });
      } else {
      }
    });
    setCambio(true);
  };

  /// Fin documentos

  const handleChange = async (e) => {
    setCambio(true);
    let { name, value } = e.target;
    let caracteresPermitidos = "";
    switch (name) {
      case "curp":
        value = value.trim();
        value = value.toUpperCase();
        caracteresPermitidos = /^([A-Z\d]{0,18})$/;
        break;
      case "codigo_postal":
        caracteresPermitidos = /^([0-9]{0,5})$/;
        break;
      case "numero":
        caracteresPermitidos = /^([0-9]{0,5})$/;
        break;
      case "telefono":
        caracteresPermitidos = /^([0-9]{0,10})$/;
        break;
      case "numero_cedula":
        // caracteresPermitidos = /^[a-zA-Z0-9]$/;
        // caracteresPermitidos = /^[a-zA-Z0-9]{0,20}$/;
        caracteresPermitidos = /^[a-zA-Z0-9]*$/;
        break;
      case "correo":
        // caracteresPermitidos =
        //   /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        caracteresPermitidos = /^[a-zA-Z0-9._%-@]*$/;
        break;

      // VALIDACION CARACTERES ESPECIALES - LUIS ADRIAN
      case "nombre":
        caracteresPermitidos = /^[a-zA-Z\s]*$/;
        break;
      case "apellido_paterno":
        caracteresPermitidos = /^[a-zA-Z\s]*$/;
        break;
      case "apellido_materno":
        caracteresPermitidos = /^[a-zA-Z\s]*$/;
        break;
      default:
        break;
    }

    if (caracteresPermitidos && !caracteresPermitidos.test(value)) {
      setErrors({
        ...errors,
        [name]: "El valor contiene caracteres no permitidos",
      });
      return;
    } else {
      let newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }

    if (name === "identificacion_archivo") {
      setForm({
        ...form,
        identificacion_archivo: e.target.files[0],
        nombre_identificacion_archivo: e.target.files[0].name,
      });
      return;
    }

    if (name === "numero_cedula") {
      if (value.length < 21) {
        setForm({
          ...form,
          numero_cedula: value,
        });
        return;
      }
      return;
    }

    if (name === "titulo_archivo") {
      setForm({
        ...form,
        titulo_archivo: e.target.files[0],
        nombre_titulo_archivo: e.target.files[0].name,
      });
      return;
    }

    if (name === "cedula_archivo") {
      setForm({
        ...form,
        cedula_archivo: e.target.files[0],
        nombre_cedula_archivo: e.target.files[0].name,
      });
      return;
    }
    if (name === "curp_archivo") {
      setForm({
        ...form,
        curp_archivo: e.target.files[0],
        nombre_curp_archivo: e.target.files[0].name,
      });
      return;
    }

    if (name === "curp") {
      if (caracteresPermitidos.test(value)) {
        if (value.length === 18) {
          setLoader({ loading: true });

          let recaptcha_token = "";

          await ReactRecaptcha3.getToken().then(
            (token) => {
              recaptcha_token = token;
            },
            (error) => {
              console.log(error);
            }
          );

          const dataCarpetas = await services(
            "POST",
            `catalogos/consultaCurp/${value}`,
            { recaptcha_token: recaptcha_token }
          );
          if (dataCarpetas.status !== 404) {
            setForm({
              ...form,
              curp: value,
              nombre: dataCarpetas.data.nombres,
              apellido_paterno: dataCarpetas.data.primerApellido,
              apellido_materno: dataCarpetas.data.segundoApellido,
            });

            showNotification(
              "success",
              "CURP Encontrada",
              "La CURP ha sido encontrada éxitosamete",
              null,
              4
            );

            let newErrors = { ...errors }; // Hacemos una copia del state actual
            delete newErrors.curp; // Eliminamos el atributo 'curp'
            setErrors(newErrors);
            setLoader({ loading: false });
            return;
          } else {
            showNotification(
              "error",
              "CURP No encontrada",
              "No se ha podido encontrar la CURP",
              null,
              4
            );
            setForm({
              ...form,
              [name]: value,
              nombre: "",
              apellido_paterno: "",
              apellido_materno: "",
            });
            setErrors({
              ...errors,
              curp: dataCarpetas.mensaje,
            });
          }
          setLoader({ loading: false });
          return;
        }
        setForm({
          ...form,
          [name]: value,
        });
      }
      setErrors({
        ...errors,
        curp: "El formato no es correcto",
      });
      return false;
    }

    if (name === "codigo_postal") {
      if (caracteresPermitidos.test(value)) {
        if (value.length === 5) {
          setLoader({ loading: true });
          const dataCarpetas = await services(
            "POST",
            "catalogos/codigoPostal",
            { cp: value }
          );
          if (dataCarpetas.data) {
            setForm({
              ...form,
              codigo_postal: value,
              entidad_federativa: dataCarpetas.data.Estado[0].DESCRIPCION,
              id_entidad_federativa: dataCarpetas.data.Estado[0].IDENTIFICADOR,
              municipio: dataCarpetas.data.Municipio[0].DESCRIPCION,
              id_municipio: dataCarpetas.data.Municipio[0].IDENTIFICADOR,
            });
            setLocalidades({
              ...localidades,
              localidades: dataCarpetas.data.Localidad,
            });
            setColonias({
              ...colonias,
              colonias: dataCarpetas.data.Colonia,
            });
            setLoader({ loading: false });
            return;
          }
          setForm({
            ...form,
            [name]: value,
          });
          setErrors({
            ...errors,
            codigo_postal: dataCarpetas.error,
          });
          setLoader({ loading: false });
          return;
        }
        setErrors({
          ...errors,
          codigo_postal: "Debe de tener 5 dígitos",
        });
        setForm({
          ...form,
          [name]: value,
        });
      }
      return false;
    }

    if (name === "numero") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
      }
      return;
    }

    if (name === "telefono") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
        if (value.length === 10) {
          let newErrors = { ...errors };
          delete newErrors.telefono;
          setErrors(newErrors);
        } else {
          setErrors({
            ...errors,
            telefono: "El número telefónico debe tener 10 dígitos",
          });
        }
      }
      return;
    }

    if (name === "correo") {
      if (caracteresPermitidos.test(value)) {
        setForm({
          ...form,
          [name]: value,
        });
        let newErrors = { ...errors };
        delete newErrors.correo;
        setErrors(newErrors);
        return;
      }
      setForm({
        ...form,
        [name]: value,
      });
      setErrors({
        ...errors,
        correo: "No cumple con el formato debido",
      });
      return;
    }

    if (name === "colonia") {
      const [identificador, descripcion] = value.split("-");
      setForm({
        ...form,
        id_colonia: identificador,
        colonia: descripcion,
      });
      return;
    }

    if (name === "localidad") {
      const [identificador, descripcion] = value.split("-");
      setForm({
        ...form,
        id_localidad: identificador,
        localidad: descripcion,
      });
      return;
    }

    if (name === "identificacion") {
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const obtenerCatalogos = async (e) => {
    const dataProfesiones = await services("POST", "catalogos/profesiones", {});
    const dataInstitusiones = await services(
      "POST",
      "catalogos/instituciones",
      {}
    );
    setProfesiones({
      ...profesiones,
      profesion: dataProfesiones.data[0],
    });
    setInstituciones({
      ...instituciones,
      instituciones: dataInstitusiones.data,
    });
  };

  const handleFechaTit = (e) => {
    const value = e.$d;
    setFechaTit(e);
    setForm({
      ...form,
      fecha_titulacion: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFechaExp = (e) => {
    const value = e.$d;
    setFechaExp(e);
    setForm({
      ...form,
      fecha_expedicion: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  // const VisuallyHiddenInput = styled("input")({
  //   clip: "rect(0 0 0 0)",
  //   clipPath: "inset(50%)",
  //   height: 1,
  //   overflow: "hidden",
  //   position: "absolute",
  //   bottom: 0,
  //   left: 0,
  //   whiteSpace: "nowrap",
  //   width: 1,
  // });

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#005cbf"),
    backgroundColor: colorUI,
    "&:hover": {
      backgroundColor: colorUI,
    },
  }));

  // const verificar = async () => {
  //   if (form.curp === "") {
  //     setErrors({
  //       ...errors,
  //       curp: "Este campo debe de estar lleno",
  //     });
  //     return;
  //   }
  // };

  const validarEstado = async () => {
    if (form.estatus === "Rechazada") {
      window.location.href = "/";
    } else {
      console.log("ok");
    }
  };

  const guardar = async () => {
    // console.log('aaaaa', form.calle);
    setLoader({ loading: true });
    formData.append("curp", form.curp);
    formData.append("nombre", form.nombre);
    formData.append("apellido_Pat", form.apellido_paterno);
    formData.append("apellido_Mat", form.apellido_materno);
    formData.append("calle", form.calle);
    formData.append("id_colonia", form.id_colonia);
    formData.append("colonia", form.colonia);
    formData.append("entidad_fede", form.entidad_federativa);
    formData.append("id_entidad_fede", form.id_entidad_federativa);
    formData.append("telefono", form.telefono);
    formData.append("email", form.correo);
    formData.append("codigo_Post", form.codigo_postal);

    formData.append("institucion", form.institucion_educativa);
    formData.append("profesion", form.profesion);
    formData.append("numero_cedula", form.numero_cedula);
    formData.append("fecha_titulacion", form.fecha_titulacion);
    formData.append("fecha_expedicion", form.fecha_expedicion);

    formData.append("id_localidad", form.id_localidad);
    formData.append("localidad", form.localidad);
    formData.append("no_ext", form.numero);
    formData.append("municipio", form.municipio);

    if (folio) {
      // Documentos

      if (Files.IdentificacionOficial.Archivo) {
        formData.append(
          "identificacion_archivo",
          Files.IdentificacionOficial.Archivo
        );
      }
      if (Files.Titulo.Archivo) {
        formData.append("titulo_archivo", Files.Titulo.Archivo);
      }
      if (Files.Cedula.Archivo) {
        formData.append("cedula_archivo", Files.Cedula.Archivo);
      }
      if (Files.Curp.Archivo) {
        formData.append("curp_archivo", Files.Curp.Archivo);
      }

      formData.append("folio", folio);

      let recaptcha_token = "";

      await ReactRecaptcha3.getToken().then(
        (token) => {
          // console.log(token);

          recaptcha_token = token;
          // send token with form data
          // dataToSend.token = token
          // fetch(url, { method: 'POST', body: JSON.stringify(dataToSend) })
        },
        (error) => {
          // handle error here
          console.log(error);
        }
      );

      formData.append("recaptcha_token", recaptcha_token);

      if (
        Files.Titulo.frameicono ||
        Files.Cedula.frameicono ||
        Files.Curp.frameicono
      ) {
        swal("Error", "Todos los archivos son requeridos", "info");
        setLoader({ loading: false });
        // console.log("vasios");
      } else if (!cambio) {
        swal("Información", "No se ha modificado ningún campo", "info");
        setLoader({ loading: false });
      } else {
        const dataCarpetas = await services(
          "POST",
          "see/profesiones/actualizaRegistroTituloRechazado",
          formData
        );

        setLoader({ loading: false });
        if (dataCarpetas.data) {
          swal("¡Éxito!", dataCarpetas.data.mensaje, "success");
          history.replace("/educacion/titulos");
        } else {
          swal("¡Error!", dataCarpetas.error, "error");
          history.replace("/educacion/titulos");
          // Swal.fire({
          //   title: 'Error!',
          //   text: dataCarpetas.error,
          //   icon: 'error',
          //   confirmButtonText: 'Ok'
          // })
        }
      }
    } else {
      let recaptcha_token = "";

      await ReactRecaptcha3.getToken().then(
        (token) => {
          // console.log(token);

          recaptcha_token = token;
          // send token with form data
          // dataToSend.token = token
          // fetch(url, { method: 'POST', body: JSON.stringify(dataToSend) })
        },
        (error) => {
          // handle error here
          console.log(error);
        }
      );

      formData.append("recaptcha_token", recaptcha_token);

      // Documentos
      formData.append("curp_archivo", Files.Curp.Archivo);
      formData.append(
        "identificacion_archivo",
        Files.IdentificacionOficial.Archivo
      );
      formData.append("cedula_archivo", Files.Cedula.Archivo);
      formData.append("titulo_archivo", Files.Titulo.Archivo);

      const dataCarpetas = await services(
        "POST",
        "see/profesiones/registroTitulo",
        formData
      );

      setLoader({ loading: false });
      if (dataCarpetas.status === 200) {
        swal("¡Éxito!", dataCarpetas.data.mensaje, "success").then((result) => {
          setForm({
            selectOpcion: "",
            curp: "",
            nombre: "",
            apellido_paterno: "",
            apellido_materno: "",
            calle: "",
            id_colonia: "",
            colonia: "",
            id_entidad_federativa: "",
            entidad_federativa: "",
            telefono: "",
            correo: "",
            codigo_postal: "",
            institucion_educativa: "",
            profesion: "",
            fecha_titulacion: dayjs(fecha_tit).format("YYYY-MM-DD"),
            fecha_expedicion: dayjs(fecha_exp).format("YYYY-MM-DD"),
            numero: "",
            municipio: "",
            id_localidad: "",
            localidad: "",
            identificacion_archivo_actual: "",
            identificacion_archivo: "",
            nombre_identificacion_archivo: "Identificación Oficial",
            titulo_archivo_actual: "",
            titulo_archivo: "",
            nombre_titulo_archivo: "Título Profesional",
            cedula_archivo_actual: "",
            cedula_archivo: "",
            nombre_cedula_archivo: "Cédula Profesional",
            curp_archivo_actual: "",
            curp_archivo: "",
            nombre_curp_archivo: "CURP",
            numero_cedula: "",
          });
          setFiles({
            IdentificacionOficial: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
            Titulo: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
            Cedula: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
            Curp: {
              previewicono: "",
              frameicono: true,
              Archivo: null,
            },
          });
        });
        // console.log("123");
      } else {
        // history.replace("/educacion/titulos")
        swal({
          icon: "error",
          title: "Atención",
          text: `${dataCarpetas.data}`,
        });
        if (
          dataCarpetas.data ===
          "El campo curp ya ha sido registrado anteriormente."
        ) {
          setTimeout(() => {
            window.location.reload(false);
          }, 5000);
        }
      }
    }
  };

  const obtDatMod = async () => {
    setLoader({ loading: true });
    const dataCarpetas = await services("POST", "solicitudes/detalle", {
      folio: folio,
    });

    if (dataCarpetas.data.folio) {
      // console.log(dataCarpetas.data, "hola que onda");
      setForm({
        ...form,
        selectOpcion: {
          id: dataCarpetas.data.data.id_institucion,
          descripcion: `${dataCarpetas.data.data.clave_institucion}-${dataCarpetas.data.data.institucion}`,
        },
        curp: dataCarpetas.data.data.solicitante.curp,
        nombre: dataCarpetas.data.data.solicitante.nombres,
        apellido_paterno: dataCarpetas.data.data.solicitante.apellido_Pat,
        apellido_materno: dataCarpetas.data.data.solicitante.apellido_Mat,
        identificacion_archivo_actual:
          dataCarpetas.data.data.solicitante.identificacion_archivo,
        telefono: dataCarpetas.data.data.solicitante.telefono,
        correo: dataCarpetas.data.data.solicitante.email,
        calle: dataCarpetas.data.data.solicitante.calle,
        numero: dataCarpetas.data.data.solicitante.no_ext,
        codigo_postal: dataCarpetas.data.data.solicitante.codigo_Post,
        id_entidad_federativa:
          dataCarpetas.data.data.solicitante.id_entidad_fede,
        entidad_federativa: dataCarpetas.data.data.solicitante.entidad_fede,
        id_colonia: dataCarpetas.data.data.solicitante.id_colonia,
        colonia: dataCarpetas.data.data.solicitante.colonia,
        id_localidad: dataCarpetas.data.data.solicitante.id_localidad,
        localidad: dataCarpetas.data.data.solicitante.localidad,
        institucion_educativa: dataCarpetas.data.data.id_institucion,
        profesion: dataCarpetas.data.data.profesion,
        numero_cedula: dataCarpetas.data.data.numero_cedula,
        titulo_archivo_actual: dataCarpetas.data.data.titulo_archivo,
        cedula_archivo_actual: dataCarpetas.data.data.cedula_archivo,
        curp_archivo_actual: dataCarpetas.data.data.solicitante.curp_archivo,
        fecha_titulacion: dataCarpetas.data.data.fecha_titulacion,
        fecha_expedicion: dataCarpetas.data.data.fecha_expedicion,
        estatus: dataCarpetas.data.estatus,
        municipio: dataCarpetas.data.data.solicitante.municipio,
      });

      setFiles({
        IdentificacionOficial: {
          previewicono:
            dataCarpetas.data.data.solicitante.identificacion_archivo,
          frameicono: dataCarpetas.data.data.solicitante.identificacion_archivo
            ? false
            : true,
          Archivo: null,
        },
        Titulo: {
          previewicono: dataCarpetas.data.data.titulo_archivo,
          frameicono: dataCarpetas.data.data.titulo_archivo ? false : true,
          Archivo: null,
        },
        Cedula: {
          previewicono: dataCarpetas.data.data.cedula_archivo,
          frameicono: dataCarpetas.data.data.cedula_archivo ? false : true,
          Archivo: null,
        },
        Curp: {
          previewicono: dataCarpetas.data.data.solicitante.curp_archivo,
          frameicono: dataCarpetas.data.data.solicitante.curp_archivo
            ? false
            : true,
          Archivo: null,
        },
      });
      validarEstado();
      setFechaExp(dayjs(dataCarpetas.data.data.fecha_expedicion));
      setFechaTit(dayjs(dataCarpetas.data.data.fecha_titulacion));
      const dataCat = await services("POST", "catalogos/codigoPostal", {
        cp: dataCarpetas.data.data.solicitante.codigo_Post,
      });

      setLocalidades({
        ...localidades,
        localidades: dataCat.data.Localidad,
      });
      setColonias({
        ...colonias,
        colonias: dataCat.data.Colonia,
      });

      setObservaciones({
        ...observaciones,
        mensaje: dataCarpetas.data.observaciones,
      });
    } else {
      // window.location.href='/';
    }
    setLoader({ loading: false });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_TOKEN).then(
        (status) => {
          // status: success/error
          // success - script is loaded and greaptcha is ready
          // error - script is not loaded
          // console.log(status);
        }
      );
      setLoader({ loading: true });
      obtenerCatalogos();
      setLoader({ loading: false });
    }
    if (folio) {
      obtDatMod();
      setCorreoDis(true);
    }
    // eslint-disable-next-line
  }, []);

  // VALIDAR CUANDO SE PIERDE EL FOCO DEL COFFEO
  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === "correo") {
      const correoRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!correoRegex.test(value)) {
        setErrors({
          ...errors,
          correo: "No cumple con el formato debido",
        });
      } else {
        let newErrors = { ...errors };
        delete newErrors.correo;
        setErrors(newErrors);
      }
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 my-4">
        <img
          className="imgLogo4"
          alt="Imagen Decorativa"
          src={logo4}
          style={{
            width: "120px",
            Height: "120px",
            marginRight: "20px",
          }}
        />
        <h3 className="h4 text-center text-vinoGob text-bold">
          Solicitud de Registro de Título Profesional
        </h3>
      </div>
      {/* <h3 className="content_title">
        <FaFileSignature />
        <span> Solicitud de Registro de Título Profesional</span>
      </h3> */}

      {observaciones.mensaje && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <p style={{ color: "red", fontSize: 20 }}>
            Observaciones: {observaciones.mensaje}
          </p>
        </div>
      )}
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          <FaUser />
          <span> Información Personal</span>
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto" }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    label="CURP"
                    disabled={correo_dis}
                    variant="standard"
                    id="curp"
                    name="curp"
                    value={form.curp}
                    onChange={handleChange}
                    helperText={errors.curp}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Nombre"
                    variant="standard"
                    id="nombre"
                    name="nombre"
                    value={form.nombre}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Apellido Paterno"
                    variant="standard"
                    id="apellido_paterno"
                    name="apellido_paterno"
                    value={form.apellido_paterno}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Apellido Materno"
                    variant="standard"
                    id="apellido_materno"
                    name="apellido_materno"
                    value={form.apellido_materno}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Teléfono"
                    variant="standard"
                    id="telefono"
                    name="telefono"
                    value={form.telefono}
                    onChange={handleChange}
                    helperText={errors.telefono}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Correo"
                    disabled={correo_dis}
                    variant="standard"
                    id="correo"
                    name="correo"
                    value={form.correo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.correo}
                  />
                </FormControl>
              </Grid>

              {KeyFilesPNG.map((item) => {
                return (
                  <Grid item xs={12} sm={8} md={4}>
                    <Grid
                      container
                      spacing={1}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <CardFile
                        hidden={!Files[item.key].frameicono}
                        onDrop={(event) => {
                          handleDropFather(event, item.key);
                        }}
                        onDragOver={(event) => {
                          handleDragOverFather(event);
                          handleDragEnterFather(event, "file");
                        }}
                        onDragEnter={(event) => {
                          handleDragEnterFather(event, "file");
                        }}
                        onDragLeave={handleDragLeaveFather}
                      >
                        <CardContent
                          onDrop={(event) => {
                            handleDropFather(event, item.key);
                          }}
                          onDragOver={handleDragOver}
                          onDragEnter={(event) => {
                            handleDragEnterFather(event, "content");
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 15, color: colorUI }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Desliza tu identificación en formato jpg, png o jepg
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: colorUI, fontWeight: "bold" }}
                          >
                            {item.name}
                          </Typography>
                        </CardContent>
                      </CardFile>
                      {Files[item.key].frameicono ? null : (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          {/* Este es tuyo tebitan */}
                          {/* <label style={{ backgroundColor: colorUI, color: 'white', borderRadius: '12px', padding: '5px 7px', marginBottom: '10px' }}>{Files[item.key].Archivo.name}</label> */}
                          <label
                            style={{
                              backgroundColor: "white",
                              color: colorUI,
                              borderRadius: "12px",
                              padding: "5px 7px",
                              marginBottom: "10px",
                            }}
                          >
                            {item.name}
                          </label>
                        </div>
                      )}
                      <iframe
                        id="frameiconoFilesx"
                        src={Files[item.key].previewicono}
                        title="Previsualizador"
                        className="preview-iframe"
                        hidden={Files[item.key].frameicono}
                      ></iframe>
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <label
                          htmlFor={`input${item.key}`}
                          style={{
                            backgroundColor: colorUI,
                            color: "white",
                            borderRadius: "12px",
                            cursor: "pointer",
                            padding: "5px 7px",
                            marginBottom: "10px",
                          }}
                        >
                          cargar archivo
                        </label>
                        <input
                          type="file"
                          id={`input${item.key}`}
                          style={{ display: "none" }}
                          accept=".jpg, .png, .jepg"
                          onChange={(e) =>
                            handleChangeFiles(item.key, e.target.files[0])
                          }
                        />
                        <DeleteForeverOutlinedIcon
                          style={{ color: "red", cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => {
                            handleEliminarDoc(item.key);
                          }}
                          sx={
                            Files[item.key].frameicono
                              ? { display: "none" }
                              : {}
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          <FaHome />
          <span> Domicilio Particular</span>
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Calle"
                    variant="standard"
                    id="calle"
                    name="calle"
                    value={form.calle}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={1}>
                <FormControl fullWidth>
                  <TextField
                    label="Número"
                    variant="standard"
                    id="numero"
                    name="numero"
                    value={form.numero}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={1}>
                <FormControl fullWidth>
                  <TextField
                    label="Código postal"
                    variant="standard"
                    id="codigo_postal"
                    name="codigo_postal"
                    value={form.codigo_postal}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    label="Municipio"
                    variant="standard"
                    id="municipio"
                    name="municipio"
                    value={form.municipio}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    label="Entidad Federativa"
                    variant="standard"
                    id="entidad_federativa"
                    name="entidad_federativa"
                    value={form.entidad_federativa}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-colonia">Colonia</InputLabel>
                  <Select
                    labelId="demo-colonia"
                    id="colonia"
                    name="colonia"
                    value={`${form.id_colonia}-${form.colonia}`}
                    label="Colonia"
                    onChange={handleChange}
                  >
                    {colonias.colonias?.map((item) => (
                      <MenuItem
                        value={`${item.IDENTIFICADOR}-${item.DESCRIPCION}`}
                      >
                        {item.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.colonia}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-localidad">Localidad</InputLabel>
                  <Select
                    labelId="demo-localidad"
                    id="localidad"
                    name="localidad"
                    value={`${form.id_localidad}-${form.localidad}`}
                    label="Localidad"
                    onChange={handleChange}
                  >
                    {localidades.localidades?.map((item) => (
                      <MenuItem
                        value={`${item.IDENTIFICADOR}-${item.DESCRIPCION}`}
                      >
                        {item.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.localidad}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <div className="container my-3">
        <div className="border-bottom--guinda text-big mb-2 text-bold">
          <FaSchool />
          <span> Datos Escolares</span>
        </div>
      </div>
      <Card sx={{ width: "85%", margin: "auto", marginTop: 2 }}>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Profesión"
                    helperText="El nombre de la profesión debe ser idéntico al que se presenta en su título"
                    variant="standard"
                    id="profesion"
                    name="profesion"
                    value={form.profesion}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Número de cédula"
                    variant="standard"
                    id="numero_cedula"
                    name="numero_cedula"
                    value={form.numero_cedula}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={6}
                sx={{ paddingTop: "2% !important" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="DD/MM/YYYY"
                      id="fecha_titulacion"
                      name="fecha_titulacion"
                      value={fecha_tit}
                      label="Fecha de titulación"
                      onChange={handleFechaTit}
                      disableFuture="true"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={6}
                sx={{ paddingTop: "2% !important" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      variant="standard"
                      format="DD/MM/YYYY"
                      id="fecha_expedicion"
                      name="fecha_expedicion"
                      value={fecha_exp}
                      label="Fecha de expedición de título"
                      onChange={handleFechaExp}
                      disableFuture="true"
                    />
                    {/* Aqui hay que arreglar */}
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              {keyFiles.map((item) => {
                return (
                  <Grid
                    item
                    xs={folio ? 12 : 12}
                    md={folio ? 4 : 4}
                    sm={folio ? 4 : 4}
                  >
                    <Grid
                      container
                      spacing={1}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <CardFile
                        hidden={!Files[item.key].frameicono}
                        onDrop={(event) => {
                          handleDropFather(event, item.key);
                        }}
                        onDragOver={(event) => {
                          handleDragOverFather(event);
                          handleDragEnterFather(event, "file");
                        }}
                        onDragEnter={(event) => {
                          handleDragEnterFather(event, "file");
                        }}
                        onDragLeave={handleDragLeaveFather}
                      >
                        <CardContent
                          onDrop={(event) => {
                            handleDropFather(event, item.key);
                          }}
                          onDragOver={handleDragOver}
                          onDragEnter={(event) => {
                            handleDragEnterFather(event, "content");
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 15, color: colorUI }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Desliza el Documento en formato PDF
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: colorUI, fontWeight: "bold" }}
                          >
                            {item.name}
                          </Typography>
                        </CardContent>
                      </CardFile>
                      {Files[item.key].frameicono ? null : (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          {/* ESTE es tuyo Tebitan */}
                          {/* <label style={{ backgroundColor: colorUI, color: 'white', borderRadius: '12px', padding: '5px 7px', marginBottom: '10px' }}>{Files[item.key].Archivo.name}</label> */}
                          <label
                            style={{
                              backgroundColor: "white",
                              color: colorUI,
                              borderRadius: "12px",
                              padding: "5px 7px",
                              marginBottom: "10px",
                            }}
                          >
                            {item.name}
                          </label>
                        </div>
                      )}
                      <iframe
                        id="frameiconoFiles"
                        src={Files[item.key].previewicono}
                        title="Previsualizador"
                        className="preview-iframe"
                        hidden={Files[item.key].frameicono}
                      ></iframe>
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <label
                          htmlFor={`input${item.key}`}
                          style={{
                            backgroundColor: colorUI,
                            color: "white",
                            borderRadius: "12px",
                            cursor: "pointer",
                            padding: "5px 7px",
                            marginBottom: "10px",
                          }}
                        >
                          cargar archivo
                        </label>
                        <input
                          type="file"
                          id={`input${item.key}`}
                          style={{ display: "none" }}
                          accept=".pdf"
                          onChange={(e) =>
                            handleChangeFiles(item.key, e.target.files[0])
                          }
                        />
                        <DeleteForeverOutlinedIcon
                          style={{ color: "red", cursor: "pointer" }}
                          fontSize="large"
                          onClick={() => {
                            handleEliminarDoc(item.key);
                          }}
                          sx={
                            Files[item.key].frameicono
                              ? { display: "none" }
                              : {}
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
              <div className="container my-3">
                <div className="text-medium mb-2 mt-2 text-bold">
                  Nota importante: El día que asista a su cita recuerde
                  presentar físicamente en ORIGINAL y COPIA los documentos que
                  guardó en esta solicitud.
                </div>
              </div>
              {/* 
              <Grid item xs={folio ? 12 : 12} md={folio ? 4 : 6} sm={folio ? 4 : 6}>
                <Grid container spacing={1} sx={{ alignItems: 'flex-end' }}>
                  {folio && <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center' }}><Typography>Título Profesional</Typography></Grid>}
                  <Grid item xs={6} md={6}>
                    {folio ? <Link href={form.titulo_archivo_actual} target="_blank" rel="noopener noreferrer"><ColorButton>Archivo actual</ColorButton></Link> : <TextField fullWidth disabled label={form.nombre_titulo_archivo + ":"} variant="standard" />}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <ColorButton component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                      {folio ? 'Nuevo' : 'Título Profesional:'}
                      <VisuallyHiddenInput name="titulo_archivo" onChange={handleChange} type="file" accept='.pdf' ref={fileTitulo} />
                    </ColorButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={folio ? 12 : 12} md={folio ? 4 : 6} sm={folio ? 4 : 6} >
                <Grid container spacing={1} sx={{ alignItems: 'flex-end' }}>
                  {folio && <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center' }}><Typography>Cédula Profesional</Typography></Grid>}
                  <Grid item xs={6} md={6}>
                    {folio ? <Link href={form.cedula_archivo_actual} target="_blank" rel="noopener noreferrer"><ColorButton>Archivo actual</ColorButton></Link> : <TextField disabled label={form.nombre_cedula_archivo + ":"} variant="standard" />}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <ColorButton component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                      {folio ? 'Nuevo' : 'Cédula Profesional:'}
                      <VisuallyHiddenInput name="cedula_archivo" onChange={handleChange} type="file" accept='.pdf' ref={fileCedula} />
                    </ColorButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={folio ? 12 : 12} md={folio ? 4 : 6} sm={folio ? 4 : 6} >
                <Grid container spacing={1} sx={{ alignItems: 'flex-end' }}>
                  {folio && <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center' }}><Typography>CURP</Typography></Grid>}
                  <Grid item xs={6} md={6}>
                    {folio ? <Link href={form.curp_archivo_actual} target="_blank" rel="noopener noreferrer"><ColorButton>Archivo actual</ColorButton></Link> : <TextField disabled label={form.nombre_curp_archivo + ":"} variant="standard" />}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <ColorButton component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                      {folio ? 'Nuevo' : 'CURP:'}
                      <VisuallyHiddenInput name="curp_archivo" onChange={handleChange} type="file" accept='.pdf' ref={fileCurp} />
                    </ColorButton>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <ColorButton
        variant="outlined"
        sx={{ display: "flex", margin: "auto", marginTop: 2, marginBottom: 2 }}
        onClick={guardar}
      >
        Guardar
      </ColorButton>

      {loader.loading ? <GifLoader></GifLoader> : null}
    </>
  );
};

export default RegistroCedulas;
