import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import GifLoader from "../../components/ui/gifLoader";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  InputAdornment,
  Tooltip,
  IconButton,
  handleImage,
  Dialog,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Slide from "@mui/material/Slide";
import { NotificacionContext } from "../../context/notificacion_context";
import { services } from "../../services/api";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import swal from "sweetalert";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { useReactToPrint } from "react-to-print";
import logo4 from "../../assets/img/logo4.png";
import imgFolio from "../../assets/img/Folio.jpg";
import { Button } from "react-bootstrap";
import HelpIcon from "@mui/icons-material/Help";

import { Footer } from "../../components/footer_component";

const ValidarDocumentos = () => {
  const { folio } = useParams();

  const [Calificacion, setCalificacion] = useState({
    calificacionValor: 0,
    openModalCali: false,
  });
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    Folio: folio === "Comprobante-Digital" ? "" : folio,
    FolioError: false,
  });
  const [openDialog, setopenDialog] = useState({
    data: [],
    openmodal: false,
  });

  const validarJson = [
    "observaciones",
    "titulo_archivo",
    "cedula_archivo",
    "email",
    "id_institucion",
    "solicitante",
    "updated_at",
    "created_at",
    "id",
    "archivo",
    "CSS_archivo",
    "acta_examen_profesional_archivo",
    "concepto",
  ];

  const notification = useContext(NotificacionContext);

  const theme = createTheme({
    palette: {
      gobierno: {
        main: "#6f1d46",
        // main: "#8d949e",
        contrastText: "#fff",
      },
    },
  });

  const [checked, setchecked] = useState(false);
  const containerRef = useRef(null);
  const componentRef = useRef();

  const styleCalificacion = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    borderRadius: "30px",
    boxShadow: 24,
    p: 4,

    marginBottom: "4%",
  };

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleConsulta = async () => {
    if (state.Folio === "") {
      setState({ ...state, FolioError: true });
      notification.showNotification(
        "warning",
        "Campos Vacios",
        "Por favor rellene los campos",
        null,
        4
      );
    } else {
      setLoading(true);
      let apiValidar = await services("POST", "solicitudes/detalle", {
        folio: state.Folio,
      });
      if (apiValidar.data.error) {
        notification.showNotification(
          "warning",
          "Alerta",
          apiValidar.data.error,
          null,
          4
        );
      } else {
        if (apiValidar.data.length <= 0) {
          swal({
            title: " Folio no valido",
            text: "Este trámite no está dado de alta, acercate a la Secretaría de Finanzas y Administración para que den de alta el nuevo trámite y poder usar el servicio",
            icon: "warning",
            button: "Aceptar",
          });
        } else {
          setopenDialog({
            ...openDialog,
            openmodal: true,
            data: apiValidar.data,
          });
        }

        setState({ ...state, FolioError: false });
      }
      setLoading(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (folio === "Comprobante-Digital") {
      } else {
        handleConsulta();
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const closeModal = () => {
    setopenDialog({ ...openDialog, openmodal: false });

    swal({
      title: "Gracias por usar el servicio ¿Te gustaria calificarlo?",
      icon: "success",
      buttons: ["No", "Si"],
      confirmButton: true,
    }).then((Calificar) => {
      if (Calificar) {
        setCalificacion({ ...Calificacion, openModalCali: true });
      }
    });
    limpiarform();
  };

  const limpiarform = () => {
    setState({ Folio: "", FolioError: false });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const nameFechaExpedicion = {
    CRC: "FECHA DE EXPEDICIÓN DE CÉDULA",
    CRT: "FECHA DE EXPEDICIÓN DE TÍTULO",
  };

  const labelsFechas = ["CRC", "CRT"];

  const [openImgInfo, setopenImgInfo] = useState(false);

  const handleImage = () => {
    setopenImgInfo(true);
    // let image = document.createElement('a')
    // image.setAttribute('href', imgInfo )
    // image.setAttribute('target', '_blank')
    // image.click()
  };

  const iconStyleHelpp = {
    // color: "#6f1d469d",
    color: "#50b69491",
    // color: "#8d949e",
    height: "1.5em",
    width: "1.5em",
  };

  return (
    <>
      {loading && <GifLoader />}
      <div className="container mx-auto px-0">
        <div
          style={{ overflow: "hidden" }}
          ref={containerRef}
          className="h-75 d-flex justify-content-center card mt-3 text-center p-5"
        >
          {!checked && (
            // <Slide
            //   direction="right"
            //   in={!checked}
            //   container={containerRef.current}
            // >
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2">
                <img
                  className="imgLogo4"
                  alt="Imagen Decorativa"
                  src={logo4}
                  style={{
                    width: "150px",
                    Height: "150px",
                    marginRight: "20px",
                  }}
                />
                <span className="h3 text-vinoGob text-bold navidad">
                  Verificador de <br className="d-md-none" /> Comprobantes
                  Digitales
                </span>
              </div>

                <FormControl
                  sx={{
                    marginTop: 3,
                    width: 400,
                    maxWidth: "100%",
                  }}
                >
                  <InputLabel htmlFor="my-input">Folio</InputLabel>
                  <Input
                    error={state.FolioError}
                    value={state.Folio}
                    onChange={(event) => {
                      setState({ ...state, Folio: event.target.value });
                    }}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    id="folio"
                    name="folio-input"
                    aria-describedby="ingresa tu folio aqui"
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title="¿De dónde obtengo este dato? Haz clic en el icono">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleImage}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="my-helper-text">
                    Ingresa tu folio.
                  </FormHelperText>
                </FormControl>

              <div
                className="cta cta--guinda mt-2"
                onClick={() => {
                  handleConsulta();
                }}
              >
                Verificar Comprobante
              </div>
            </div>
            // </Slide>
          )}
        </div>
      </div>

      <div className="mt-4 container card p-5">
        <h4 className="h5 text-start mb-0 fw-bold text-vinoGob">
          Listado de trámites que pueden verificarse:
        </h4>
        <ul className="text-start mt-4 p-0">
          <li className=" ms-2 p-2 ">
            1. Solicitud de Constancia de Interés Particular
          </li>
          <li className=" ms-2 p-2 ">
            2. Solicitud del Impuesto Estatal sobre Nómina y Hospedaje
          </li>
          <li className=" ms-2 p-2 ">3. Constancia de registro de cédula</li>
          <li className=" ms-2 p-2 ">4. Registro de Título</li>
          <li className=" ms-2 p-2 ">5. Solicitud de Cédulas Provisionales</li>
        </ul>
      </div>

      <ThemeProvider theme={theme}>
        <Modal
          open={openDialog.openmodal}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalVerificarCertificado">
            <Box sx={{ width: "100%", marginBottom: "3%" }} ref={componentRef}>
              <div
                className="pt-2 border-bottom--guinda text-big mb-2 text-bold"
                style={{ marginBottom: "3% !important" }}
              >
                CERTIFICADO VERIFICADO
              </div>
              {/* <div className="container d-flex justify-content-center align-items-center"> */}
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                columns={{ xs: 1, sm: 2, md: 2 }}
              >
                {Object.keys(openDialog.data).map((item, index) =>
                  item === "data" ? (
                    Object.keys(openDialog.data.data).map((subObject) =>
                      validarJson.includes(subObject) ? (
                        <></>
                      ) : openDialog.data.data[subObject] !== undefined &&
                        openDialog.data.data[subObject] !== null &&
                        openDialog.data.data[subObject] !== "" ? (
                        <Grid
                          item
                          xs={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            className="d-flex flex-column flex-lg-row justify-content-center align-items-center"
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            columns={{ xs: 4, sm: 4, md: 4 }}
                          >
                            <Grid
                              item
                              className="text-center mt-2"
                              // xs={2}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <p
                                className="text-center text-md-start mt-4"
                                style={{
                                  lineBreak: "anywhere",
                                }}
                              >
                                {" "}
                                <Chip
                                  className="ChipsVerificar"
                                  label={
                                    labelsFechas.includes(
                                      openDialog.data.concepto
                                    ) && subObject === "fecha_expedicion"
                                      ? nameFechaExpedicion[
                                          openDialog.data.concepto
                                        ]
                                      : subObject
                                          .toUpperCase()
                                          .replaceAll("_", " ")
                                  }
                                  color="gobierno"
                                />{" "}
                              </p>
                            </Grid>
                            <Grid
                              item
                              className="d-flex d-lg-block justify-content-center align-items-center mt-4"
                              xs={2}
                            >
                              <p
                                className="text-center text-lg-start"
                                style={{
                                  lineBreak: "anywhere",
                                  textAlign: "center",
                                }}
                              >
                                {openDialog.data.data[subObject]}{" "}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )
                    )
                  ) : item === "fecha" ? (
                    openDialog.data.tipo ===
                    "Constancia  De Registro De Cédula" ? (
                      <>
                        <Grid
                          item
                          xs={1}
                          alignItems="left"
                          justifyContent="left"
                        >
                          <Grid
                            container
                            alignItems="left"
                            justifyContent="left"
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            columns={{ xs: 1, sm: 2, md: 2 }}
                            className="d-flex flex-column flex-lg-row justify-content-center align-items-center"
                          >
                            <Grid
                              item
                              className="d-flex justify-content-center align-items-center mt-4"
                              // xs={1}
                            >
                              <p
                                className="text-center text-md-start"
                                style={{
                                  textAlign: "left",
                                  lineBreak: "anywhere",
                                }}
                              >
                                <Chip
                                  className="ChipsVerificar"
                                  label={"NOMBRE"}
                                  color="gobierno"
                                />{" "}
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className="d-flex d-md-block justify-content-center align-items-center mt-4"
                              alignItems="left"
                              justifyContent="left"
                            >
                              <p
                                className="text-center text-md-start"
                                style={{
                                  textAlign: "left",
                                  lineBreak: "anywhere",
                                }}
                              >
                                {" "}
                                {openDialog.data.data.solicitante.nombre
                                  .toUpperCase()
                                  .replaceAll("_", " ")}{" "}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          alignItems="left"
                          justifyContent="left"
                        >
                          <Grid
                            container
                            className="d-flex flex-column flex-lg-row justify-content-center align-items-center"
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            columns={{ xs: 1, sm: 2, md: 2 }}
                          >
                            <Grid
                              className="d-flex justify-content-center align-items-center mt-4"
                              item
                              // xs={1}
                            >
                              <p
                                className="text-center text-md-start"
                                style={{
                                  textAlign: "left",
                                  lineBreak: "anywhere",
                                }}
                              >
                                {" "}
                                <Chip
                                  className="ChipsVerificar text-center"
                                  label={item
                                    .toUpperCase()
                                    .replaceAll("_", " ")}
                                  color="gobierno"
                                />{" "}
                              </p>
                            </Grid>
                            <Grid
                              item
                              className="d-flex d-md-block justify-content-center align-items-center mt-4"
                              xs={1}
                              alignItems="left"
                              justifyContent="left"
                            >
                              <p
                                className="text-center text-md-start"
                                style={{
                                  textAlign: "left",
                                  lineBreak: "anywhere",
                                }}
                              >
                                {" "}
                                {openDialog.data[item] !== undefined &&
                                openDialog.data[item] !== null
                                  ? new Date(
                                      openDialog.data[item]
                                    ).toLocaleDateString("es-mx", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })
                                  : "N/A"}{" "}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={1}>
                        <Grid
                          container
                          className="d-flex flex-column flex-lg-row justify-content-center align-items-center mt-4"
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          columns={{ xs: 1, sm: 2, md: 2 }}
                        >
                          <Grid
                            item
                            // className="d-flex justify-content-center align-items-center"
                            // xs={1}
                          >
                            <p
                              className="text-center text-md-start "
                              style={{
                                textAlign: "left",
                                lineBreak: "anywhere",
                              }}
                            >
                              {" "}
                              <Chip
                                className="ChipsVerificar"
                                label={item.toUpperCase().replaceAll("_", " ")}
                                color="gobierno"
                              />{" "}
                            </p>
                          </Grid>
                          <Grid
                            className="d-flex d-md-block justify-content-center align-items-center mt-4"
                            item
                            xs={1}
                            alignItems="left"
                            justifyContent="left"
                          >
                            <p
                              className="text-center text-md-start"
                              style={{
                                textAlign: "left",
                                lineBreak: "anywhere",
                              }}
                            >
                              {" "}
                              {openDialog.data[item] !== undefined &&
                              openDialog.data[item] !== null
                                ? new Date(
                                    openDialog.data[item]
                                  ).toLocaleDateString("es-mx", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })
                                : "N/A"}{" "}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  ) : item === "observaciones" || item === "email" ? (
                    <></>
                  ) : openDialog.data[item] !== undefined &&
                    openDialog.data[item] !== null &&
                    openDialog.data[item] !== "" ? (
                    <Grid item xs={1}>
                      <Grid
                        className="d-flex flex-column flex-lg-row justify-content-center align-items-center mt-4"
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        columns={{ xs: 1, sm: 2, md: 2 }}
                      >
                        <Grid
                          // className="d-flex d-md-block justify-content-center align-items-center text-center"
                          item
                          // xs={1}
                        >
                          <p
                            className="text-center text-md-star"
                            style={{
                              textAlign: "left",
                              lineBreak: "anywhere",
                            }}
                          >
                            {" "}
                            <Chip
                              className="ChipsVerificar"
                              label={item.toUpperCase().replaceAll("_", " ")}
                              color="gobierno"
                            />{" "}
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <p
                            className="text-center text-md-start"
                            style={{
                              textAlign: "left",
                              lineBreak: "anywhere",
                            }}
                          >
                            {openDialog.data[item]}{" "}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )
                )}
              </Grid>
              {/* </div> */}
            </Box>
            <div className="container">
              <p className="linkPrint">
                Información extraída de la Secretaría de Finanzas y
                Administración del Estado de Michoacán, mediante la consulta en
                la liga:
                https://www.ventanilladigitaldetramites.michoacan.gob.mx/verificar-Certificado/Comprobante-Digital
              </p>
            </div>
            {/* <Grid
              container
              justifyContent="flex-start"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ mx: 4 }}
            >
              <p className="linkPrint">
                Información extraída de la Secretaría de Finanzas y
                Administración del Estado de Michoacán, mediante la consulta en
                la liga:
                https://www.ventanilladigitaldetramites.michoacan.gob.mx/verificar-Certificado/Comprobante-Digital
              </p>
            </Grid> */}

            <Grid
              className="btn-modalprint"
              container
              justifyContent="flex-end"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <div
                className="cta cta--guinda mt-2 btn-modalprint"
                style={{ marginRight: "1%" }}
                onClick={() => handlePrint()}
              >
                Imprimir
              </div>

              <div
                className="cta cta--guinda mt-2 btn-modalprint"
                onClick={() => closeModal()}
              >
                Aceptar
              </div>
            </Grid>
            <div className="Footerprint">
              <Footer />
            </div>

            {/* <Button color='error' onClick={()=> setopenDialog(false)}>Aceptar</Button> */}
          </Box>
        </Modal>
      </ThemeProvider>

      <Modal
        open={Calificacion.openModalCali}
        onClose={() =>
          setCalificacion({ ...Calificacion, openModalCali: false })
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleCalificacion}>
          <Box
            sx={{
              "& > legend": { mt: 2 },
              marginBottom: "3%",
            }}
          >
            <Typography component="legend">
              ¿Cómo calificarías tu experiencia?
            </Typography>
            <Rating
              size="large"
              sx={{
                marginLeft: "22%",
                marginTop: "4%",
              }}
              name="simple-controlled"
              value={Calificacion.calificacionValor}
              onChange={(event, newValue) => {
                setCalificacion({
                  ...Calificacion,
                  calificacionValor: newValue,
                });
              }}
            />
          </Box>
          <Grid
            container
            justifyContent="flex-end"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <div
              className="cta cta--guinda mt-2"
              onClick={() =>
                setCalificacion({ ...Calificacion, openModalCali: false })
              }
            >
              Enviar
            </div>
          </Grid>
        </Box>
      </Modal>
      <Dialog
        open={openImgInfo}
        onClose={() => setopenImgInfo(false)}
        aria-labelledby="alert-dialog-title"
        maxWidth="lg"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Como identificar el folio
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img
              style={{ width: "100%", height: "100%" }}
              src={imgFolio}
              alt="info"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="bg-main" onClick={() => setopenImgInfo(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ValidarDocumentos;
